// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from 'react';
import { GetBinsRequest, GetBinsRequestBody } from '../../grpc-web/MCAPI_Types_pb.js';
import { useMCAPIContext } from './MCAPIContext';
import useGetProjectInfo from './useGetProjectInfo';

export type Bin = {
  path: string;
  name: string;
};

type GetBinsValue = {
  data: Bins[];
  isLoading: boolean;
};

const useGetBins = (): GetBinsValue => {
  const [data, setData] = useState<Bin[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { data: project } = useGetProjectInfo();

  const { client, getAccessToken } = useMCAPIContext();

  useEffect(() => {
    if (!project?.projectPath) return;
    setLoading(true);
    setData([]);

    const request = new GetBinsRequest();
    const gbqBody = new GetBinsRequestBody();
    gbqBody.setRequestFlagList([GetBinsRequestBody.GetBinsFlag.ALLTYPES]);
    request.setBody(gbqBody);

    const stream = client.getBins(request, { accessToken: getAccessToken() });

    const binData = [];

    stream.on('data', (response) => {
      const path = response.getBody().getAbsolutePath().replace(project.projectPath, '');
      const bin = {
        path: path,
        name: path.replace('.avb', '')
      };
      if (!bin.path.includes('Trash/')) {
        binData.push(bin);
      }
    });
    stream.on('error', (err) => {
      console.log(`Unexpected stream error: code = ${err.code}` + `, message = "${err.message}"`);
      window.mcapi.reportError(err.code, err.message);
    });
    stream.on('status', (status) => {
      console.log(status);
    });
    stream.on('end', () => {
      setData(binData);
      setLoading(false);
      console.log(`Completed`);
    });
  }, [client, getAccessToken, project]);

  const value = {
    data,
    isLoading
  };

  return value;
};

export default useGetBins;
