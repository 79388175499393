// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  CreateCustomColumnRequest,
  CreateCustomColumnRequestBody
} from '../../grpc-web/MCAPI_Types_pb.js';
import { useMCAPIContext } from './MCAPIContext';

interface CreateColumnProps {
  binName: string;
  columnName: string;
  folderName?: string;
  afterColumnName?: string;
  hidden?: boolean;
}

const useCreateColumn = () => {
  const { client, getAccessToken } = useMCAPIContext();

  const createColumn = ({
    binName,
    columnName,
    afterColumnName,
    hidden
  }: CreateColumnProps): boolean => {
    const request = new CreateCustomColumnRequest();
    const requestBody = new CreateCustomColumnRequestBody();

    requestBody.setAbsoluteBinPath(`${binName}.avb`);
    requestBody.setNewColumnName(columnName);
    requestBody.setAfterColumnName(afterColumnName || '');
    requestBody.setColumnHidden(!!hidden);
    request.setBody(requestBody);

    client.createCustomColumn(request, { accessToken: getAccessToken() }, (err, response) => {
      if (err) {
        const errMessage = `Unexpected error: code = ${err.code}` + `, message = "${err.message}"`;
        console.log(errMessage);
        return false;
      } else {
        console.log(response.toObject());
        return true;
      }
    });
  };

  return createColumn;
};

export default useCreateColumn;
