import { Metadata } from '../../types/files';

const MetadataCategory = ({ metadata = {} }: Metadata) => {
  return (
    <div>
      {Object.entries(metadata).map(([key, value]) => {
        if (typeof value === 'string') {
          return (
            <div key={key} className="">
              <span className="font-bold">{key}:</span> {value}
            </div>
          );
        } else {
          return (
            <div key={key} className="">
              <span className="font-bold">{key}</span>
              <div className="pl-4">
                <MetadataCategory metadata={value} />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

interface Props {
  metadata?: Metadata;
}

const MetadataList = ({ metadata }: Props) => {
  return (
    <div className="grow overflow-y-auto max-h-full bg-background-light border border-border-light p-1">
      {metadata && <MetadataCategory metadata={metadata} />}
    </div>
  );
};

export default MetadataList;
