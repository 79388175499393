import EllipsisHorizontalIcon from './icons/EllipsisHorizontalIcon';

interface Props {
  label: string;
  value: string;
  emptyString?: string;
  onClick?: () => void;
}

const SelectedItem = ({ label, value, emptyString = 'nothing is selected', onClick }: Props) => {
  return (
    <div>
      <div>{label}</div>
      <button
        onClick={() => onClick?.()}
        className="group bg-background-light px-2 py-1 w-full outline-none border-border-light border flex justify-between items-center w-min-0 gap-3">
        <div className="grow w-min-0 overflow-hidden flex">
          <div className="truncate">
            {value || <span className="opacity-50">{emptyString}</span>}
          </div>
        </div>
        {onClick && (
          <div className="group-hover:text-white transition-colors grow-0 outline-none">
            <EllipsisHorizontalIcon />
          </div>
        )}
      </button>
    </div>
  );
};

export default SelectedItem;
