import { DragEvent, useEffect, useState } from 'react';
import { BEFileItem } from '../../types/files';
import FileBrowser from '../pixFileBrowser/FileBrowser';
import { useScopeContext } from '../scope/ScopeContext';
import MetadataSyncHeader from '../metadata/MetadataSyncHeader';
import DropArea from '../common/DropArea';
import SelectedItem from '../common/SelectedItem';
import useGetMobInfo from '../mcapi/useGetMobInfo';
import Button from '../common/Button';
import useUpdateItem from '../../hooks/useUpdateItem';
import Checkbox from '../common/Checkbox';
import Notification from '../common/Notification';
import McFileBrowser from '../mcFileBrowser/McFileBrowser';
import { BinItem } from '../mcapi/useGetBinItems';
import MetadataColumnList from './MetadataColumnList';

const MCAPI_ASSETLIST_MIME_TYPE = 'text/x.avid.mc-api-asset-list+json';

export type Columns = {
  [key: string]: string;
};

const MetadataSyncPage = () => {
  const [selectedFile, setSelectedFile] = useState<BEFileItem>();
  const [isFileBrowserOpened, setFileBrowserOpen] = useState<boolean>(false);
  const [isMcFileBrowserOpened, setMcFileBrowserOpen] = useState<boolean>(false);
  const [selectedFileFromMc, setSelectedFileFromMc] = useState<string>('');
  const [syncEmptyColumns, setSyncEmptyColumns] = useState<boolean>(false);
  const [columnsToSync, setColumnsToSync] = useState<Columns>({});
  const [done, setDone] = useState<boolean>(false);

  const { scope } = useScopeContext();
  const { mutate: updateItem, isLoading } = useUpdateItem();
  const { data: fileFromMc } = useGetMobInfo({
    mobId: selectedFileFromMc
  });

  useEffect(() => {
    if (!fileFromMc) return;

    if (syncEmptyColumns) {
      setColumnsToSync(fileFromMc);
    } else {
      setColumnsToSync(
        Object.keys(fileFromMc)
          .filter((k) => !!fileFromMc[k])
          .reduce((a, k) => ({ ...a, [k]: fileFromMc[k] }), {})
      );
    }
  }, [fileFromMc, syncEmptyColumns]);

  const handleDrop = (event: DragEvent<HTMLElement>) => {
    for (const item of event.dataTransfer.items) {
      if (item.type === MCAPI_ASSETLIST_MIME_TYPE) {
        const mimeData = event.dataTransfer.getData(MCAPI_ASSETLIST_MIME_TYPE);
        const dragList = JSON.parse(mimeData);

        dragList.forEach((element: { [x: string]: any }) => {
          const id = element['id'];
          setSelectedFileFromMc(id || '');
        });
      }
    }
  };

  const handleMetadataSync = () => {
    if (!fileFromMc) return;
    updateItem({
      domainId: scope?.project.id || '',
      itemId: selectedFile?.id || '',
      metadata: {
        AVID: columnsToSync
      }
    });
    setDone(true);
  };

  const handleFileSelect = (files: BEFileItem[]) => {
    setSelectedFile(files[0]);
    setFileBrowserOpen(false);
  };

  const handleMcItemSelect = (item: BinItem) => {
    setSelectedFileFromMc(item.id);
    setMcFileBrowserOpen(false);
  };

  const syncButtonDisabled = !selectedFile || !selectedFileFromMc || isLoading;
  const file = selectedFile ? `${selectedFile.name}.${selectedFile.extension}` : '';

  return (
    <>
      <div className="flex flex-col h-full">
        <MetadataSyncHeader />
        <DropArea
          className="grow"
          innerClassName="flex flex-col gap-4 max-h-full"
          onDrop={handleDrop}>
          <SelectedItem
            label="Source (MC):"
            value={fileFromMc?.['Name'] || ''}
            emptyString="no file selected"
            onClick={() => setMcFileBrowserOpen(true)}
          />
          <SelectedItem
            label="Target (PIX):"
            value={file}
            emptyString="no file selected"
            onClick={() => setFileBrowserOpen(true)}
          />
          <Checkbox
            checked={!syncEmptyColumns}
            onChange={() => setSyncEmptyColumns(!syncEmptyColumns)}
            label="Do not sync empty columns"
          />

          <MetadataColumnList columns={columnsToSync} />

          <div className="flex justify-center mt-4">
            <Button onClick={handleMetadataSync} disabled={syncButtonDisabled} loading={isLoading}>
              Start sync
            </Button>
          </div>
        </DropArea>
      </div>

      {isFileBrowserOpened && (
        <FileBrowser
          projectId={scope?.project.id}
          onSelect={(files) => handleFileSelect(files)}
          onClose={() => setFileBrowserOpen(false)}
        />
      )}

      {isMcFileBrowserOpened && (
        <McFileBrowser onSelect={handleMcItemSelect} onClose={() => setMcFileBrowserOpen(false)} />
      )}

      {done && <Notification text="Syncronization sucessful" />}
    </>
  );
};

export default MetadataSyncPage;
