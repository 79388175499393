import { Organization } from '../../hooks/useGetOrganizations';

interface Props {
  organizations: Organization[] | undefined;
  onSelect: (org: Organization) => void;
}

const OrganizationPicker = ({ organizations, onSelect }: Props) => {
  return (
    <div className="py-2 flex flex-col gap-4 justify-center items-center h-full">
      <h1>Select organization</h1>
      {organizations && (
        <div className="flex gap-2">
          {organizations.length > 0 &&
            organizations.map((org) => (
              <button
                key={org.id}
                onClick={() => onSelect(org)}
                className="flex flex-col items-center justify-center w-24 h-24 bg-neutral-700/25 hover:bg-neutral-700/50 transition-colors outline-none">
                {org.logo ? <img className="max-w-[4rem]" src={org.logo} /> : <>{org.title}</>}
              </button>
            ))}
        </div>
      )}
    </div>
  );
};

export default OrganizationPicker;
