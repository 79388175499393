import { useState } from 'react';
import { BEFileItem } from '../../types/files';
import FileBrowser from '../pixFileBrowser/FileBrowser';
import { useScopeContext } from '../scope/ScopeContext';
import CreateBin from '../bin/CreateBin';

const CreateBinPage = () => {
  const { scope } = useScopeContext();
  const [selectedFiles, setSelectedFiles] = useState<BEFileItem[]>([]);
  const [isFileBrowserOpened, setFileBrowserOpen] = useState<boolean>(false);

  const handleFileSelect = (files: BEFileItem[]) => {
    setSelectedFiles(files);
    setFileBrowserOpen(false);
  };
  return (
    <>
      <div className="flex flex-col h-full">
        {!isFileBrowserOpened && (
          <>
            <h1 className="text-bold mb-1 text-lg flex items-center gap-2">Create a new bin</h1>
            <p className="italic opacity-50 mb-4">
              Create a new bin and add custom columns from a pix asset&#39;s metadata structure
            </p>
            <CreateBin
              selectedFile={selectedFiles[0]}
              onOpenFileBroser={() => setFileBrowserOpen(true)}
            />
          </>
        )}
      </div>
      {isFileBrowserOpened && (
        <FileBrowser
          projectId={scope?.project.id}
          onSelect={(files) => handleFileSelect(files)}
          onClose={() => setFileBrowserOpen(false)}
        />
      )}
    </>
  );
};

export default CreateBinPage;
