import { useApi } from './useApi';
import { useMutation } from '@tanstack/react-query';

type ItemMetadata = {
  [categoryKey: string]: { [metadataKey: string]: string };
};

type UpdateItemvariables = {
  domainId: string;
  itemId: string;
  metadata: ItemMetadata;
};

const useUpdateItem = () => {
  const callApi = useApi();

  const mutation = useMutation<ItemMetadata, Record<string, string>, UpdateItemvariables>(
    async ({ domainId, itemId, metadata }) => {
      return await callApi<ItemMetadata>({
        url: `${process.env.REACT_APP_API_URL}/v1/content-v2/${domainId}/items/${itemId}`,
        body: { metadata },
        method: 'PATCH'
      });
    }
  );

  return mutation;
};

export default useUpdateItem;
