import { DragEvent } from 'react';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';

interface Props {
  onDrop: (event: DragEvent<HTMLElement>) => void;
  children: ReactNode;
  className?: string;
  innerClassName?: string;
}

const DropArea = ({ className = '', innerClassName = '', onDrop, children }: Props) => {
  const [isDragging, setDragging] = useState<boolean>(false);

  const handleDragEnter = () => {
    setDragging(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.relatedTarget && event.currentTarget.contains(event.relatedTarget as Element)) return;
    setDragging(false);
  };

  const handleDragOver = (event: DragEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLElement>) => {
    event.preventDefault();
    setDragging(false);
    onDrop(event);
  };

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className={clsx(className, 'm-[-4px] p-1 border border-dashed transition-colors', {
        'border-transparent': !isDragging,
        'border-primary-default': isDragging
      })}>
      <div
        className={clsx(innerClassName, 'h-full transition-opacity', { 'opacity-20': isDragging })}>
        {children}
      </div>
    </div>
  );
};

export default DropArea;
