import { Link } from 'react-router-dom';
import Button from './Button';
import Scope from './Scope';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const { pathname } = useLocation();
  return (
    <header className="flex justify-between">
      <Link to="/">
        <Button variant="text">{pathname === '/' ? 'PIX Sync Plugin' : 'Choose A Feature'}</Button>
      </Link>
      <Scope />
    </header>
  );
};

export default Header;
