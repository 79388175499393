import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';
import { ONE_HOUR } from '../constants/time';

export interface Organization {
  id: string;
  title: string;
  status: string;
  created_at: string;
  updated_at: string | null;
  logo: string | null;
  permissions?: string[];
}

export interface OrganizationsResponse {
  data: Organization[];
}

const useGetOrganizations = () => {
  const callApi = useApi();

  const result = useQuery<Organization[]>(
    ['organizations'],
    async () => {
      const data = await callApi<OrganizationsResponse>({
        url: `${process.env.REACT_APP_API_URL}/v1/admin-v2/organizations`
      });

      return data.data;
    },
    {
      staleTime: ONE_HOUR
    }
  );

  return result;
};

export default useGetOrganizations;
