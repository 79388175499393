import { ReactNode } from 'react';

interface Props {
  name: string;
  description: string;
  icon: ReactNode;
  onClick?: () => void;
}

const PageButton = ({ name, description, icon, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      className="w-full bg-background-light border border-border-light hover:border-primary-default flex flex-col gap-1 items-center justify-center p-6 hover:bg-primary-default transition-colors hover:text-white cursor-pointer outline-none">
      <div>{icon}</div>
      <div>{name}</div>
      <div className=" italic">{description}</div>
    </button>
  );
};

export default PageButton;
