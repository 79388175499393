import { useState, createContext, useContext, ReactNode } from 'react';
import { MCAPIClient } from '../../grpc-web/MCAPI_grpc_web_pb';

const mcapiclient = new MCAPIClient(window.mcapi.getGatewayServerAddress(), null, null);

// export const mcapiclient = (() => {
//   let initialized = false;
//   return () => {
//     if (!initialized) {
//       const client = new MCAPIClient(window.mcapi.getGatewayServerAddress(), null, null);
//       initialized = true;
//       return client;
//     }
//     return null;
//   };
// })();

interface MContext {
  client: unknown;
  getAccessToken: () => string;
}

const defaultContext = {
  client: null,
  getAccessToken: () => ''
};

const MCAPIContext = createContext<MContext>(defaultContext);

interface Props {
  children: ReactNode;
}

export const MCAPIContextProvider = ({ children }: Props) => {
  const [client] = useState(mcapiclient);

  const getAccessToken = () => window.mcapi?.getAccessToken();

  const value = { client, getAccessToken };

  return <MCAPIContext.Provider value={value}>{children}</MCAPIContext.Provider>;
};

export const useMCAPIContext = () => {
  return useContext(MCAPIContext);
};
