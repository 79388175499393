// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  AddMarkerRequest,
  AddMarkerRequestBody,
  TrackLabel,
  TrackType,
  MarkerColor
} from '../../grpc-web/MCAPI_Types_pb.js';
import { ObjectValues } from '../../types/object';
import { useMCAPIContext } from './MCAPIContext';

const TrackTypeFLAGS = {
  TRACKTYPE_PICTURE: TrackType.TRACKTYPE_PICTURE,
  TRACKTYPE_SOUND: TrackType.TRACKTYPE_SOUND,
  TRACKTYPE_TIMECODE: TrackType.TRACKTYPE_TIMECODE,
  TRACKTYPE_EDGECODE: TrackType.TRACKTYPE_EDGECODE,
  TRACKTYPE_DATA: TrackType.TRACKTYPE_DATA
};

const TrackTypes = {
  V: TrackType.TRACKTYPE_PICTURE,
  A: TrackType.TRACKTYPE_SOUND,
  TC: TrackType.TRACKTYPE_TIMECODE,
  EC: TrackType.TRACKTYPE_EDGECODE,
  D: TrackType.TRACKTYPE_DATA
};

export const MarkerColorFLAGS = {
  Red: MarkerColor.RED,
  Green: MarkerColor.GREEN,
  Blue: MarkerColor.BLUE,
  Cyan: MarkerColor.CYAN,
  Magenta: MarkerColor.MAGENTA,
  Yellow: MarkerColor.YELLOW,
  Black: MarkerColor.BLACK,
  White: MarkerColor.WHITE
};

interface Marker {
  name: string;
  comment: string;
  markerFramePosition: number;
  mobId: string;
  markerLength?: number;
  timecode?: string;
  color?: ObjectValues<typeof MarkerColorFLAGS>;
  trackLabelNumber?: number;
  trackType?: ObjectValues<typeof TrackTypeFLAGS>;
}

const defaultMarker = {
  color: MarkerColorFLAGS.Green,
  trackerLabelNumber: 1,
  trackType: TrackTypeFLAGS.TRACKTYPE_TIMECODE
};

const useAddMarker = () => {
  const { client, getAccessToken } = useMCAPIContext();
  const addMarker = (marker: Marker) => {
    const fullMarker = { ...defaultMarker, ...marker };

    const request = new AddMarkerRequest();
    const markerRequestBody = new AddMarkerRequestBody();

    markerRequestBody.setName(fullMarker.name);
    markerRequestBody.setColor(MarkerColorFLAGS[fullMarker.color || 'Red']);
    markerRequestBody.setComment(fullMarker.comment);
    markerRequestBody.setMarkerLength(Number(fullMarker.markerLength));
    markerRequestBody.setMarkerFramePosition(Number(fullMarker.markerFramePosition));
    markerRequestBody.setMobId(fullMarker.mobId);

    const trackLabel = new TrackLabel();

    trackLabel.setType(TrackTypes[marker?.trackType || 'V']);
    trackLabel.setNumber(Number(marker?.trackLabelNumber));

    markerRequestBody.setTrackLabel(trackLabel);

    request.setBody(markerRequestBody);

    client.addMarker(request, { accessToken: getAccessToken() }, (err, response) => {
      if (err) {
        const errMessage = `Unexpected error: code = ${err.code}` + `, message = "${err.message}"`;
        console.log(errMessage);
      } else {
        console.log(response.toObject());
      }
    });
  };
  return addMarker;
};

export default useAddMarker;
