// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from 'react';
import {
  GetMobTrackInfoRequest,
  GetMobTrackInfoRequestBody
} from '../../grpc-web/MCAPI_grpc_web_pb';
import { TrackType } from '../../grpc-web/MCAPI_Types_pb.js';
import { useMCAPIContext } from './MCAPIContext';

export interface TrackList {
  label: string;
  trackType: string;
  trackCustomName: string;
  trackNumber: number;
}

const trackTypes = {
  [TrackType.TRACKTYPE_PICTURE]: 'V',
  [TrackType.TRACKTYPE_SOUND]: 'A',
  [TrackType.TRACKTYPE_DATA]: 'D',
  [TrackType.TRACKTYPE_EDGECODE]: 'EC',
  [TrackType.TRACKTYPE_TIMECODE]: 'TC'
};

interface Props {
  mobId: string;
}

const useGetMobTrackInfo = ({ mobId }: Props): { data: TrackList[] } => {
  const { client, getAccessToken } = useMCAPIContext();
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!mobId) return;
    setLoading(true);

    const request = new GetMobTrackInfoRequest();
    const requestBody = new GetMobTrackInfoRequestBody();

    requestBody.setMobId(mobId);
    request.setBody(requestBody);

    client.getMobTrackInfo(request, { accessToken: getAccessToken() }, (err, response) => {
      if (err) {
        const errMessage = `Unexpected error: code = ${err.code}` + `, message = "${err.message}"`;
        console.log(errMessage);
        window.mcapi.reportError(err.code, err.message);
        setLoading(false);
      } else {
        console.log('no error');
        const trackInfoList = response.getBody().getTrackInfoList().getTrackInfoList();

        const normalizedTrackList = trackInfoList.map<TrackList>((trackInfo) => {
          const trackLabel = trackInfo.getLabel();
          const trackType = trackTypes[trackLabel.getType()];
          const trackCustomName = trackInfo.getCustomName() || '';
          const trackNumber = trackLabel.getNumber();

          return {
            label: `${trackType + trackNumber} ${trackCustomName && ' - ' + trackCustomName}`,
            trackType,
            trackCustomName,
            trackNumber
          };
        });

        setData(normalizedTrackList);
        setLoading(false);
      }
    });
  }, [client, getAccessToken, mobId]);

  const value = {
    data,
    isLoading
  };

  return value;
};

export default useGetMobTrackInfo;
