import { useState } from 'react';
import { BEFileItem, Metadata } from '../../types/files';
import SelectedItem from '../common/SelectedItem';
import Button from '../common/Button';
import MetadataList from '../metadata/MetadataList';
import TextInput from '../common/TextInput';
import useCreateBin from '../mcapi/useCreateBin';
import useCreateColumn from '../mcapi/useCreateColumn';
import Notification from '../common/Notification';

const generateColumnList = (metadata: Metadata, prefix?: string) =>
  Object.entries(metadata).reduce<{ key: string; value: string }[]>(
    (acc, [key, value]): { key: string; value: string }[] => {
      const prefixedKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'string') {
        return [...acc, { key: prefixedKey, value }];
      } else {
        return [...acc, ...generateColumnList(value, prefixedKey)];
      }
    },
    []
  );

interface Props {
  selectedFile: BEFileItem | undefined;
  onOpenFileBroser: () => void;
}

const CreateBin = ({ selectedFile, onOpenFileBroser }: Props) => {
  const file = selectedFile ? `${selectedFile.name}.${selectedFile.extension}` : '';
  const [binName, setBinName] = useState('');
  const createBin = useCreateBin();
  const createColumn = useCreateColumn();
  const [inProgress, setInProgress] = useState(false);
  const [done, setDone] = useState<boolean>(false);

  const handleCreateColumns = () => {
    if (!binName || !selectedFile?.metadata) {
      return;
    }

    const columnList = generateColumnList(selectedFile.metadata);
    columnList.forEach((column) => {
      createColumn({ binName, columnName: column.key });
    });
  };

  const handleCreateBin = () => {
    setInProgress(true);
    if (!binName) {
      return;
    }
    createBin(binName);
    handleCreateColumns();
    setInProgress(false);
    setDone(true);
  };

  const syncButtonDisabled = !selectedFile || !binName;

  if (inProgress) {
    return <div className="grow flex justify-center items-center">please wait...</div>;
  }

  return (
    <div className="grow flex flex-col gap-4">
      <SelectedItem
        label="Source (PIX):"
        value={file}
        emptyString="no file selected"
        onClick={onOpenFileBroser}
      />

      <TextInput label="Bin name" onChange={({ target: { value } }) => setBinName(value)} />

      <div className="grow max-h-full h-20 flex flex-col">
        <MetadataList metadata={selectedFile?.metadata} />
      </div>

      <div className="flex justify-center mt-4">
        <Button onClick={handleCreateBin} disabled={syncButtonDisabled}>
          Create bin
        </Button>
      </div>

      {done && <Notification text="New bin created" />}
    </div>
  );
};

export default CreateBin;
