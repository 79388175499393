import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetOrganizations, { Organization } from '../../hooks/useGetOrganizations';
import useGetProjects, { Project } from '../../hooks/useGetProjects';
import useGetTeams, { Team } from '../../hooks/useGetTeams';
import Button from '../common/Button';
import ProjectIcon from '../common/icons/ProjectIcon';
import OrganizationPicker from '../scope/OrganizationPicker';
import { useScopeContext } from '../scope/ScopeContext';
import SelectedItem from '../common/SelectedItem';

interface ExtendedTeam extends Team {
  projects: Project[];
}

const ScopeSelector = () => {
  const { data: organizations, isLoading: orgsLoading } = useGetOrganizations();
  const { data: teams, isLoading: teamsLoading } = useGetTeams();
  const { data: projects, isLoading: projectsLoading } = useGetProjects();
  const { setScope } = useScopeContext();
  const navigate = useNavigate();

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  const loading = orgsLoading || projectsLoading || teamsLoading;
  const noAccess = !organizations || !teams || !projects;

  const teamProjectList = useMemo(
    () =>
      projects && teams
        ? teams.reduce<ExtendedTeam[]>(
            (acc, team) => [
              ...acc,
              ...(team.organization_id === selectedOrg?.id
                ? [
                    {
                      ...team,
                      projects: projects.filter((project) => project.team_id === team.id)
                    }
                  ]
                : [])
            ],
            []
          )
        : [],
    [projects, selectedOrg?.id, teams]
  );

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">fetching projects...</div>
    );
  }

  if (noAccess) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        You do not have access to any project
      </div>
    );
  }

  const handleSelectOrg = (organization: Organization) => setSelectedOrg(organization);

  if (!selectedOrg) {
    return <OrganizationPicker organizations={organizations} onSelect={handleSelectOrg} />;
  }

  const handleSelectScope = (organization: Organization, team: Team, project: Project) => {
    setScope({ organization, team, project });
    navigate('/');
  };

  return (
    <div>
      <h1 className="text-bold mb-1 text-lg">Select Project</h1>
      <SelectedItem
        label="Selected organization:"
        value={selectedOrg.title}
        onClick={() => setSelectedOrg(null)}
      />
      <div className="mt-4">Projects:</div>
      <div className="border border-border-light p-1 h-full bg-background-light">
        {teamProjectList.map((team) => (
          <>
            {team.projects.length > 0 && (
              <>
                <div key={team.id} className="text-text/50 uppercase text-xs mb-1">
                  {team.title}
                </div>
                <div className="flex flex-col gap-1 items-start mb-2">
                  {team.projects.map((project) => (
                    <Button
                      variant="text"
                      key={project.id}
                      onClick={() => handleSelectScope(selectedOrg, team, project)}>
                      <ProjectIcon className="w-4 h-4" />
                      {project.title}
                    </Button>
                  ))}
                </div>
              </>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default ScopeSelector;
