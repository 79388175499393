// source: MCAPI_Types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.mcapi.AddMarkerRequest', null, global);
goog.exportSymbol('proto.mcapi.AddMarkerRequestBody', null, global);
goog.exportSymbol('proto.mcapi.AddMarkerResponse', null, global);
goog.exportSymbol('proto.mcapi.AddMarkerResponseBody', null, global);
goog.exportSymbol('proto.mcapi.AudioTrackType', null, global);
goog.exportSymbol('proto.mcapi.ColumnInfo', null, global);
goog.exportSymbol('proto.mcapi.CommandError', null, global);
goog.exportSymbol('proto.mcapi.CommandErrorType', null, global);
goog.exportSymbol('proto.mcapi.ConfigureSRTStreamRequest', null, global);
goog.exportSymbol('proto.mcapi.ConfigureSRTStreamRequestBody', null, global);
goog.exportSymbol('proto.mcapi.ConfigureSRTStreamRequestBody.ModeOption', null, global);
goog.exportSymbol('proto.mcapi.ConfigureSRTStreamRequestBody.QualityOption', null, global);
goog.exportSymbol('proto.mcapi.ConfigureSRTStreamResponse', null, global);
goog.exportSymbol('proto.mcapi.ConfigureSRTStreamResponseBody', null, global);
goog.exportSymbol('proto.mcapi.CreateBinRequest', null, global);
goog.exportSymbol('proto.mcapi.CreateBinRequestBody', null, global);
goog.exportSymbol('proto.mcapi.CreateBinRequestBody.OpenBinOption', null, global);
goog.exportSymbol('proto.mcapi.CreateBinResponse', null, global);
goog.exportSymbol('proto.mcapi.CreateBinResponseBody', null, global);
goog.exportSymbol('proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest', null, global);
goog.exportSymbol('proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody', null, global);
goog.exportSymbol('proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse', null, global);
goog.exportSymbol('proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody', null, global);
goog.exportSymbol('proto.mcapi.CreateCustomColumnRequest', null, global);
goog.exportSymbol('proto.mcapi.CreateCustomColumnRequestBody', null, global);
goog.exportSymbol('proto.mcapi.CreateCustomColumnResponse', null, global);
goog.exportSymbol('proto.mcapi.CreateCustomColumnResponseBody', null, global);
goog.exportSymbol('proto.mcapi.EchoRequest', null, global);
goog.exportSymbol('proto.mcapi.EchoRequestBody', null, global);
goog.exportSymbol('proto.mcapi.EchoResponse', null, global);
goog.exportSymbol('proto.mcapi.EchoResponseBody', null, global);
goog.exportSymbol('proto.mcapi.ExportEDLRequest', null, global);
goog.exportSymbol('proto.mcapi.ExportEDLRequestBody', null, global);
goog.exportSymbol('proto.mcapi.ExportEDLResponse', null, global);
goog.exportSymbol('proto.mcapi.ExportEDLResponseBody', null, global);
goog.exportSymbol('proto.mcapi.ExportFileOption', null, global);
goog.exportSymbol('proto.mcapi.ExportFileRequest', null, global);
goog.exportSymbol('proto.mcapi.ExportFileRequestBody', null, global);
goog.exportSymbol('proto.mcapi.ExportFileResponse', null, global);
goog.exportSymbol('proto.mcapi.ExportFileResponseBody', null, global);
goog.exportSymbol('proto.mcapi.FrameRate', null, global);
goog.exportSymbol('proto.mcapi.GetAppInfoRequest', null, global);
goog.exportSymbol('proto.mcapi.GetAppInfoRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetAppInfoResponse', null, global);
goog.exportSymbol('proto.mcapi.GetAppInfoResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetAppInfoResponseBody.AppBusyStatus', null, global);
goog.exportSymbol('proto.mcapi.GetBinInfoRequest', null, global);
goog.exportSymbol('proto.mcapi.GetBinInfoRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetBinInfoResponse', null, global);
goog.exportSymbol('proto.mcapi.GetBinInfoResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetBinsRequest', null, global);
goog.exportSymbol('proto.mcapi.GetBinsRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetBinsRequestBody.GetBinsFlag', null, global);
goog.exportSymbol('proto.mcapi.GetBinsResponse', null, global);
goog.exportSymbol('proto.mcapi.GetBinsResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetCustomProjectDataRequest', null, global);
goog.exportSymbol('proto.mcapi.GetCustomProjectDataRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetCustomProjectDataResponse', null, global);
goog.exportSymbol('proto.mcapi.GetCustomProjectDataResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetListOfBinItemsRequest', null, global);
goog.exportSymbol('proto.mcapi.GetListOfBinItemsRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetListOfBinItemsRequestBody.BinItemFlags', null, global);
goog.exportSymbol('proto.mcapi.GetListOfBinItemsResponse', null, global);
goog.exportSymbol('proto.mcapi.GetListOfBinItemsResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetListOfExportEDLSettingsRequest', null, global);
goog.exportSymbol('proto.mcapi.GetListOfExportEDLSettingsRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetListOfExportEDLSettingsResponse', null, global);
goog.exportSymbol('proto.mcapi.GetListOfExportEDLSettingsResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetListOfExportSettingsRequest', null, global);
goog.exportSymbol('proto.mcapi.GetListOfExportSettingsRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetListOfExportSettingsResponse', null, global);
goog.exportSymbol('proto.mcapi.GetListOfExportSettingsResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetListOfImportSettingsRequest', null, global);
goog.exportSymbol('proto.mcapi.GetListOfImportSettingsRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetListOfImportSettingsResponse', null, global);
goog.exportSymbol('proto.mcapi.GetListOfImportSettingsResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetMediaVolumeListRequest', null, global);
goog.exportSymbol('proto.mcapi.GetMediaVolumeListRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetMediaVolumeListResponse', null, global);
goog.exportSymbol('proto.mcapi.GetMediaVolumeListResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume', null, global);
goog.exportSymbol('proto.mcapi.GetMobInfoRequest', null, global);
goog.exportSymbol('proto.mcapi.GetMobInfoRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetMobInfoResponse', null, global);
goog.exportSymbol('proto.mcapi.GetMobInfoResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetMobTrackInfoRequest', null, global);
goog.exportSymbol('proto.mcapi.GetMobTrackInfoRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetMobTrackInfoResponse', null, global);
goog.exportSymbol('proto.mcapi.GetMobTrackInfoResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetOTSSessionStatusRequest', null, global);
goog.exportSymbol('proto.mcapi.GetOTSSessionStatusRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetOTSSessionStatusResponse', null, global);
goog.exportSymbol('proto.mcapi.GetOTSSessionStatusResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetOTSSessionStatusResponseBody.ModeOption', null, global);
goog.exportSymbol('proto.mcapi.GetOTSSessionStatusResponseBody.QualityOption', null, global);
goog.exportSymbol('proto.mcapi.GetOpenProjectInfoRequest', null, global);
goog.exportSymbol('proto.mcapi.GetOpenProjectInfoRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetOpenProjectInfoResponse', null, global);
goog.exportSymbol('proto.mcapi.GetOpenProjectInfoResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetSRTStreamSettingsRequest', null, global);
goog.exportSymbol('proto.mcapi.GetSRTStreamSettingsRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetSRTStreamSettingsResponse', null, global);
goog.exportSymbol('proto.mcapi.GetSRTStreamSettingsResponseBody', null, global);
goog.exportSymbol('proto.mcapi.GetSRTStreamSettingsResponseBody.ModeOption', null, global);
goog.exportSymbol('proto.mcapi.GetSRTStreamSettingsResponseBody.QualityOption', null, global);
goog.exportSymbol('proto.mcapi.GetValuesRequest', null, global);
goog.exportSymbol('proto.mcapi.GetValuesRequestBody', null, global);
goog.exportSymbol('proto.mcapi.GetValuesResponse', null, global);
goog.exportSymbol('proto.mcapi.GetValuesResponseBody', null, global);
goog.exportSymbol('proto.mcapi.ImportFileOption', null, global);
goog.exportSymbol('proto.mcapi.ImportFileRequest', null, global);
goog.exportSymbol('proto.mcapi.ImportFileRequestBody', null, global);
goog.exportSymbol('proto.mcapi.ImportFileResponse', null, global);
goog.exportSymbol('proto.mcapi.ImportFileResponseBody', null, global);
goog.exportSymbol('proto.mcapi.LoadSettingRequest', null, global);
goog.exportSymbol('proto.mcapi.LoadSettingRequestBody', null, global);
goog.exportSymbol('proto.mcapi.LoadSettingResponse', null, global);
goog.exportSymbol('proto.mcapi.MarkerColor', null, global);
goog.exportSymbol('proto.mcapi.OpenBinRequest', null, global);
goog.exportSymbol('proto.mcapi.OpenBinRequestBody', null, global);
goog.exportSymbol('proto.mcapi.OpenBinResponse', null, global);
goog.exportSymbol('proto.mcapi.OpenBinResponseBody', null, global);
goog.exportSymbol('proto.mcapi.RequestHeader', null, global);
goog.exportSymbol('proto.mcapi.ResponseHeader', null, global);
goog.exportSymbol('proto.mcapi.ScanAvidMediaFilesFolderRequest', null, global);
goog.exportSymbol('proto.mcapi.ScanAvidMediaFilesFolderRequestBody', null, global);
goog.exportSymbol('proto.mcapi.ScanAvidMediaFilesFolderResponse', null, global);
goog.exportSymbol('proto.mcapi.ScanAvidMediaFilesFolderResponseBody', null, global);
goog.exportSymbol('proto.mcapi.SetCustomProjectDataRequest', null, global);
goog.exportSymbol('proto.mcapi.SetCustomProjectDataRequestBody', null, global);
goog.exportSymbol('proto.mcapi.SetCustomProjectDataResponse', null, global);
goog.exportSymbol('proto.mcapi.SetCustomProjectDataResponseBody', null, global);
goog.exportSymbol('proto.mcapi.SetMobInfoRequest', null, global);
goog.exportSymbol('proto.mcapi.SetMobInfoRequestBody', null, global);
goog.exportSymbol('proto.mcapi.SetMobInfoResponse', null, global);
goog.exportSymbol('proto.mcapi.SetMobInfoResponseBody', null, global);
goog.exportSymbol('proto.mcapi.SetMobInfoResponseBody.MobFailure', null, global);
goog.exportSymbol('proto.mcapi.SetOTSSessionStatusRequest', null, global);
goog.exportSymbol('proto.mcapi.SetOTSSessionStatusRequestBody', null, global);
goog.exportSymbol('proto.mcapi.SetOTSSessionStatusRequestBody.ModeOption', null, global);
goog.exportSymbol('proto.mcapi.SetOTSSessionStatusRequestBody.QualityOption', null, global);
goog.exportSymbol('proto.mcapi.SetOTSSessionStatusResponse', null, global);
goog.exportSymbol('proto.mcapi.SetOTSSessionStatusResponseBody', null, global);
goog.exportSymbol('proto.mcapi.Stereoscopic', null, global);
goog.exportSymbol('proto.mcapi.TaskStatus', null, global);
goog.exportSymbol('proto.mcapi.Timestamp', null, global);
goog.exportSymbol('proto.mcapi.TrackInfo', null, global);
goog.exportSymbol('proto.mcapi.TrackInfoList', null, global);
goog.exportSymbol('proto.mcapi.TrackLabel', null, global);
goog.exportSymbol('proto.mcapi.TrackList', null, global);
goog.exportSymbol('proto.mcapi.TrackType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfBinItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfBinItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfBinItemsRequest.displayName = 'proto.mcapi.GetListOfBinItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfBinItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfBinItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfBinItemsResponse.displayName = 'proto.mcapi.GetListOfBinItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfBinItemsRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.GetListOfBinItemsRequestBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.GetListOfBinItemsRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfBinItemsRequestBody.displayName = 'proto.mcapi.GetListOfBinItemsRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfBinItemsResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfBinItemsResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfBinItemsResponseBody.displayName = 'proto.mcapi.GetListOfBinItemsResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.EchoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.EchoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.EchoRequest.displayName = 'proto.mcapi.EchoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.EchoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.EchoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.EchoResponse.displayName = 'proto.mcapi.EchoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.EchoRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.EchoRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.EchoRequestBody.displayName = 'proto.mcapi.EchoRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.EchoResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.EchoResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.EchoResponseBody.displayName = 'proto.mcapi.EchoResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetValuesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetValuesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetValuesRequest.displayName = 'proto.mcapi.GetValuesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetValuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetValuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetValuesResponse.displayName = 'proto.mcapi.GetValuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetValuesRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetValuesRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetValuesRequestBody.displayName = 'proto.mcapi.GetValuesRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetValuesResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetValuesResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetValuesResponseBody.displayName = 'proto.mcapi.GetValuesResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetAppInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetAppInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetAppInfoRequest.displayName = 'proto.mcapi.GetAppInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetAppInfoRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetAppInfoRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetAppInfoRequestBody.displayName = 'proto.mcapi.GetAppInfoRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetAppInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetAppInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetAppInfoResponse.displayName = 'proto.mcapi.GetAppInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetAppInfoResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetAppInfoResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetAppInfoResponseBody.displayName = 'proto.mcapi.GetAppInfoResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetBinsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetBinsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetBinsRequest.displayName = 'proto.mcapi.GetBinsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetBinsRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.GetBinsRequestBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.GetBinsRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetBinsRequestBody.displayName = 'proto.mcapi.GetBinsRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetBinsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetBinsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetBinsResponse.displayName = 'proto.mcapi.GetBinsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetBinsResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetBinsResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetBinsResponseBody.displayName = 'proto.mcapi.GetBinsResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetBinInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetBinInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetBinInfoRequest.displayName = 'proto.mcapi.GetBinInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetBinInfoRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetBinInfoRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetBinInfoRequestBody.displayName = 'proto.mcapi.GetBinInfoRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetBinInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetBinInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetBinInfoResponse.displayName = 'proto.mcapi.GetBinInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetBinInfoResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetBinInfoResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetBinInfoResponseBody.displayName = 'proto.mcapi.GetBinInfoResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMobInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMobInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMobInfoRequest.displayName = 'proto.mcapi.GetMobInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMobInfoRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMobInfoRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMobInfoRequestBody.displayName = 'proto.mcapi.GetMobInfoRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMobInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMobInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMobInfoResponse.displayName = 'proto.mcapi.GetMobInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMobInfoResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMobInfoResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMobInfoResponseBody.displayName = 'proto.mcapi.GetMobInfoResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetMobInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetMobInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetMobInfoRequest.displayName = 'proto.mcapi.SetMobInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetMobInfoRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetMobInfoRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetMobInfoRequestBody.displayName = 'proto.mcapi.SetMobInfoRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ColumnInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ColumnInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ColumnInfo.displayName = 'proto.mcapi.ColumnInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetMobInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetMobInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetMobInfoResponse.displayName = 'proto.mcapi.SetMobInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetMobInfoResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.SetMobInfoResponseBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.SetMobInfoResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetMobInfoResponseBody.displayName = 'proto.mcapi.SetMobInfoResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.SetMobInfoResponseBody.MobFailure.repeatedFields_, null);
};
goog.inherits(proto.mcapi.SetMobInfoResponseBody.MobFailure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetMobInfoResponseBody.MobFailure.displayName = 'proto.mcapi.SetMobInfoResponseBody.MobFailure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.OpenBinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.OpenBinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.OpenBinRequest.displayName = 'proto.mcapi.OpenBinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.OpenBinRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.OpenBinRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.OpenBinRequestBody.displayName = 'proto.mcapi.OpenBinRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.OpenBinResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.OpenBinResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.OpenBinResponse.displayName = 'proto.mcapi.OpenBinResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.OpenBinResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.OpenBinResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.OpenBinResponseBody.displayName = 'proto.mcapi.OpenBinResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateBinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateBinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateBinRequest.displayName = 'proto.mcapi.CreateBinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateBinRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateBinRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateBinRequestBody.displayName = 'proto.mcapi.CreateBinRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateBinResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateBinResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateBinResponse.displayName = 'proto.mcapi.CreateBinResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateBinResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateBinResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateBinResponseBody.displayName = 'proto.mcapi.CreateBinResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ConfigureSRTStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ConfigureSRTStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ConfigureSRTStreamRequest.displayName = 'proto.mcapi.ConfigureSRTStreamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ConfigureSRTStreamRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ConfigureSRTStreamRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ConfigureSRTStreamRequestBody.displayName = 'proto.mcapi.ConfigureSRTStreamRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ConfigureSRTStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ConfigureSRTStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ConfigureSRTStreamResponse.displayName = 'proto.mcapi.ConfigureSRTStreamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ConfigureSRTStreamResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ConfigureSRTStreamResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ConfigureSRTStreamResponseBody.displayName = 'proto.mcapi.ConfigureSRTStreamResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetSRTStreamSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetSRTStreamSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetSRTStreamSettingsRequest.displayName = 'proto.mcapi.GetSRTStreamSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetSRTStreamSettingsRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetSRTStreamSettingsRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetSRTStreamSettingsRequestBody.displayName = 'proto.mcapi.GetSRTStreamSettingsRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetSRTStreamSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetSRTStreamSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetSRTStreamSettingsResponse.displayName = 'proto.mcapi.GetSRTStreamSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetSRTStreamSettingsResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetSRTStreamSettingsResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetSRTStreamSettingsResponseBody.displayName = 'proto.mcapi.GetSRTStreamSettingsResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetOTSSessionStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetOTSSessionStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetOTSSessionStatusRequest.displayName = 'proto.mcapi.GetOTSSessionStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetOTSSessionStatusRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetOTSSessionStatusRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetOTSSessionStatusRequestBody.displayName = 'proto.mcapi.GetOTSSessionStatusRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetOTSSessionStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetOTSSessionStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetOTSSessionStatusResponse.displayName = 'proto.mcapi.GetOTSSessionStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetOTSSessionStatusResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetOTSSessionStatusResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetOTSSessionStatusResponseBody.displayName = 'proto.mcapi.GetOTSSessionStatusResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetOTSSessionStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetOTSSessionStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetOTSSessionStatusRequest.displayName = 'proto.mcapi.SetOTSSessionStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetOTSSessionStatusRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetOTSSessionStatusRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetOTSSessionStatusRequestBody.displayName = 'proto.mcapi.SetOTSSessionStatusRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetOTSSessionStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetOTSSessionStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetOTSSessionStatusResponse.displayName = 'proto.mcapi.SetOTSSessionStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetOTSSessionStatusResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetOTSSessionStatusResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetOTSSessionStatusResponseBody.displayName = 'proto.mcapi.SetOTSSessionStatusResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMediaVolumeListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMediaVolumeListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMediaVolumeListRequest.displayName = 'proto.mcapi.GetMediaVolumeListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMediaVolumeListRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMediaVolumeListRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMediaVolumeListRequestBody.displayName = 'proto.mcapi.GetMediaVolumeListRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMediaVolumeListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMediaVolumeListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMediaVolumeListResponse.displayName = 'proto.mcapi.GetMediaVolumeListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMediaVolumeListResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.GetMediaVolumeListResponseBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.GetMediaVolumeListResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMediaVolumeListResponseBody.displayName = 'proto.mcapi.GetMediaVolumeListResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.displayName = 'proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetOpenProjectInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetOpenProjectInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetOpenProjectInfoRequest.displayName = 'proto.mcapi.GetOpenProjectInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetOpenProjectInfoRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetOpenProjectInfoRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetOpenProjectInfoRequestBody.displayName = 'proto.mcapi.GetOpenProjectInfoRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetOpenProjectInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetOpenProjectInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetOpenProjectInfoResponse.displayName = 'proto.mcapi.GetOpenProjectInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetOpenProjectInfoResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetOpenProjectInfoResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetOpenProjectInfoResponseBody.displayName = 'proto.mcapi.GetOpenProjectInfoResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetCustomProjectDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetCustomProjectDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetCustomProjectDataRequest.displayName = 'proto.mcapi.GetCustomProjectDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetCustomProjectDataRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetCustomProjectDataRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetCustomProjectDataRequestBody.displayName = 'proto.mcapi.GetCustomProjectDataRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetCustomProjectDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetCustomProjectDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetCustomProjectDataResponse.displayName = 'proto.mcapi.GetCustomProjectDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetCustomProjectDataResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetCustomProjectDataResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetCustomProjectDataResponseBody.displayName = 'proto.mcapi.GetCustomProjectDataResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetCustomProjectDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetCustomProjectDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetCustomProjectDataRequest.displayName = 'proto.mcapi.SetCustomProjectDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetCustomProjectDataRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetCustomProjectDataRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetCustomProjectDataRequestBody.displayName = 'proto.mcapi.SetCustomProjectDataRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetCustomProjectDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetCustomProjectDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetCustomProjectDataResponse.displayName = 'proto.mcapi.SetCustomProjectDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.SetCustomProjectDataResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.SetCustomProjectDataResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.SetCustomProjectDataResponseBody.displayName = 'proto.mcapi.SetCustomProjectDataResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.FrameRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.FrameRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.FrameRate.displayName = 'proto.mcapi.FrameRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ScanAvidMediaFilesFolderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ScanAvidMediaFilesFolderRequest.displayName = 'proto.mcapi.ScanAvidMediaFilesFolderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ScanAvidMediaFilesFolderRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ScanAvidMediaFilesFolderRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ScanAvidMediaFilesFolderRequestBody.displayName = 'proto.mcapi.ScanAvidMediaFilesFolderRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ScanAvidMediaFilesFolderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ScanAvidMediaFilesFolderResponse.displayName = 'proto.mcapi.ScanAvidMediaFilesFolderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ScanAvidMediaFilesFolderResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ScanAvidMediaFilesFolderResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ScanAvidMediaFilesFolderResponseBody.displayName = 'proto.mcapi.ScanAvidMediaFilesFolderResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.displayName = 'proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.displayName = 'proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.displayName = 'proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.displayName = 'proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateCustomColumnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateCustomColumnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateCustomColumnRequest.displayName = 'proto.mcapi.CreateCustomColumnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateCustomColumnRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateCustomColumnRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateCustomColumnRequestBody.displayName = 'proto.mcapi.CreateCustomColumnRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateCustomColumnResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateCustomColumnResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateCustomColumnResponse.displayName = 'proto.mcapi.CreateCustomColumnResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CreateCustomColumnResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CreateCustomColumnResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CreateCustomColumnResponseBody.displayName = 'proto.mcapi.CreateCustomColumnResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfExportEDLSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfExportEDLSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfExportEDLSettingsRequest.displayName = 'proto.mcapi.GetListOfExportEDLSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfExportEDLSettingsRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfExportEDLSettingsRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfExportEDLSettingsRequestBody.displayName = 'proto.mcapi.GetListOfExportEDLSettingsRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfExportEDLSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfExportEDLSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfExportEDLSettingsResponse.displayName = 'proto.mcapi.GetListOfExportEDLSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.GetListOfExportEDLSettingsResponseBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.GetListOfExportEDLSettingsResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfExportEDLSettingsResponseBody.displayName = 'proto.mcapi.GetListOfExportEDLSettingsResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ExportEDLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ExportEDLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ExportEDLRequest.displayName = 'proto.mcapi.ExportEDLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ExportEDLRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ExportEDLRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ExportEDLRequestBody.displayName = 'proto.mcapi.ExportEDLRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.TrackList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.TrackList.repeatedFields_, null);
};
goog.inherits(proto.mcapi.TrackList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.TrackList.displayName = 'proto.mcapi.TrackList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.TrackLabel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.TrackLabel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.TrackLabel.displayName = 'proto.mcapi.TrackLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ExportEDLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ExportEDLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ExportEDLResponse.displayName = 'proto.mcapi.ExportEDLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ExportEDLResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.ExportEDLResponseBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.ExportEDLResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ExportEDLResponseBody.displayName = 'proto.mcapi.ExportEDLResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMobTrackInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMobTrackInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMobTrackInfoRequest.displayName = 'proto.mcapi.GetMobTrackInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMobTrackInfoRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMobTrackInfoRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMobTrackInfoRequestBody.displayName = 'proto.mcapi.GetMobTrackInfoRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMobTrackInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMobTrackInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMobTrackInfoResponse.displayName = 'proto.mcapi.GetMobTrackInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.TrackInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.TrackInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.TrackInfo.displayName = 'proto.mcapi.TrackInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.TrackInfoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.TrackInfoList.repeatedFields_, null);
};
goog.inherits(proto.mcapi.TrackInfoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.TrackInfoList.displayName = 'proto.mcapi.TrackInfoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetMobTrackInfoResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetMobTrackInfoResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetMobTrackInfoResponseBody.displayName = 'proto.mcapi.GetMobTrackInfoResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.RequestHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.RequestHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.RequestHeader.displayName = 'proto.mcapi.RequestHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ResponseHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ResponseHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ResponseHeader.displayName = 'proto.mcapi.ResponseHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.Timestamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.Timestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.Timestamp.displayName = 'proto.mcapi.Timestamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.CommandError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.CommandError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.CommandError.displayName = 'proto.mcapi.CommandError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ExportFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ExportFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ExportFileRequest.displayName = 'proto.mcapi.ExportFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ExportFileRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.ExportFileRequestBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.ExportFileRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ExportFileRequestBody.displayName = 'proto.mcapi.ExportFileRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ExportFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ExportFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ExportFileResponse.displayName = 'proto.mcapi.ExportFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ExportFileResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ExportFileResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ExportFileResponseBody.displayName = 'proto.mcapi.ExportFileResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ImportFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ImportFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ImportFileRequest.displayName = 'proto.mcapi.ImportFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ImportFileRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.ImportFileRequestBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.ImportFileRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ImportFileRequestBody.displayName = 'proto.mcapi.ImportFileRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ImportFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ImportFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ImportFileResponse.displayName = 'proto.mcapi.ImportFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.ImportFileResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.ImportFileResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.ImportFileResponseBody.displayName = 'proto.mcapi.ImportFileResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.LoadSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.LoadSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.LoadSettingRequest.displayName = 'proto.mcapi.LoadSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.LoadSettingRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.LoadSettingRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.LoadSettingRequestBody.displayName = 'proto.mcapi.LoadSettingRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.LoadSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.LoadSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.LoadSettingResponse.displayName = 'proto.mcapi.LoadSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfExportSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfExportSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfExportSettingsRequest.displayName = 'proto.mcapi.GetListOfExportSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfExportSettingsRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfExportSettingsRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfExportSettingsRequestBody.displayName = 'proto.mcapi.GetListOfExportSettingsRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfExportSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfExportSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfExportSettingsResponse.displayName = 'proto.mcapi.GetListOfExportSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfExportSettingsResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.GetListOfExportSettingsResponseBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.GetListOfExportSettingsResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfExportSettingsResponseBody.displayName = 'proto.mcapi.GetListOfExportSettingsResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfImportSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfImportSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfImportSettingsRequest.displayName = 'proto.mcapi.GetListOfImportSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfImportSettingsRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfImportSettingsRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfImportSettingsRequestBody.displayName = 'proto.mcapi.GetListOfImportSettingsRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfImportSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.GetListOfImportSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfImportSettingsResponse.displayName = 'proto.mcapi.GetListOfImportSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.GetListOfImportSettingsResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcapi.GetListOfImportSettingsResponseBody.repeatedFields_, null);
};
goog.inherits(proto.mcapi.GetListOfImportSettingsResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.GetListOfImportSettingsResponseBody.displayName = 'proto.mcapi.GetListOfImportSettingsResponseBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.AddMarkerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.AddMarkerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.AddMarkerRequest.displayName = 'proto.mcapi.AddMarkerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.AddMarkerRequestBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.AddMarkerRequestBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.AddMarkerRequestBody.displayName = 'proto.mcapi.AddMarkerRequestBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.AddMarkerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.AddMarkerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.AddMarkerResponse.displayName = 'proto.mcapi.AddMarkerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcapi.AddMarkerResponseBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcapi.AddMarkerResponseBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcapi.AddMarkerResponseBody.displayName = 'proto.mcapi.AddMarkerResponseBody';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfBinItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfBinItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfBinItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfBinItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetListOfBinItemsRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfBinItemsRequest}
 */
proto.mcapi.GetListOfBinItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfBinItemsRequest;
  return proto.mcapi.GetListOfBinItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfBinItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfBinItemsRequest}
 */
proto.mcapi.GetListOfBinItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetListOfBinItemsRequestBody;
      reader.readMessage(value,proto.mcapi.GetListOfBinItemsRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfBinItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfBinItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfBinItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfBinItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetListOfBinItemsRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetListOfBinItemsRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetListOfBinItemsRequest} returns this
*/
proto.mcapi.GetListOfBinItemsRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfBinItemsRequest} returns this
 */
proto.mcapi.GetListOfBinItemsRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfBinItemsRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetListOfBinItemsRequestBody body = 2;
 * @return {?proto.mcapi.GetListOfBinItemsRequestBody}
 */
proto.mcapi.GetListOfBinItemsRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetListOfBinItemsRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetListOfBinItemsRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetListOfBinItemsRequestBody|undefined} value
 * @return {!proto.mcapi.GetListOfBinItemsRequest} returns this
*/
proto.mcapi.GetListOfBinItemsRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfBinItemsRequest} returns this
 */
proto.mcapi.GetListOfBinItemsRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfBinItemsRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfBinItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfBinItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfBinItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfBinItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetListOfBinItemsResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfBinItemsResponse}
 */
proto.mcapi.GetListOfBinItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfBinItemsResponse;
  return proto.mcapi.GetListOfBinItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfBinItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfBinItemsResponse}
 */
proto.mcapi.GetListOfBinItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetListOfBinItemsResponseBody;
      reader.readMessage(value,proto.mcapi.GetListOfBinItemsResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfBinItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfBinItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfBinItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfBinItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetListOfBinItemsResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetListOfBinItemsResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetListOfBinItemsResponse} returns this
*/
proto.mcapi.GetListOfBinItemsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfBinItemsResponse} returns this
 */
proto.mcapi.GetListOfBinItemsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfBinItemsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetListOfBinItemsResponseBody body = 2;
 * @return {?proto.mcapi.GetListOfBinItemsResponseBody}
 */
proto.mcapi.GetListOfBinItemsResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetListOfBinItemsResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetListOfBinItemsResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetListOfBinItemsResponseBody|undefined} value
 * @return {!proto.mcapi.GetListOfBinItemsResponse} returns this
*/
proto.mcapi.GetListOfBinItemsResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfBinItemsResponse} returns this
 */
proto.mcapi.GetListOfBinItemsResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfBinItemsResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.GetListOfBinItemsRequestBody.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfBinItemsRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfBinItemsRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfBinItemsRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    binRelativePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    binFlagsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    onlyVisibleFlag: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    onlySelectedFlag: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfBinItemsRequestBody}
 */
proto.mcapi.GetListOfBinItemsRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfBinItemsRequestBody;
  return proto.mcapi.GetListOfBinItemsRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfBinItemsRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfBinItemsRequestBody}
 */
proto.mcapi.GetListOfBinItemsRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBinRelativePath(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.mcapi.GetListOfBinItemsRequestBody.BinItemFlags>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBinFlags(values[i]);
      }
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyVisibleFlag(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlySelectedFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfBinItemsRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfBinItemsRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfBinItemsRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBinRelativePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBinFlagsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getOnlyVisibleFlag();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOnlySelectedFlag();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mcapi.GetListOfBinItemsRequestBody.BinItemFlags = {
  ALLTYPES: 0,
  MASTERCLIPS: 1,
  LINKEDMASTERCLIPS: 2,
  SUBCLIPS: 3,
  SEQUENCES: 4,
  SOURCES: 5,
  EFFECTS: 6,
  MOTIONEFFECTS: 7,
  PRECOMPSRE: 8,
  PRECOMPSTMK: 9,
  GROUPS: 10,
  STEREOSCOPICCLIPS: 11
};

/**
 * optional string bin_relative_path = 1;
 * @return {string}
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.getBinRelativePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetListOfBinItemsRequestBody} returns this
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.setBinRelativePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated BinItemFlags bin_flags = 2;
 * @return {!Array<!proto.mcapi.GetListOfBinItemsRequestBody.BinItemFlags>}
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.getBinFlagsList = function() {
  return /** @type {!Array<!proto.mcapi.GetListOfBinItemsRequestBody.BinItemFlags>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.mcapi.GetListOfBinItemsRequestBody.BinItemFlags>} value
 * @return {!proto.mcapi.GetListOfBinItemsRequestBody} returns this
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.setBinFlagsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.mcapi.GetListOfBinItemsRequestBody.BinItemFlags} value
 * @param {number=} opt_index
 * @return {!proto.mcapi.GetListOfBinItemsRequestBody} returns this
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.addBinFlags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.GetListOfBinItemsRequestBody} returns this
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.clearBinFlagsList = function() {
  return this.setBinFlagsList([]);
};


/**
 * optional bool only_visible_flag = 3;
 * @return {boolean}
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.getOnlyVisibleFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetListOfBinItemsRequestBody} returns this
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.setOnlyVisibleFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool only_selected_flag = 4;
 * @return {boolean}
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.getOnlySelectedFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetListOfBinItemsRequestBody} returns this
 */
proto.mcapi.GetListOfBinItemsRequestBody.prototype.setOnlySelectedFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfBinItemsResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfBinItemsResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfBinItemsResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfBinItemsResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mobSelected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfBinItemsResponseBody}
 */
proto.mcapi.GetListOfBinItemsResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfBinItemsResponseBody;
  return proto.mcapi.GetListOfBinItemsResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfBinItemsResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfBinItemsResponseBody}
 */
proto.mcapi.GetListOfBinItemsResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMobSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfBinItemsResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfBinItemsResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfBinItemsResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfBinItemsResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMobSelected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string mob_name = 1;
 * @return {string}
 */
proto.mcapi.GetListOfBinItemsResponseBody.prototype.getMobName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetListOfBinItemsResponseBody} returns this
 */
proto.mcapi.GetListOfBinItemsResponseBody.prototype.setMobName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mob_id = 2;
 * @return {string}
 */
proto.mcapi.GetListOfBinItemsResponseBody.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetListOfBinItemsResponseBody} returns this
 */
proto.mcapi.GetListOfBinItemsResponseBody.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool mob_selected = 3;
 * @return {boolean}
 */
proto.mcapi.GetListOfBinItemsResponseBody.prototype.getMobSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetListOfBinItemsResponseBody} returns this
 */
proto.mcapi.GetListOfBinItemsResponseBody.prototype.setMobSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.EchoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.EchoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.EchoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.EchoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.EchoRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.EchoRequest}
 */
proto.mcapi.EchoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.EchoRequest;
  return proto.mcapi.EchoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.EchoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.EchoRequest}
 */
proto.mcapi.EchoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.EchoRequestBody;
      reader.readMessage(value,proto.mcapi.EchoRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.EchoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.EchoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.EchoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.EchoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.EchoRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.EchoRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.EchoRequest} returns this
*/
proto.mcapi.EchoRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.EchoRequest} returns this
 */
proto.mcapi.EchoRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.EchoRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EchoRequestBody body = 2;
 * @return {?proto.mcapi.EchoRequestBody}
 */
proto.mcapi.EchoRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.EchoRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.EchoRequestBody, 2));
};


/**
 * @param {?proto.mcapi.EchoRequestBody|undefined} value
 * @return {!proto.mcapi.EchoRequest} returns this
*/
proto.mcapi.EchoRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.EchoRequest} returns this
 */
proto.mcapi.EchoRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.EchoRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.EchoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.EchoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.EchoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.EchoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.EchoResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.EchoResponse}
 */
proto.mcapi.EchoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.EchoResponse;
  return proto.mcapi.EchoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.EchoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.EchoResponse}
 */
proto.mcapi.EchoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.EchoResponseBody;
      reader.readMessage(value,proto.mcapi.EchoResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.EchoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.EchoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.EchoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.EchoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.EchoResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.EchoResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.EchoResponse} returns this
*/
proto.mcapi.EchoResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.EchoResponse} returns this
 */
proto.mcapi.EchoResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.EchoResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EchoResponseBody body = 2;
 * @return {?proto.mcapi.EchoResponseBody}
 */
proto.mcapi.EchoResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.EchoResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.EchoResponseBody, 2));
};


/**
 * @param {?proto.mcapi.EchoResponseBody|undefined} value
 * @return {!proto.mcapi.EchoResponse} returns this
*/
proto.mcapi.EchoResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.EchoResponse} returns this
 */
proto.mcapi.EchoResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.EchoResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.EchoRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.EchoRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.EchoRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.EchoRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.EchoRequestBody}
 */
proto.mcapi.EchoRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.EchoRequestBody;
  return proto.mcapi.EchoRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.EchoRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.EchoRequestBody}
 */
proto.mcapi.EchoRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.EchoRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.EchoRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.EchoRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.EchoRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mcapi.EchoRequestBody.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.EchoRequestBody} returns this
 */
proto.mcapi.EchoRequestBody.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.EchoResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.EchoResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.EchoResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.EchoResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.EchoResponseBody}
 */
proto.mcapi.EchoResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.EchoResponseBody;
  return proto.mcapi.EchoResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.EchoResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.EchoResponseBody}
 */
proto.mcapi.EchoResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.EchoResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.EchoResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.EchoResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.EchoResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.mcapi.EchoResponseBody.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.EchoResponseBody} returns this
 */
proto.mcapi.EchoResponseBody.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetValuesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetValuesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetValuesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetValuesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetValuesRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetValuesRequest}
 */
proto.mcapi.GetValuesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetValuesRequest;
  return proto.mcapi.GetValuesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetValuesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetValuesRequest}
 */
proto.mcapi.GetValuesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetValuesRequestBody;
      reader.readMessage(value,proto.mcapi.GetValuesRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetValuesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetValuesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetValuesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetValuesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetValuesRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetValuesRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetValuesRequest} returns this
*/
proto.mcapi.GetValuesRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetValuesRequest} returns this
 */
proto.mcapi.GetValuesRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetValuesRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetValuesRequestBody body = 2;
 * @return {?proto.mcapi.GetValuesRequestBody}
 */
proto.mcapi.GetValuesRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetValuesRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetValuesRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetValuesRequestBody|undefined} value
 * @return {!proto.mcapi.GetValuesRequest} returns this
*/
proto.mcapi.GetValuesRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetValuesRequest} returns this
 */
proto.mcapi.GetValuesRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetValuesRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetValuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetValuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetValuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetValuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetValuesResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetValuesResponse}
 */
proto.mcapi.GetValuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetValuesResponse;
  return proto.mcapi.GetValuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetValuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetValuesResponse}
 */
proto.mcapi.GetValuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetValuesResponseBody;
      reader.readMessage(value,proto.mcapi.GetValuesResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetValuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetValuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetValuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetValuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetValuesResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetValuesResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetValuesResponse} returns this
*/
proto.mcapi.GetValuesResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetValuesResponse} returns this
 */
proto.mcapi.GetValuesResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetValuesResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetValuesResponseBody body = 2;
 * @return {?proto.mcapi.GetValuesResponseBody}
 */
proto.mcapi.GetValuesResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetValuesResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetValuesResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetValuesResponseBody|undefined} value
 * @return {!proto.mcapi.GetValuesResponse} returns this
*/
proto.mcapi.GetValuesResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetValuesResponse} returns this
 */
proto.mcapi.GetValuesResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetValuesResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetValuesRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetValuesRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetValuesRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetValuesRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetValuesRequestBody}
 */
proto.mcapi.GetValuesRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetValuesRequestBody;
  return proto.mcapi.GetValuesRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetValuesRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetValuesRequestBody}
 */
proto.mcapi.GetValuesRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetValuesRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetValuesRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetValuesRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetValuesRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.mcapi.GetValuesRequestBody.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetValuesRequestBody} returns this
 */
proto.mcapi.GetValuesRequestBody.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 count = 2;
 * @return {number}
 */
proto.mcapi.GetValuesRequestBody.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.GetValuesRequestBody} returns this
 */
proto.mcapi.GetValuesRequestBody.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetValuesResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetValuesResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetValuesResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetValuesResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetValuesResponseBody}
 */
proto.mcapi.GetValuesResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetValuesResponseBody;
  return proto.mcapi.GetValuesResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetValuesResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetValuesResponseBody}
 */
proto.mcapi.GetValuesResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetValuesResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetValuesResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetValuesResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetValuesResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.mcapi.GetValuesResponseBody.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetValuesResponseBody} returns this
 */
proto.mcapi.GetValuesResponseBody.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetAppInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetAppInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetAppInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetAppInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetAppInfoRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetAppInfoRequest}
 */
proto.mcapi.GetAppInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetAppInfoRequest;
  return proto.mcapi.GetAppInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetAppInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetAppInfoRequest}
 */
proto.mcapi.GetAppInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetAppInfoRequestBody;
      reader.readMessage(value,proto.mcapi.GetAppInfoRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetAppInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetAppInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetAppInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetAppInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetAppInfoRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetAppInfoRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetAppInfoRequest} returns this
*/
proto.mcapi.GetAppInfoRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetAppInfoRequest} returns this
 */
proto.mcapi.GetAppInfoRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetAppInfoRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetAppInfoRequestBody body = 2;
 * @return {?proto.mcapi.GetAppInfoRequestBody}
 */
proto.mcapi.GetAppInfoRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetAppInfoRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetAppInfoRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetAppInfoRequestBody|undefined} value
 * @return {!proto.mcapi.GetAppInfoRequest} returns this
*/
proto.mcapi.GetAppInfoRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetAppInfoRequest} returns this
 */
proto.mcapi.GetAppInfoRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetAppInfoRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetAppInfoRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetAppInfoRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetAppInfoRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetAppInfoRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetAppInfoRequestBody}
 */
proto.mcapi.GetAppInfoRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetAppInfoRequestBody;
  return proto.mcapi.GetAppInfoRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetAppInfoRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetAppInfoRequestBody}
 */
proto.mcapi.GetAppInfoRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetAppInfoRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetAppInfoRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetAppInfoRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetAppInfoRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetAppInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetAppInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetAppInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetAppInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetAppInfoResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetAppInfoResponse}
 */
proto.mcapi.GetAppInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetAppInfoResponse;
  return proto.mcapi.GetAppInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetAppInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetAppInfoResponse}
 */
proto.mcapi.GetAppInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetAppInfoResponseBody;
      reader.readMessage(value,proto.mcapi.GetAppInfoResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetAppInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetAppInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetAppInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetAppInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetAppInfoResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetAppInfoResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetAppInfoResponse} returns this
*/
proto.mcapi.GetAppInfoResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetAppInfoResponse} returns this
 */
proto.mcapi.GetAppInfoResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetAppInfoResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetAppInfoResponseBody body = 2;
 * @return {?proto.mcapi.GetAppInfoResponseBody}
 */
proto.mcapi.GetAppInfoResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetAppInfoResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetAppInfoResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetAppInfoResponseBody|undefined} value
 * @return {!proto.mcapi.GetAppInfoResponse} returns this
*/
proto.mcapi.GetAppInfoResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetAppInfoResponse} returns this
 */
proto.mcapi.GetAppInfoResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetAppInfoResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetAppInfoResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetAppInfoResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetAppInfoResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetAppInfoResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    appName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appBusyStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetAppInfoResponseBody}
 */
proto.mcapi.GetAppInfoResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetAppInfoResponseBody;
  return proto.mcapi.GetAppInfoResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetAppInfoResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetAppInfoResponseBody}
 */
proto.mcapi.GetAppInfoResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppVersion(value);
      break;
    case 3:
      var value = /** @type {!proto.mcapi.GetAppInfoResponseBody.AppBusyStatus} */ (reader.readEnum());
      msg.setAppBusyStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetAppInfoResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetAppInfoResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetAppInfoResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetAppInfoResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppBusyStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mcapi.GetAppInfoResponseBody.AppBusyStatus = {
  IDLE: 0,
  PLAYING: 1,
  MODALDIALOG: 2,
  BUSY: 3
};

/**
 * optional string app_name = 1;
 * @return {string}
 */
proto.mcapi.GetAppInfoResponseBody.prototype.getAppName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetAppInfoResponseBody} returns this
 */
proto.mcapi.GetAppInfoResponseBody.prototype.setAppName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string app_version = 2;
 * @return {string}
 */
proto.mcapi.GetAppInfoResponseBody.prototype.getAppVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetAppInfoResponseBody} returns this
 */
proto.mcapi.GetAppInfoResponseBody.prototype.setAppVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AppBusyStatus app_busy_status = 3;
 * @return {!proto.mcapi.GetAppInfoResponseBody.AppBusyStatus}
 */
proto.mcapi.GetAppInfoResponseBody.prototype.getAppBusyStatus = function() {
  return /** @type {!proto.mcapi.GetAppInfoResponseBody.AppBusyStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mcapi.GetAppInfoResponseBody.AppBusyStatus} value
 * @return {!proto.mcapi.GetAppInfoResponseBody} returns this
 */
proto.mcapi.GetAppInfoResponseBody.prototype.setAppBusyStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetBinsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetBinsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetBinsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetBinsRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetBinsRequest}
 */
proto.mcapi.GetBinsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetBinsRequest;
  return proto.mcapi.GetBinsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetBinsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetBinsRequest}
 */
proto.mcapi.GetBinsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetBinsRequestBody;
      reader.readMessage(value,proto.mcapi.GetBinsRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetBinsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetBinsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetBinsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetBinsRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetBinsRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetBinsRequest} returns this
*/
proto.mcapi.GetBinsRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetBinsRequest} returns this
 */
proto.mcapi.GetBinsRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetBinsRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetBinsRequestBody body = 2;
 * @return {?proto.mcapi.GetBinsRequestBody}
 */
proto.mcapi.GetBinsRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetBinsRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetBinsRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetBinsRequestBody|undefined} value
 * @return {!proto.mcapi.GetBinsRequest} returns this
*/
proto.mcapi.GetBinsRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetBinsRequest} returns this
 */
proto.mcapi.GetBinsRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetBinsRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.GetBinsRequestBody.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetBinsRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetBinsRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetBinsRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinsRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requestFlagList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetBinsRequestBody}
 */
proto.mcapi.GetBinsRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetBinsRequestBody;
  return proto.mcapi.GetBinsRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetBinsRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetBinsRequestBody}
 */
proto.mcapi.GetBinsRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectPath(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.mcapi.GetBinsRequestBody.GetBinsFlag>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRequestFlag(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetBinsRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetBinsRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetBinsRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinsRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequestFlagList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mcapi.GetBinsRequestBody.GetBinsFlag = {
  ALLTYPES: 0,
  BINTYPE: 1,
  SCRIPTTYPE: 2,
  VOLUMETYPE: 3
};

/**
 * optional string project_path = 1;
 * @return {string}
 */
proto.mcapi.GetBinsRequestBody.prototype.getProjectPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetBinsRequestBody} returns this
 */
proto.mcapi.GetBinsRequestBody.prototype.setProjectPath = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mcapi.GetBinsRequestBody} returns this
 */
proto.mcapi.GetBinsRequestBody.prototype.clearProjectPath = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetBinsRequestBody.prototype.hasProjectPath = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GetBinsFlag request_flag = 2;
 * @return {!Array<!proto.mcapi.GetBinsRequestBody.GetBinsFlag>}
 */
proto.mcapi.GetBinsRequestBody.prototype.getRequestFlagList = function() {
  return /** @type {!Array<!proto.mcapi.GetBinsRequestBody.GetBinsFlag>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.mcapi.GetBinsRequestBody.GetBinsFlag>} value
 * @return {!proto.mcapi.GetBinsRequestBody} returns this
 */
proto.mcapi.GetBinsRequestBody.prototype.setRequestFlagList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.mcapi.GetBinsRequestBody.GetBinsFlag} value
 * @param {number=} opt_index
 * @return {!proto.mcapi.GetBinsRequestBody} returns this
 */
proto.mcapi.GetBinsRequestBody.prototype.addRequestFlag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.GetBinsRequestBody} returns this
 */
proto.mcapi.GetBinsRequestBody.prototype.clearRequestFlagList = function() {
  return this.setRequestFlagList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetBinsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetBinsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetBinsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetBinsResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetBinsResponse}
 */
proto.mcapi.GetBinsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetBinsResponse;
  return proto.mcapi.GetBinsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetBinsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetBinsResponse}
 */
proto.mcapi.GetBinsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetBinsResponseBody;
      reader.readMessage(value,proto.mcapi.GetBinsResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetBinsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetBinsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetBinsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetBinsResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetBinsResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetBinsResponse} returns this
*/
proto.mcapi.GetBinsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetBinsResponse} returns this
 */
proto.mcapi.GetBinsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetBinsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetBinsResponseBody body = 2;
 * @return {?proto.mcapi.GetBinsResponseBody}
 */
proto.mcapi.GetBinsResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetBinsResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetBinsResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetBinsResponseBody|undefined} value
 * @return {!proto.mcapi.GetBinsResponse} returns this
*/
proto.mcapi.GetBinsResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetBinsResponse} returns this
 */
proto.mcapi.GetBinsResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetBinsResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetBinsResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetBinsResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetBinsResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinsResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    absolutePath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetBinsResponseBody}
 */
proto.mcapi.GetBinsResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetBinsResponseBody;
  return proto.mcapi.GetBinsResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetBinsResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetBinsResponseBody}
 */
proto.mcapi.GetBinsResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbsolutePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetBinsResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetBinsResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetBinsResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinsResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbsolutePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string absolute_path = 1;
 * @return {string}
 */
proto.mcapi.GetBinsResponseBody.prototype.getAbsolutePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetBinsResponseBody} returns this
 */
proto.mcapi.GetBinsResponseBody.prototype.setAbsolutePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetBinInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetBinInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetBinInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetBinInfoRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetBinInfoRequest}
 */
proto.mcapi.GetBinInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetBinInfoRequest;
  return proto.mcapi.GetBinInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetBinInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetBinInfoRequest}
 */
proto.mcapi.GetBinInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetBinInfoRequestBody;
      reader.readMessage(value,proto.mcapi.GetBinInfoRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetBinInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetBinInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetBinInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetBinInfoRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetBinInfoRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetBinInfoRequest} returns this
*/
proto.mcapi.GetBinInfoRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetBinInfoRequest} returns this
 */
proto.mcapi.GetBinInfoRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetBinInfoRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetBinInfoRequestBody body = 2;
 * @return {?proto.mcapi.GetBinInfoRequestBody}
 */
proto.mcapi.GetBinInfoRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetBinInfoRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetBinInfoRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetBinInfoRequestBody|undefined} value
 * @return {!proto.mcapi.GetBinInfoRequest} returns this
*/
proto.mcapi.GetBinInfoRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetBinInfoRequest} returns this
 */
proto.mcapi.GetBinInfoRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetBinInfoRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetBinInfoRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetBinInfoRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetBinInfoRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinInfoRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    relativeBinPath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetBinInfoRequestBody}
 */
proto.mcapi.GetBinInfoRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetBinInfoRequestBody;
  return proto.mcapi.GetBinInfoRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetBinInfoRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetBinInfoRequestBody}
 */
proto.mcapi.GetBinInfoRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelativeBinPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetBinInfoRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetBinInfoRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetBinInfoRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinInfoRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelativeBinPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string relative_bin_path = 1;
 * @return {string}
 */
proto.mcapi.GetBinInfoRequestBody.prototype.getRelativeBinPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetBinInfoRequestBody} returns this
 */
proto.mcapi.GetBinInfoRequestBody.prototype.setRelativeBinPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetBinInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetBinInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetBinInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetBinInfoResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetBinInfoResponse}
 */
proto.mcapi.GetBinInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetBinInfoResponse;
  return proto.mcapi.GetBinInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetBinInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetBinInfoResponse}
 */
proto.mcapi.GetBinInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetBinInfoResponseBody;
      reader.readMessage(value,proto.mcapi.GetBinInfoResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetBinInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetBinInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetBinInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetBinInfoResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetBinInfoResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetBinInfoResponse} returns this
*/
proto.mcapi.GetBinInfoResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetBinInfoResponse} returns this
 */
proto.mcapi.GetBinInfoResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetBinInfoResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetBinInfoResponseBody body = 2;
 * @return {?proto.mcapi.GetBinInfoResponseBody}
 */
proto.mcapi.GetBinInfoResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetBinInfoResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetBinInfoResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetBinInfoResponseBody|undefined} value
 * @return {!proto.mcapi.GetBinInfoResponse} returns this
*/
proto.mcapi.GetBinInfoResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetBinInfoResponse} returns this
 */
proto.mcapi.GetBinInfoResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetBinInfoResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetBinInfoResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetBinInfoResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetBinInfoResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinInfoResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    lockState: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    lockOwner: jspb.Message.getFieldWithDefault(msg, 2, ""),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isOpen: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    backgroundColor: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetBinInfoResponseBody}
 */
proto.mcapi.GetBinInfoResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetBinInfoResponseBody;
  return proto.mcapi.GetBinInfoResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetBinInfoResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetBinInfoResponseBody}
 */
proto.mcapi.GetBinInfoResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLockState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockOwner(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOpen(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetBinInfoResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetBinInfoResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetBinInfoResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetBinInfoResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLockState();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLockOwner();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getIsOpen();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBackgroundColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bool lock_state = 1;
 * @return {boolean}
 */
proto.mcapi.GetBinInfoResponseBody.prototype.getLockState = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetBinInfoResponseBody} returns this
 */
proto.mcapi.GetBinInfoResponseBody.prototype.setLockState = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string lock_owner = 2;
 * @return {string}
 */
proto.mcapi.GetBinInfoResponseBody.prototype.getLockOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetBinInfoResponseBody} returns this
 */
proto.mcapi.GetBinInfoResponseBody.prototype.setLockOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 size = 3;
 * @return {number}
 */
proto.mcapi.GetBinInfoResponseBody.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.GetBinInfoResponseBody} returns this
 */
proto.mcapi.GetBinInfoResponseBody.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_open = 4;
 * @return {boolean}
 */
proto.mcapi.GetBinInfoResponseBody.prototype.getIsOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetBinInfoResponseBody} returns this
 */
proto.mcapi.GetBinInfoResponseBody.prototype.setIsOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string background_color = 5;
 * @return {string}
 */
proto.mcapi.GetBinInfoResponseBody.prototype.getBackgroundColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetBinInfoResponseBody} returns this
 */
proto.mcapi.GetBinInfoResponseBody.prototype.setBackgroundColor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMobInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMobInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMobInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetMobInfoRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMobInfoRequest}
 */
proto.mcapi.GetMobInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMobInfoRequest;
  return proto.mcapi.GetMobInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMobInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMobInfoRequest}
 */
proto.mcapi.GetMobInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetMobInfoRequestBody;
      reader.readMessage(value,proto.mcapi.GetMobInfoRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMobInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMobInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMobInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetMobInfoRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetMobInfoRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetMobInfoRequest} returns this
*/
proto.mcapi.GetMobInfoRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMobInfoRequest} returns this
 */
proto.mcapi.GetMobInfoRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMobInfoRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetMobInfoRequestBody body = 2;
 * @return {?proto.mcapi.GetMobInfoRequestBody}
 */
proto.mcapi.GetMobInfoRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetMobInfoRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetMobInfoRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetMobInfoRequestBody|undefined} value
 * @return {!proto.mcapi.GetMobInfoRequest} returns this
*/
proto.mcapi.GetMobInfoRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMobInfoRequest} returns this
 */
proto.mcapi.GetMobInfoRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMobInfoRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMobInfoRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMobInfoRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMobInfoRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobInfoRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    onlyVisibleColumns: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includesEmptyColumns: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMobInfoRequestBody}
 */
proto.mcapi.GetMobInfoRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMobInfoRequestBody;
  return proto.mcapi.GetMobInfoRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMobInfoRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMobInfoRequestBody}
 */
proto.mcapi.GetMobInfoRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyVisibleColumns(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludesEmptyColumns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMobInfoRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMobInfoRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMobInfoRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobInfoRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOnlyVisibleColumns();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludesEmptyColumns();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string mob_id = 1;
 * @return {string}
 */
proto.mcapi.GetMobInfoRequestBody.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetMobInfoRequestBody} returns this
 */
proto.mcapi.GetMobInfoRequestBody.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool only_visible_columns = 2;
 * @return {boolean}
 */
proto.mcapi.GetMobInfoRequestBody.prototype.getOnlyVisibleColumns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetMobInfoRequestBody} returns this
 */
proto.mcapi.GetMobInfoRequestBody.prototype.setOnlyVisibleColumns = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool includes_empty_columns = 3;
 * @return {boolean}
 */
proto.mcapi.GetMobInfoRequestBody.prototype.getIncludesEmptyColumns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetMobInfoRequestBody} returns this
 */
proto.mcapi.GetMobInfoRequestBody.prototype.setIncludesEmptyColumns = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMobInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMobInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMobInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetMobInfoResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMobInfoResponse}
 */
proto.mcapi.GetMobInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMobInfoResponse;
  return proto.mcapi.GetMobInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMobInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMobInfoResponse}
 */
proto.mcapi.GetMobInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetMobInfoResponseBody;
      reader.readMessage(value,proto.mcapi.GetMobInfoResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMobInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMobInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMobInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetMobInfoResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetMobInfoResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetMobInfoResponse} returns this
*/
proto.mcapi.GetMobInfoResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMobInfoResponse} returns this
 */
proto.mcapi.GetMobInfoResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMobInfoResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetMobInfoResponseBody body = 2;
 * @return {?proto.mcapi.GetMobInfoResponseBody}
 */
proto.mcapi.GetMobInfoResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetMobInfoResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetMobInfoResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetMobInfoResponseBody|undefined} value
 * @return {!proto.mcapi.GetMobInfoResponse} returns this
*/
proto.mcapi.GetMobInfoResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMobInfoResponse} returns this
 */
proto.mcapi.GetMobInfoResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMobInfoResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMobInfoResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMobInfoResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMobInfoResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobInfoResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    columnName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    columnValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    columnValueType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    columnHidden: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    columnIsCustom: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMobInfoResponseBody}
 */
proto.mcapi.GetMobInfoResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMobInfoResponseBody;
  return proto.mcapi.GetMobInfoResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMobInfoResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMobInfoResponseBody}
 */
proto.mcapi.GetMobInfoResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumnName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumnValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumnValueType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setColumnHidden(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setColumnIsCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMobInfoResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMobInfoResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMobInfoResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobInfoResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumnName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColumnValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColumnValueType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getColumnHidden();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getColumnIsCustom();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string column_name = 1;
 * @return {string}
 */
proto.mcapi.GetMobInfoResponseBody.prototype.getColumnName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetMobInfoResponseBody} returns this
 */
proto.mcapi.GetMobInfoResponseBody.prototype.setColumnName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string column_value = 2;
 * @return {string}
 */
proto.mcapi.GetMobInfoResponseBody.prototype.getColumnValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetMobInfoResponseBody} returns this
 */
proto.mcapi.GetMobInfoResponseBody.prototype.setColumnValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string column_value_type = 3;
 * @return {string}
 */
proto.mcapi.GetMobInfoResponseBody.prototype.getColumnValueType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetMobInfoResponseBody} returns this
 */
proto.mcapi.GetMobInfoResponseBody.prototype.setColumnValueType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool column_hidden = 4;
 * @return {boolean}
 */
proto.mcapi.GetMobInfoResponseBody.prototype.getColumnHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetMobInfoResponseBody} returns this
 */
proto.mcapi.GetMobInfoResponseBody.prototype.setColumnHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool column_is_custom = 5;
 * @return {boolean}
 */
proto.mcapi.GetMobInfoResponseBody.prototype.getColumnIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetMobInfoResponseBody} returns this
 */
proto.mcapi.GetMobInfoResponseBody.prototype.setColumnIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetMobInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetMobInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetMobInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.SetMobInfoRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetMobInfoRequest}
 */
proto.mcapi.SetMobInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetMobInfoRequest;
  return proto.mcapi.SetMobInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetMobInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetMobInfoRequest}
 */
proto.mcapi.SetMobInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.SetMobInfoRequestBody;
      reader.readMessage(value,proto.mcapi.SetMobInfoRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetMobInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetMobInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetMobInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.SetMobInfoRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.SetMobInfoRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.SetMobInfoRequest} returns this
*/
proto.mcapi.SetMobInfoRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetMobInfoRequest} returns this
 */
proto.mcapi.SetMobInfoRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetMobInfoRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetMobInfoRequestBody body = 2;
 * @return {?proto.mcapi.SetMobInfoRequestBody}
 */
proto.mcapi.SetMobInfoRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.SetMobInfoRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.SetMobInfoRequestBody, 2));
};


/**
 * @param {?proto.mcapi.SetMobInfoRequestBody|undefined} value
 * @return {!proto.mcapi.SetMobInfoRequest} returns this
*/
proto.mcapi.SetMobInfoRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetMobInfoRequest} returns this
 */
proto.mcapi.SetMobInfoRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetMobInfoRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetMobInfoRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetMobInfoRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetMobInfoRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    column: (f = msg.getColumn()) && proto.mcapi.ColumnInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetMobInfoRequestBody}
 */
proto.mcapi.SetMobInfoRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetMobInfoRequestBody;
  return proto.mcapi.SetMobInfoRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetMobInfoRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetMobInfoRequestBody}
 */
proto.mcapi.SetMobInfoRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    case 2:
      var value = new proto.mcapi.ColumnInfo;
      reader.readMessage(value,proto.mcapi.ColumnInfo.deserializeBinaryFromReader);
      msg.setColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetMobInfoRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetMobInfoRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetMobInfoRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColumn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ColumnInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string mob_id = 1;
 * @return {string}
 */
proto.mcapi.SetMobInfoRequestBody.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetMobInfoRequestBody} returns this
 */
proto.mcapi.SetMobInfoRequestBody.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ColumnInfo column = 2;
 * @return {?proto.mcapi.ColumnInfo}
 */
proto.mcapi.SetMobInfoRequestBody.prototype.getColumn = function() {
  return /** @type{?proto.mcapi.ColumnInfo} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ColumnInfo, 2));
};


/**
 * @param {?proto.mcapi.ColumnInfo|undefined} value
 * @return {!proto.mcapi.SetMobInfoRequestBody} returns this
*/
proto.mcapi.SetMobInfoRequestBody.prototype.setColumn = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetMobInfoRequestBody} returns this
 */
proto.mcapi.SetMobInfoRequestBody.prototype.clearColumn = function() {
  return this.setColumn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetMobInfoRequestBody.prototype.hasColumn = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ColumnInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ColumnInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ColumnInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ColumnInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    columnName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    columnValue: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ColumnInfo}
 */
proto.mcapi.ColumnInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ColumnInfo;
  return proto.mcapi.ColumnInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ColumnInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ColumnInfo}
 */
proto.mcapi.ColumnInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumnName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumnValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ColumnInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ColumnInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ColumnInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ColumnInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumnName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColumnValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string column_name = 1;
 * @return {string}
 */
proto.mcapi.ColumnInfo.prototype.getColumnName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ColumnInfo} returns this
 */
proto.mcapi.ColumnInfo.prototype.setColumnName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string column_value = 2;
 * @return {string}
 */
proto.mcapi.ColumnInfo.prototype.getColumnValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ColumnInfo} returns this
 */
proto.mcapi.ColumnInfo.prototype.setColumnValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetMobInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetMobInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetMobInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.SetMobInfoResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetMobInfoResponse}
 */
proto.mcapi.SetMobInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetMobInfoResponse;
  return proto.mcapi.SetMobInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetMobInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetMobInfoResponse}
 */
proto.mcapi.SetMobInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.SetMobInfoResponseBody;
      reader.readMessage(value,proto.mcapi.SetMobInfoResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetMobInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetMobInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetMobInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.SetMobInfoResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.SetMobInfoResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.SetMobInfoResponse} returns this
*/
proto.mcapi.SetMobInfoResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetMobInfoResponse} returns this
 */
proto.mcapi.SetMobInfoResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetMobInfoResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetMobInfoResponseBody body = 2;
 * @return {?proto.mcapi.SetMobInfoResponseBody}
 */
proto.mcapi.SetMobInfoResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.SetMobInfoResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.SetMobInfoResponseBody, 2));
};


/**
 * @param {?proto.mcapi.SetMobInfoResponseBody|undefined} value
 * @return {!proto.mcapi.SetMobInfoResponse} returns this
*/
proto.mcapi.SetMobInfoResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetMobInfoResponse} returns this
 */
proto.mcapi.SetMobInfoResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetMobInfoResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.SetMobInfoResponseBody.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetMobInfoResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetMobInfoResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetMobInfoResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobFailureList: jspb.Message.toObjectList(msg.getMobFailureList(),
    proto.mcapi.SetMobInfoResponseBody.MobFailure.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetMobInfoResponseBody}
 */
proto.mcapi.SetMobInfoResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetMobInfoResponseBody;
  return proto.mcapi.SetMobInfoResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetMobInfoResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetMobInfoResponseBody}
 */
proto.mcapi.SetMobInfoResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.SetMobInfoResponseBody.MobFailure;
      reader.readMessage(value,proto.mcapi.SetMobInfoResponseBody.MobFailure.deserializeBinaryFromReader);
      msg.addMobFailure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetMobInfoResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetMobInfoResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetMobInfoResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobFailureList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mcapi.SetMobInfoResponseBody.MobFailure.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetMobInfoResponseBody.MobFailure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetMobInfoResponseBody.MobFailure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    failedColumnsList: jspb.Message.toObjectList(msg.getFailedColumnsList(),
    proto.mcapi.ColumnInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetMobInfoResponseBody.MobFailure}
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetMobInfoResponseBody.MobFailure;
  return proto.mcapi.SetMobInfoResponseBody.MobFailure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetMobInfoResponseBody.MobFailure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetMobInfoResponseBody.MobFailure}
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    case 2:
      var value = new proto.mcapi.ColumnInfo;
      reader.readMessage(value,proto.mcapi.ColumnInfo.deserializeBinaryFromReader);
      msg.addFailedColumns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetMobInfoResponseBody.MobFailure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetMobInfoResponseBody.MobFailure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFailedColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mcapi.ColumnInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string mob_id = 1;
 * @return {string}
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetMobInfoResponseBody.MobFailure} returns this
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ColumnInfo failed_columns = 2;
 * @return {!Array<!proto.mcapi.ColumnInfo>}
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.prototype.getFailedColumnsList = function() {
  return /** @type{!Array<!proto.mcapi.ColumnInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mcapi.ColumnInfo, 2));
};


/**
 * @param {!Array<!proto.mcapi.ColumnInfo>} value
 * @return {!proto.mcapi.SetMobInfoResponseBody.MobFailure} returns this
*/
proto.mcapi.SetMobInfoResponseBody.MobFailure.prototype.setFailedColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mcapi.ColumnInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mcapi.ColumnInfo}
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.prototype.addFailedColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mcapi.ColumnInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.SetMobInfoResponseBody.MobFailure} returns this
 */
proto.mcapi.SetMobInfoResponseBody.MobFailure.prototype.clearFailedColumnsList = function() {
  return this.setFailedColumnsList([]);
};


/**
 * repeated MobFailure mob_failure = 1;
 * @return {!Array<!proto.mcapi.SetMobInfoResponseBody.MobFailure>}
 */
proto.mcapi.SetMobInfoResponseBody.prototype.getMobFailureList = function() {
  return /** @type{!Array<!proto.mcapi.SetMobInfoResponseBody.MobFailure>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mcapi.SetMobInfoResponseBody.MobFailure, 1));
};


/**
 * @param {!Array<!proto.mcapi.SetMobInfoResponseBody.MobFailure>} value
 * @return {!proto.mcapi.SetMobInfoResponseBody} returns this
*/
proto.mcapi.SetMobInfoResponseBody.prototype.setMobFailureList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mcapi.SetMobInfoResponseBody.MobFailure=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mcapi.SetMobInfoResponseBody.MobFailure}
 */
proto.mcapi.SetMobInfoResponseBody.prototype.addMobFailure = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mcapi.SetMobInfoResponseBody.MobFailure, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.SetMobInfoResponseBody} returns this
 */
proto.mcapi.SetMobInfoResponseBody.prototype.clearMobFailureList = function() {
  return this.setMobFailureList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.OpenBinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.OpenBinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.OpenBinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.OpenBinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.OpenBinRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.OpenBinRequest}
 */
proto.mcapi.OpenBinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.OpenBinRequest;
  return proto.mcapi.OpenBinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.OpenBinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.OpenBinRequest}
 */
proto.mcapi.OpenBinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.OpenBinRequestBody;
      reader.readMessage(value,proto.mcapi.OpenBinRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.OpenBinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.OpenBinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.OpenBinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.OpenBinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.OpenBinRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.OpenBinRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.OpenBinRequest} returns this
*/
proto.mcapi.OpenBinRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.OpenBinRequest} returns this
 */
proto.mcapi.OpenBinRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.OpenBinRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OpenBinRequestBody body = 2;
 * @return {?proto.mcapi.OpenBinRequestBody}
 */
proto.mcapi.OpenBinRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.OpenBinRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.OpenBinRequestBody, 2));
};


/**
 * @param {?proto.mcapi.OpenBinRequestBody|undefined} value
 * @return {!proto.mcapi.OpenBinRequest} returns this
*/
proto.mcapi.OpenBinRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.OpenBinRequest} returns this
 */
proto.mcapi.OpenBinRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.OpenBinRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.OpenBinRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.OpenBinRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.OpenBinRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.OpenBinRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    binPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locked: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.OpenBinRequestBody}
 */
proto.mcapi.OpenBinRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.OpenBinRequestBody;
  return proto.mcapi.OpenBinRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.OpenBinRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.OpenBinRequestBody}
 */
proto.mcapi.OpenBinRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBinPath(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.OpenBinRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.OpenBinRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.OpenBinRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.OpenBinRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBinPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocked();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string bin_path = 1;
 * @return {string}
 */
proto.mcapi.OpenBinRequestBody.prototype.getBinPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.OpenBinRequestBody} returns this
 */
proto.mcapi.OpenBinRequestBody.prototype.setBinPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool locked = 2;
 * @return {boolean}
 */
proto.mcapi.OpenBinRequestBody.prototype.getLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.OpenBinRequestBody} returns this
 */
proto.mcapi.OpenBinRequestBody.prototype.setLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.OpenBinResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.OpenBinResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.OpenBinResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.OpenBinResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.OpenBinResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.OpenBinResponse}
 */
proto.mcapi.OpenBinResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.OpenBinResponse;
  return proto.mcapi.OpenBinResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.OpenBinResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.OpenBinResponse}
 */
proto.mcapi.OpenBinResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.OpenBinResponseBody;
      reader.readMessage(value,proto.mcapi.OpenBinResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.OpenBinResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.OpenBinResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.OpenBinResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.OpenBinResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.OpenBinResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.OpenBinResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.OpenBinResponse} returns this
*/
proto.mcapi.OpenBinResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.OpenBinResponse} returns this
 */
proto.mcapi.OpenBinResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.OpenBinResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OpenBinResponseBody body = 2;
 * @return {?proto.mcapi.OpenBinResponseBody}
 */
proto.mcapi.OpenBinResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.OpenBinResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.OpenBinResponseBody, 2));
};


/**
 * @param {?proto.mcapi.OpenBinResponseBody|undefined} value
 * @return {!proto.mcapi.OpenBinResponse} returns this
*/
proto.mcapi.OpenBinResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.OpenBinResponse} returns this
 */
proto.mcapi.OpenBinResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.OpenBinResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.OpenBinResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.OpenBinResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.OpenBinResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.OpenBinResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    isLockedByOther: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    lockOwner: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.OpenBinResponseBody}
 */
proto.mcapi.OpenBinResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.OpenBinResponseBody;
  return proto.mcapi.OpenBinResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.OpenBinResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.OpenBinResponseBody}
 */
proto.mcapi.OpenBinResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLockedByOther(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.OpenBinResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.OpenBinResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.OpenBinResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.OpenBinResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsLockedByOther();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLockOwner();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_locked_by_other = 1;
 * @return {boolean}
 */
proto.mcapi.OpenBinResponseBody.prototype.getIsLockedByOther = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.OpenBinResponseBody} returns this
 */
proto.mcapi.OpenBinResponseBody.prototype.setIsLockedByOther = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string lock_owner = 2;
 * @return {string}
 */
proto.mcapi.OpenBinResponseBody.prototype.getLockOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.OpenBinResponseBody} returns this
 */
proto.mcapi.OpenBinResponseBody.prototype.setLockOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateBinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateBinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateBinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateBinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.CreateBinRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateBinRequest}
 */
proto.mcapi.CreateBinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateBinRequest;
  return proto.mcapi.CreateBinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateBinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateBinRequest}
 */
proto.mcapi.CreateBinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.CreateBinRequestBody;
      reader.readMessage(value,proto.mcapi.CreateBinRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateBinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateBinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateBinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateBinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.CreateBinRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.CreateBinRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.CreateBinRequest} returns this
*/
proto.mcapi.CreateBinRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateBinRequest} returns this
 */
proto.mcapi.CreateBinRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateBinRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateBinRequestBody body = 2;
 * @return {?proto.mcapi.CreateBinRequestBody}
 */
proto.mcapi.CreateBinRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.CreateBinRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.CreateBinRequestBody, 2));
};


/**
 * @param {?proto.mcapi.CreateBinRequestBody|undefined} value
 * @return {!proto.mcapi.CreateBinRequest} returns this
*/
proto.mcapi.CreateBinRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateBinRequest} returns this
 */
proto.mcapi.CreateBinRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateBinRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateBinRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateBinRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateBinRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateBinRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    folderPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    binName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    option: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateBinRequestBody}
 */
proto.mcapi.CreateBinRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateBinRequestBody;
  return proto.mcapi.CreateBinRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateBinRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateBinRequestBody}
 */
proto.mcapi.CreateBinRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFolderPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBinName(value);
      break;
    case 3:
      var value = /** @type {!proto.mcapi.CreateBinRequestBody.OpenBinOption} */ (reader.readEnum());
      msg.setOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateBinRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateBinRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateBinRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateBinRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFolderPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBinName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOption();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mcapi.CreateBinRequestBody.OpenBinOption = {
  FLOATBIN: 0,
  LASTACTIVEBINCONTAINER: 1,
  FOLLOWBINSETTINGS: 2
};

/**
 * optional string folder_path = 1;
 * @return {string}
 */
proto.mcapi.CreateBinRequestBody.prototype.getFolderPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.CreateBinRequestBody} returns this
 */
proto.mcapi.CreateBinRequestBody.prototype.setFolderPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bin_name = 2;
 * @return {string}
 */
proto.mcapi.CreateBinRequestBody.prototype.getBinName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.CreateBinRequestBody} returns this
 */
proto.mcapi.CreateBinRequestBody.prototype.setBinName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OpenBinOption option = 3;
 * @return {!proto.mcapi.CreateBinRequestBody.OpenBinOption}
 */
proto.mcapi.CreateBinRequestBody.prototype.getOption = function() {
  return /** @type {!proto.mcapi.CreateBinRequestBody.OpenBinOption} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mcapi.CreateBinRequestBody.OpenBinOption} value
 * @return {!proto.mcapi.CreateBinRequestBody} returns this
 */
proto.mcapi.CreateBinRequestBody.prototype.setOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateBinResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateBinResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateBinResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateBinResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.CreateBinResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateBinResponse}
 */
proto.mcapi.CreateBinResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateBinResponse;
  return proto.mcapi.CreateBinResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateBinResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateBinResponse}
 */
proto.mcapi.CreateBinResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.CreateBinResponseBody;
      reader.readMessage(value,proto.mcapi.CreateBinResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateBinResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateBinResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateBinResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateBinResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.CreateBinResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.CreateBinResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.CreateBinResponse} returns this
*/
proto.mcapi.CreateBinResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateBinResponse} returns this
 */
proto.mcapi.CreateBinResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateBinResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateBinResponseBody body = 2;
 * @return {?proto.mcapi.CreateBinResponseBody}
 */
proto.mcapi.CreateBinResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.CreateBinResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.CreateBinResponseBody, 2));
};


/**
 * @param {?proto.mcapi.CreateBinResponseBody|undefined} value
 * @return {!proto.mcapi.CreateBinResponse} returns this
*/
proto.mcapi.CreateBinResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateBinResponse} returns this
 */
proto.mcapi.CreateBinResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateBinResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateBinResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateBinResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateBinResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateBinResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateBinResponseBody}
 */
proto.mcapi.CreateBinResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateBinResponseBody;
  return proto.mcapi.CreateBinResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateBinResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateBinResponseBody}
 */
proto.mcapi.CreateBinResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateBinResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateBinResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateBinResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateBinResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ConfigureSRTStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ConfigureSRTStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ConfigureSRTStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ConfigureSRTStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ConfigureSRTStreamRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ConfigureSRTStreamRequest}
 */
proto.mcapi.ConfigureSRTStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ConfigureSRTStreamRequest;
  return proto.mcapi.ConfigureSRTStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ConfigureSRTStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ConfigureSRTStreamRequest}
 */
proto.mcapi.ConfigureSRTStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ConfigureSRTStreamRequestBody;
      reader.readMessage(value,proto.mcapi.ConfigureSRTStreamRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ConfigureSRTStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ConfigureSRTStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ConfigureSRTStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ConfigureSRTStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ConfigureSRTStreamRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.ConfigureSRTStreamRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.ConfigureSRTStreamRequest} returns this
*/
proto.mcapi.ConfigureSRTStreamRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ConfigureSRTStreamRequest} returns this
 */
proto.mcapi.ConfigureSRTStreamRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ConfigureSRTStreamRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ConfigureSRTStreamRequestBody body = 2;
 * @return {?proto.mcapi.ConfigureSRTStreamRequestBody}
 */
proto.mcapi.ConfigureSRTStreamRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ConfigureSRTStreamRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ConfigureSRTStreamRequestBody, 2));
};


/**
 * @param {?proto.mcapi.ConfigureSRTStreamRequestBody|undefined} value
 * @return {!proto.mcapi.ConfigureSRTStreamRequest} returns this
*/
proto.mcapi.ConfigureSRTStreamRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ConfigureSRTStreamRequest} returns this
 */
proto.mcapi.ConfigureSRTStreamRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ConfigureSRTStreamRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ConfigureSRTStreamRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ConfigureSRTStreamRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ConfigureSRTStreamRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    port: jspb.Message.getFieldWithDefault(msg, 3, 0),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    latency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    qualityOption: jspb.Message.getFieldWithDefault(msg, 6, 0),
    modeOption: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ConfigureSRTStreamRequestBody;
  return proto.mcapi.ConfigureSRTStreamRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ConfigureSRTStreamRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLatency(value);
      break;
    case 6:
      var value = /** @type {!proto.mcapi.ConfigureSRTStreamRequestBody.QualityOption} */ (reader.readEnum());
      msg.setQualityOption(value);
      break;
    case 7:
      var value = /** @type {!proto.mcapi.ConfigureSRTStreamRequestBody.ModeOption} */ (reader.readEnum());
      msg.setModeOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ConfigureSRTStreamRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ConfigureSRTStreamRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ConfigureSRTStreamRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLatency();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getQualityOption();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getModeOption();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.QualityOption = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2
};

/**
 * @enum {number}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.ModeOption = {
  LISTENER: 0,
  CALLER: 1
};

/**
 * optional string stream_name = 1;
 * @return {string}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.getStreamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody} returns this
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.setStreamName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ip_address = 2;
 * @return {string}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody} returns this
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 port = 3;
 * @return {number}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody} returns this
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody} returns this
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 latency = 5;
 * @return {number}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.getLatency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody} returns this
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.setLatency = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional QualityOption quality_option = 6;
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody.QualityOption}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.getQualityOption = function() {
  return /** @type {!proto.mcapi.ConfigureSRTStreamRequestBody.QualityOption} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.mcapi.ConfigureSRTStreamRequestBody.QualityOption} value
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody} returns this
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.setQualityOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ModeOption mode_option = 7;
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody.ModeOption}
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.getModeOption = function() {
  return /** @type {!proto.mcapi.ConfigureSRTStreamRequestBody.ModeOption} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.mcapi.ConfigureSRTStreamRequestBody.ModeOption} value
 * @return {!proto.mcapi.ConfigureSRTStreamRequestBody} returns this
 */
proto.mcapi.ConfigureSRTStreamRequestBody.prototype.setModeOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ConfigureSRTStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ConfigureSRTStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ConfigureSRTStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ConfigureSRTStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ConfigureSRTStreamResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ConfigureSRTStreamResponse}
 */
proto.mcapi.ConfigureSRTStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ConfigureSRTStreamResponse;
  return proto.mcapi.ConfigureSRTStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ConfigureSRTStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ConfigureSRTStreamResponse}
 */
proto.mcapi.ConfigureSRTStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ConfigureSRTStreamResponseBody;
      reader.readMessage(value,proto.mcapi.ConfigureSRTStreamResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ConfigureSRTStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ConfigureSRTStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ConfigureSRTStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ConfigureSRTStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ConfigureSRTStreamResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.ConfigureSRTStreamResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.ConfigureSRTStreamResponse} returns this
*/
proto.mcapi.ConfigureSRTStreamResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ConfigureSRTStreamResponse} returns this
 */
proto.mcapi.ConfigureSRTStreamResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ConfigureSRTStreamResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ConfigureSRTStreamResponseBody body = 2;
 * @return {?proto.mcapi.ConfigureSRTStreamResponseBody}
 */
proto.mcapi.ConfigureSRTStreamResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ConfigureSRTStreamResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ConfigureSRTStreamResponseBody, 2));
};


/**
 * @param {?proto.mcapi.ConfigureSRTStreamResponseBody|undefined} value
 * @return {!proto.mcapi.ConfigureSRTStreamResponse} returns this
*/
proto.mcapi.ConfigureSRTStreamResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ConfigureSRTStreamResponse} returns this
 */
proto.mcapi.ConfigureSRTStreamResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ConfigureSRTStreamResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ConfigureSRTStreamResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ConfigureSRTStreamResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ConfigureSRTStreamResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ConfigureSRTStreamResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ConfigureSRTStreamResponseBody}
 */
proto.mcapi.ConfigureSRTStreamResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ConfigureSRTStreamResponseBody;
  return proto.mcapi.ConfigureSRTStreamResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ConfigureSRTStreamResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ConfigureSRTStreamResponseBody}
 */
proto.mcapi.ConfigureSRTStreamResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ConfigureSRTStreamResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ConfigureSRTStreamResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ConfigureSRTStreamResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ConfigureSRTStreamResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetSRTStreamSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetSRTStreamSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetSRTStreamSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetSRTStreamSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetSRTStreamSettingsRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetSRTStreamSettingsRequest}
 */
proto.mcapi.GetSRTStreamSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetSRTStreamSettingsRequest;
  return proto.mcapi.GetSRTStreamSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetSRTStreamSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetSRTStreamSettingsRequest}
 */
proto.mcapi.GetSRTStreamSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetSRTStreamSettingsRequestBody;
      reader.readMessage(value,proto.mcapi.GetSRTStreamSettingsRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetSRTStreamSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetSRTStreamSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetSRTStreamSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetSRTStreamSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetSRTStreamSettingsRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetSRTStreamSettingsRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetSRTStreamSettingsRequest} returns this
*/
proto.mcapi.GetSRTStreamSettingsRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetSRTStreamSettingsRequest} returns this
 */
proto.mcapi.GetSRTStreamSettingsRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetSRTStreamSettingsRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetSRTStreamSettingsRequestBody body = 2;
 * @return {?proto.mcapi.GetSRTStreamSettingsRequestBody}
 */
proto.mcapi.GetSRTStreamSettingsRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetSRTStreamSettingsRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetSRTStreamSettingsRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetSRTStreamSettingsRequestBody|undefined} value
 * @return {!proto.mcapi.GetSRTStreamSettingsRequest} returns this
*/
proto.mcapi.GetSRTStreamSettingsRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetSRTStreamSettingsRequest} returns this
 */
proto.mcapi.GetSRTStreamSettingsRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetSRTStreamSettingsRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetSRTStreamSettingsRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetSRTStreamSettingsRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetSRTStreamSettingsRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetSRTStreamSettingsRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetSRTStreamSettingsRequestBody}
 */
proto.mcapi.GetSRTStreamSettingsRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetSRTStreamSettingsRequestBody;
  return proto.mcapi.GetSRTStreamSettingsRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetSRTStreamSettingsRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetSRTStreamSettingsRequestBody}
 */
proto.mcapi.GetSRTStreamSettingsRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetSRTStreamSettingsRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetSRTStreamSettingsRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetSRTStreamSettingsRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetSRTStreamSettingsRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetSRTStreamSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetSRTStreamSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetSRTStreamSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetSRTStreamSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetSRTStreamSettingsResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetSRTStreamSettingsResponse}
 */
proto.mcapi.GetSRTStreamSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetSRTStreamSettingsResponse;
  return proto.mcapi.GetSRTStreamSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetSRTStreamSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetSRTStreamSettingsResponse}
 */
proto.mcapi.GetSRTStreamSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetSRTStreamSettingsResponseBody;
      reader.readMessage(value,proto.mcapi.GetSRTStreamSettingsResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetSRTStreamSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetSRTStreamSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetSRTStreamSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetSRTStreamSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetSRTStreamSettingsResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetSRTStreamSettingsResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetSRTStreamSettingsResponse} returns this
*/
proto.mcapi.GetSRTStreamSettingsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetSRTStreamSettingsResponse} returns this
 */
proto.mcapi.GetSRTStreamSettingsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetSRTStreamSettingsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetSRTStreamSettingsResponseBody body = 2;
 * @return {?proto.mcapi.GetSRTStreamSettingsResponseBody}
 */
proto.mcapi.GetSRTStreamSettingsResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetSRTStreamSettingsResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetSRTStreamSettingsResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetSRTStreamSettingsResponseBody|undefined} value
 * @return {!proto.mcapi.GetSRTStreamSettingsResponse} returns this
*/
proto.mcapi.GetSRTStreamSettingsResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetSRTStreamSettingsResponse} returns this
 */
proto.mcapi.GetSRTStreamSettingsResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetSRTStreamSettingsResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetSRTStreamSettingsResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetSRTStreamSettingsResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    port: jspb.Message.getFieldWithDefault(msg, 3, 0),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    latency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    qualityOption: jspb.Message.getFieldWithDefault(msg, 6, 0),
    modeOption: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetSRTStreamSettingsResponseBody;
  return proto.mcapi.GetSRTStreamSettingsResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetSRTStreamSettingsResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLatency(value);
      break;
    case 6:
      var value = /** @type {!proto.mcapi.GetSRTStreamSettingsResponseBody.QualityOption} */ (reader.readEnum());
      msg.setQualityOption(value);
      break;
    case 7:
      var value = /** @type {!proto.mcapi.GetSRTStreamSettingsResponseBody.ModeOption} */ (reader.readEnum());
      msg.setModeOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetSRTStreamSettingsResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetSRTStreamSettingsResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLatency();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getQualityOption();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getModeOption();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.QualityOption = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2
};

/**
 * @enum {number}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.ModeOption = {
  LISTENER: 0,
  CALLER: 1
};

/**
 * optional string stream_name = 1;
 * @return {string}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.getStreamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody} returns this
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.setStreamName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ip_address = 2;
 * @return {string}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody} returns this
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 port = 3;
 * @return {number}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody} returns this
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody} returns this
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 latency = 5;
 * @return {number}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.getLatency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody} returns this
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.setLatency = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional QualityOption quality_option = 6;
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody.QualityOption}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.getQualityOption = function() {
  return /** @type {!proto.mcapi.GetSRTStreamSettingsResponseBody.QualityOption} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.mcapi.GetSRTStreamSettingsResponseBody.QualityOption} value
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody} returns this
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.setQualityOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ModeOption mode_option = 7;
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody.ModeOption}
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.getModeOption = function() {
  return /** @type {!proto.mcapi.GetSRTStreamSettingsResponseBody.ModeOption} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.mcapi.GetSRTStreamSettingsResponseBody.ModeOption} value
 * @return {!proto.mcapi.GetSRTStreamSettingsResponseBody} returns this
 */
proto.mcapi.GetSRTStreamSettingsResponseBody.prototype.setModeOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetOTSSessionStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetOTSSessionStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetOTSSessionStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOTSSessionStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetOTSSessionStatusRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetOTSSessionStatusRequest}
 */
proto.mcapi.GetOTSSessionStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetOTSSessionStatusRequest;
  return proto.mcapi.GetOTSSessionStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetOTSSessionStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetOTSSessionStatusRequest}
 */
proto.mcapi.GetOTSSessionStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetOTSSessionStatusRequestBody;
      reader.readMessage(value,proto.mcapi.GetOTSSessionStatusRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetOTSSessionStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetOTSSessionStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetOTSSessionStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOTSSessionStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetOTSSessionStatusRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetOTSSessionStatusRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetOTSSessionStatusRequest} returns this
*/
proto.mcapi.GetOTSSessionStatusRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOTSSessionStatusRequest} returns this
 */
proto.mcapi.GetOTSSessionStatusRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOTSSessionStatusRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetOTSSessionStatusRequestBody body = 2;
 * @return {?proto.mcapi.GetOTSSessionStatusRequestBody}
 */
proto.mcapi.GetOTSSessionStatusRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetOTSSessionStatusRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetOTSSessionStatusRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetOTSSessionStatusRequestBody|undefined} value
 * @return {!proto.mcapi.GetOTSSessionStatusRequest} returns this
*/
proto.mcapi.GetOTSSessionStatusRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOTSSessionStatusRequest} returns this
 */
proto.mcapi.GetOTSSessionStatusRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOTSSessionStatusRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetOTSSessionStatusRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetOTSSessionStatusRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetOTSSessionStatusRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOTSSessionStatusRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetOTSSessionStatusRequestBody}
 */
proto.mcapi.GetOTSSessionStatusRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetOTSSessionStatusRequestBody;
  return proto.mcapi.GetOTSSessionStatusRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetOTSSessionStatusRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetOTSSessionStatusRequestBody}
 */
proto.mcapi.GetOTSSessionStatusRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetOTSSessionStatusRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetOTSSessionStatusRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetOTSSessionStatusRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOTSSessionStatusRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetOTSSessionStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetOTSSessionStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetOTSSessionStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOTSSessionStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetOTSSessionStatusResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetOTSSessionStatusResponse}
 */
proto.mcapi.GetOTSSessionStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetOTSSessionStatusResponse;
  return proto.mcapi.GetOTSSessionStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetOTSSessionStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetOTSSessionStatusResponse}
 */
proto.mcapi.GetOTSSessionStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetOTSSessionStatusResponseBody;
      reader.readMessage(value,proto.mcapi.GetOTSSessionStatusResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetOTSSessionStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetOTSSessionStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetOTSSessionStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOTSSessionStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetOTSSessionStatusResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetOTSSessionStatusResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponse} returns this
*/
proto.mcapi.GetOTSSessionStatusResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOTSSessionStatusResponse} returns this
 */
proto.mcapi.GetOTSSessionStatusResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOTSSessionStatusResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetOTSSessionStatusResponseBody body = 2;
 * @return {?proto.mcapi.GetOTSSessionStatusResponseBody}
 */
proto.mcapi.GetOTSSessionStatusResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetOTSSessionStatusResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetOTSSessionStatusResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetOTSSessionStatusResponseBody|undefined} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponse} returns this
*/
proto.mcapi.GetOTSSessionStatusResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOTSSessionStatusResponse} returns this
 */
proto.mcapi.GetOTSSessionStatusResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOTSSessionStatusResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetOTSSessionStatusResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetOTSSessionStatusResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOTSSessionStatusResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    streamName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 6, ""),
    port: jspb.Message.getFieldWithDefault(msg, 7, 0),
    password: jspb.Message.getFieldWithDefault(msg, 8, ""),
    latency: jspb.Message.getFieldWithDefault(msg, 9, 0),
    qualityOption: jspb.Message.getFieldWithDefault(msg, 10, 0),
    modeOption: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetOTSSessionStatusResponseBody;
  return proto.mcapi.GetOTSSessionStatusResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetOTSSessionStatusResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreationDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLatency(value);
      break;
    case 10:
      var value = /** @type {!proto.mcapi.GetOTSSessionStatusResponseBody.QualityOption} */ (reader.readEnum());
      msg.setQualityOption(value);
      break;
    case 11:
      var value = /** @type {!proto.mcapi.GetOTSSessionStatusResponseBody.ModeOption} */ (reader.readEnum());
      msg.setModeOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetOTSSessionStatusResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetOTSSessionStatusResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOTSSessionStatusResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreationDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStreamName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLatency();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getQualityOption();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getModeOption();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.QualityOption = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2
};

/**
 * @enum {number}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.ModeOption = {
  LISTENER: 0,
  CALLER: 1
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string creation_date = 3;
 * @return {string}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getCreationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tenant_id = 4;
 * @return {string}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string stream_name = 5;
 * @return {string}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getStreamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setStreamName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ip_address = 6;
 * @return {string}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 port = 7;
 * @return {number}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string password = 8;
 * @return {string}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 latency = 9;
 * @return {number}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getLatency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setLatency = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional QualityOption quality_option = 10;
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody.QualityOption}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getQualityOption = function() {
  return /** @type {!proto.mcapi.GetOTSSessionStatusResponseBody.QualityOption} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.mcapi.GetOTSSessionStatusResponseBody.QualityOption} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setQualityOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional ModeOption mode_option = 11;
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody.ModeOption}
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.getModeOption = function() {
  return /** @type {!proto.mcapi.GetOTSSessionStatusResponseBody.ModeOption} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.mcapi.GetOTSSessionStatusResponseBody.ModeOption} value
 * @return {!proto.mcapi.GetOTSSessionStatusResponseBody} returns this
 */
proto.mcapi.GetOTSSessionStatusResponseBody.prototype.setModeOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetOTSSessionStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetOTSSessionStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetOTSSessionStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetOTSSessionStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.SetOTSSessionStatusRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetOTSSessionStatusRequest}
 */
proto.mcapi.SetOTSSessionStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetOTSSessionStatusRequest;
  return proto.mcapi.SetOTSSessionStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetOTSSessionStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetOTSSessionStatusRequest}
 */
proto.mcapi.SetOTSSessionStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.SetOTSSessionStatusRequestBody;
      reader.readMessage(value,proto.mcapi.SetOTSSessionStatusRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetOTSSessionStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetOTSSessionStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetOTSSessionStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetOTSSessionStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.SetOTSSessionStatusRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.SetOTSSessionStatusRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequest} returns this
*/
proto.mcapi.SetOTSSessionStatusRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetOTSSessionStatusRequest} returns this
 */
proto.mcapi.SetOTSSessionStatusRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetOTSSessionStatusRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetOTSSessionStatusRequestBody body = 2;
 * @return {?proto.mcapi.SetOTSSessionStatusRequestBody}
 */
proto.mcapi.SetOTSSessionStatusRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.SetOTSSessionStatusRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.SetOTSSessionStatusRequestBody, 2));
};


/**
 * @param {?proto.mcapi.SetOTSSessionStatusRequestBody|undefined} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequest} returns this
*/
proto.mcapi.SetOTSSessionStatusRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetOTSSessionStatusRequest} returns this
 */
proto.mcapi.SetOTSSessionStatusRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetOTSSessionStatusRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetOTSSessionStatusRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetOTSSessionStatusRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetOTSSessionStatusRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    streamName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    port: jspb.Message.getFieldWithDefault(msg, 6, 0),
    password: jspb.Message.getFieldWithDefault(msg, 7, ""),
    latency: jspb.Message.getFieldWithDefault(msg, 8, 0),
    qualityOption: jspb.Message.getFieldWithDefault(msg, 9, 0),
    modeOption: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetOTSSessionStatusRequestBody;
  return proto.mcapi.SetOTSSessionStatusRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetOTSSessionStatusRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLatency(value);
      break;
    case 9:
      var value = /** @type {!proto.mcapi.SetOTSSessionStatusRequestBody.QualityOption} */ (reader.readEnum());
      msg.setQualityOption(value);
      break;
    case 10:
      var value = /** @type {!proto.mcapi.SetOTSSessionStatusRequestBody.ModeOption} */ (reader.readEnum());
      msg.setModeOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetOTSSessionStatusRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetOTSSessionStatusRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetOTSSessionStatusRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStreamName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLatency();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getQualityOption();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getModeOption();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.QualityOption = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2
};

/**
 * @enum {number}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.ModeOption = {
  LISTENER: 0,
  CALLER: 1
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string stream_name = 4;
 * @return {string}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getStreamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setStreamName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ip_address = 5;
 * @return {string}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 port = 6;
 * @return {number}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string password = 7;
 * @return {string}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 latency = 8;
 * @return {number}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getLatency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setLatency = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional QualityOption quality_option = 9;
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody.QualityOption}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getQualityOption = function() {
  return /** @type {!proto.mcapi.SetOTSSessionStatusRequestBody.QualityOption} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.mcapi.SetOTSSessionStatusRequestBody.QualityOption} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setQualityOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional ModeOption mode_option = 10;
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody.ModeOption}
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.getModeOption = function() {
  return /** @type {!proto.mcapi.SetOTSSessionStatusRequestBody.ModeOption} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.mcapi.SetOTSSessionStatusRequestBody.ModeOption} value
 * @return {!proto.mcapi.SetOTSSessionStatusRequestBody} returns this
 */
proto.mcapi.SetOTSSessionStatusRequestBody.prototype.setModeOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetOTSSessionStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetOTSSessionStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetOTSSessionStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetOTSSessionStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.SetOTSSessionStatusResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetOTSSessionStatusResponse}
 */
proto.mcapi.SetOTSSessionStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetOTSSessionStatusResponse;
  return proto.mcapi.SetOTSSessionStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetOTSSessionStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetOTSSessionStatusResponse}
 */
proto.mcapi.SetOTSSessionStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.SetOTSSessionStatusResponseBody;
      reader.readMessage(value,proto.mcapi.SetOTSSessionStatusResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetOTSSessionStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetOTSSessionStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetOTSSessionStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetOTSSessionStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.SetOTSSessionStatusResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.SetOTSSessionStatusResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.SetOTSSessionStatusResponse} returns this
*/
proto.mcapi.SetOTSSessionStatusResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetOTSSessionStatusResponse} returns this
 */
proto.mcapi.SetOTSSessionStatusResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetOTSSessionStatusResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetOTSSessionStatusResponseBody body = 2;
 * @return {?proto.mcapi.SetOTSSessionStatusResponseBody}
 */
proto.mcapi.SetOTSSessionStatusResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.SetOTSSessionStatusResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.SetOTSSessionStatusResponseBody, 2));
};


/**
 * @param {?proto.mcapi.SetOTSSessionStatusResponseBody|undefined} value
 * @return {!proto.mcapi.SetOTSSessionStatusResponse} returns this
*/
proto.mcapi.SetOTSSessionStatusResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetOTSSessionStatusResponse} returns this
 */
proto.mcapi.SetOTSSessionStatusResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetOTSSessionStatusResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetOTSSessionStatusResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetOTSSessionStatusResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetOTSSessionStatusResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetOTSSessionStatusResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetOTSSessionStatusResponseBody}
 */
proto.mcapi.SetOTSSessionStatusResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetOTSSessionStatusResponseBody;
  return proto.mcapi.SetOTSSessionStatusResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetOTSSessionStatusResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetOTSSessionStatusResponseBody}
 */
proto.mcapi.SetOTSSessionStatusResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetOTSSessionStatusResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetOTSSessionStatusResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetOTSSessionStatusResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetOTSSessionStatusResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMediaVolumeListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMediaVolumeListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMediaVolumeListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetMediaVolumeListRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMediaVolumeListRequest}
 */
proto.mcapi.GetMediaVolumeListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMediaVolumeListRequest;
  return proto.mcapi.GetMediaVolumeListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMediaVolumeListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMediaVolumeListRequest}
 */
proto.mcapi.GetMediaVolumeListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetMediaVolumeListRequestBody;
      reader.readMessage(value,proto.mcapi.GetMediaVolumeListRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMediaVolumeListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMediaVolumeListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMediaVolumeListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetMediaVolumeListRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetMediaVolumeListRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetMediaVolumeListRequest} returns this
*/
proto.mcapi.GetMediaVolumeListRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMediaVolumeListRequest} returns this
 */
proto.mcapi.GetMediaVolumeListRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMediaVolumeListRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetMediaVolumeListRequestBody body = 2;
 * @return {?proto.mcapi.GetMediaVolumeListRequestBody}
 */
proto.mcapi.GetMediaVolumeListRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetMediaVolumeListRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetMediaVolumeListRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetMediaVolumeListRequestBody|undefined} value
 * @return {!proto.mcapi.GetMediaVolumeListRequest} returns this
*/
proto.mcapi.GetMediaVolumeListRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMediaVolumeListRequest} returns this
 */
proto.mcapi.GetMediaVolumeListRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMediaVolumeListRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMediaVolumeListRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMediaVolumeListRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMediaVolumeListRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMediaVolumeListRequestBody}
 */
proto.mcapi.GetMediaVolumeListRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMediaVolumeListRequestBody;
  return proto.mcapi.GetMediaVolumeListRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMediaVolumeListRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMediaVolumeListRequestBody}
 */
proto.mcapi.GetMediaVolumeListRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMediaVolumeListRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMediaVolumeListRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMediaVolumeListRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMediaVolumeListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMediaVolumeListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMediaVolumeListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetMediaVolumeListResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMediaVolumeListResponse}
 */
proto.mcapi.GetMediaVolumeListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMediaVolumeListResponse;
  return proto.mcapi.GetMediaVolumeListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMediaVolumeListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMediaVolumeListResponse}
 */
proto.mcapi.GetMediaVolumeListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetMediaVolumeListResponseBody;
      reader.readMessage(value,proto.mcapi.GetMediaVolumeListResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMediaVolumeListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMediaVolumeListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMediaVolumeListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetMediaVolumeListResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetMediaVolumeListResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetMediaVolumeListResponse} returns this
*/
proto.mcapi.GetMediaVolumeListResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMediaVolumeListResponse} returns this
 */
proto.mcapi.GetMediaVolumeListResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMediaVolumeListResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetMediaVolumeListResponseBody body = 2;
 * @return {?proto.mcapi.GetMediaVolumeListResponseBody}
 */
proto.mcapi.GetMediaVolumeListResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetMediaVolumeListResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetMediaVolumeListResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetMediaVolumeListResponseBody|undefined} value
 * @return {!proto.mcapi.GetMediaVolumeListResponse} returns this
*/
proto.mcapi.GetMediaVolumeListResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMediaVolumeListResponse} returns this
 */
proto.mcapi.GetMediaVolumeListResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMediaVolumeListResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.GetMediaVolumeListResponseBody.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMediaVolumeListResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMediaVolumeListResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMediaVolumeListResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody}
 */
proto.mcapi.GetMediaVolumeListResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMediaVolumeListResponseBody;
  return proto.mcapi.GetMediaVolumeListResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMediaVolumeListResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody}
 */
proto.mcapi.GetMediaVolumeListResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume;
      reader.readMessage(value,proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMediaVolumeListResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMediaVolumeListResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMediaVolumeListResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isShared: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    freeSpace: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume}
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume;
  return proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume}
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsShared(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFreeSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsShared();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getFreeSpace();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume} returns this
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_shared = 2;
 * @return {boolean}
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.prototype.getIsShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume} returns this
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.prototype.setIsShared = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional uint64 free_space = 3;
 * @return {number}
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.prototype.getFreeSpace = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume} returns this
 */
proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume.prototype.setFreeSpace = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated MediaVolume volumes = 1;
 * @return {!Array<!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume>}
 */
proto.mcapi.GetMediaVolumeListResponseBody.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume, 1));
};


/**
 * @param {!Array<!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume>} value
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody} returns this
*/
proto.mcapi.GetMediaVolumeListResponseBody.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume}
 */
proto.mcapi.GetMediaVolumeListResponseBody.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mcapi.GetMediaVolumeListResponseBody.MediaVolume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.GetMediaVolumeListResponseBody} returns this
 */
proto.mcapi.GetMediaVolumeListResponseBody.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetOpenProjectInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetOpenProjectInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetOpenProjectInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOpenProjectInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetOpenProjectInfoRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetOpenProjectInfoRequest}
 */
proto.mcapi.GetOpenProjectInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetOpenProjectInfoRequest;
  return proto.mcapi.GetOpenProjectInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetOpenProjectInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetOpenProjectInfoRequest}
 */
proto.mcapi.GetOpenProjectInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetOpenProjectInfoRequestBody;
      reader.readMessage(value,proto.mcapi.GetOpenProjectInfoRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetOpenProjectInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetOpenProjectInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetOpenProjectInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOpenProjectInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetOpenProjectInfoRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetOpenProjectInfoRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetOpenProjectInfoRequest} returns this
*/
proto.mcapi.GetOpenProjectInfoRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOpenProjectInfoRequest} returns this
 */
proto.mcapi.GetOpenProjectInfoRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOpenProjectInfoRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetOpenProjectInfoRequestBody body = 2;
 * @return {?proto.mcapi.GetOpenProjectInfoRequestBody}
 */
proto.mcapi.GetOpenProjectInfoRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetOpenProjectInfoRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetOpenProjectInfoRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetOpenProjectInfoRequestBody|undefined} value
 * @return {!proto.mcapi.GetOpenProjectInfoRequest} returns this
*/
proto.mcapi.GetOpenProjectInfoRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOpenProjectInfoRequest} returns this
 */
proto.mcapi.GetOpenProjectInfoRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOpenProjectInfoRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetOpenProjectInfoRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetOpenProjectInfoRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetOpenProjectInfoRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOpenProjectInfoRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetOpenProjectInfoRequestBody}
 */
proto.mcapi.GetOpenProjectInfoRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetOpenProjectInfoRequestBody;
  return proto.mcapi.GetOpenProjectInfoRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetOpenProjectInfoRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetOpenProjectInfoRequestBody}
 */
proto.mcapi.GetOpenProjectInfoRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetOpenProjectInfoRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetOpenProjectInfoRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetOpenProjectInfoRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOpenProjectInfoRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetOpenProjectInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetOpenProjectInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetOpenProjectInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOpenProjectInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetOpenProjectInfoResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetOpenProjectInfoResponse}
 */
proto.mcapi.GetOpenProjectInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetOpenProjectInfoResponse;
  return proto.mcapi.GetOpenProjectInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetOpenProjectInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetOpenProjectInfoResponse}
 */
proto.mcapi.GetOpenProjectInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetOpenProjectInfoResponseBody;
      reader.readMessage(value,proto.mcapi.GetOpenProjectInfoResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetOpenProjectInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetOpenProjectInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetOpenProjectInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOpenProjectInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetOpenProjectInfoResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetOpenProjectInfoResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponse} returns this
*/
proto.mcapi.GetOpenProjectInfoResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOpenProjectInfoResponse} returns this
 */
proto.mcapi.GetOpenProjectInfoResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOpenProjectInfoResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetOpenProjectInfoResponseBody body = 2;
 * @return {?proto.mcapi.GetOpenProjectInfoResponseBody}
 */
proto.mcapi.GetOpenProjectInfoResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetOpenProjectInfoResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetOpenProjectInfoResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetOpenProjectInfoResponseBody|undefined} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponse} returns this
*/
proto.mcapi.GetOpenProjectInfoResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOpenProjectInfoResponse} returns this
 */
proto.mcapi.GetOpenProjectInfoResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOpenProjectInfoResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetOpenProjectInfoResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetOpenProjectInfoResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOpenProjectInfoResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frameRate: (f = msg.getFrameRate()) && proto.mcapi.FrameRate.toObject(includeInstance, f),
    colorSpace: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rasterWidth: jspb.Message.getFieldWithDefault(msg, 5, 0),
    rasterHeight: jspb.Message.getFieldWithDefault(msg, 6, 0),
    stereoscopic: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dropFrame: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    creationDate: (f = msg.getCreationDate()) && proto.mcapi.Timestamp.toObject(includeInstance, f),
    modifyDate: (f = msg.getModifyDate()) && proto.mcapi.Timestamp.toObject(includeInstance, f),
    film: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    filmPerf: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetOpenProjectInfoResponseBody;
  return proto.mcapi.GetOpenProjectInfoResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetOpenProjectInfoResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectType(value);
      break;
    case 3:
      var value = new proto.mcapi.FrameRate;
      reader.readMessage(value,proto.mcapi.FrameRate.deserializeBinaryFromReader);
      msg.setFrameRate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorSpace(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRasterWidth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRasterHeight(value);
      break;
    case 7:
      var value = /** @type {!proto.mcapi.Stereoscopic} */ (reader.readEnum());
      msg.setStereoscopic(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDropFrame(value);
      break;
    case 9:
      var value = new proto.mcapi.Timestamp;
      reader.readMessage(value,proto.mcapi.Timestamp.deserializeBinaryFromReader);
      msg.setCreationDate(value);
      break;
    case 10:
      var value = new proto.mcapi.Timestamp;
      reader.readMessage(value,proto.mcapi.Timestamp.deserializeBinaryFromReader);
      msg.setModifyDate(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFilm(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilmPerf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetOpenProjectInfoResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetOpenProjectInfoResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetOpenProjectInfoResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrameRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mcapi.FrameRate.serializeBinaryToWriter
    );
  }
  f = message.getColorSpace();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRasterWidth();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRasterHeight();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getStereoscopic();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDropFrame();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCreationDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.mcapi.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifyDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.mcapi.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFilm();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getFilmPerf();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_type = 2;
 * @return {string}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getProjectType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setProjectType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FrameRate frame_rate = 3;
 * @return {?proto.mcapi.FrameRate}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getFrameRate = function() {
  return /** @type{?proto.mcapi.FrameRate} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.FrameRate, 3));
};


/**
 * @param {?proto.mcapi.FrameRate|undefined} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
*/
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setFrameRate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.clearFrameRate = function() {
  return this.setFrameRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.hasFrameRate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string color_space = 4;
 * @return {string}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getColorSpace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setColorSpace = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 raster_width = 5;
 * @return {number}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getRasterWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setRasterWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 raster_height = 6;
 * @return {number}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getRasterHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setRasterHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Stereoscopic stereoscopic = 7;
 * @return {!proto.mcapi.Stereoscopic}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getStereoscopic = function() {
  return /** @type {!proto.mcapi.Stereoscopic} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.mcapi.Stereoscopic} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setStereoscopic = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool drop_frame = 8;
 * @return {boolean}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getDropFrame = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setDropFrame = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional Timestamp creation_date = 9;
 * @return {?proto.mcapi.Timestamp}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getCreationDate = function() {
  return /** @type{?proto.mcapi.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.Timestamp, 9));
};


/**
 * @param {?proto.mcapi.Timestamp|undefined} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
*/
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setCreationDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.clearCreationDate = function() {
  return this.setCreationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.hasCreationDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Timestamp modify_date = 10;
 * @return {?proto.mcapi.Timestamp}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getModifyDate = function() {
  return /** @type{?proto.mcapi.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.Timestamp, 10));
};


/**
 * @param {?proto.mcapi.Timestamp|undefined} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
*/
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setModifyDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.clearModifyDate = function() {
  return this.setModifyDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.hasModifyDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool film = 11;
 * @return {boolean}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getFilm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setFilm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string film_perf = 12;
 * @return {string}
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.getFilmPerf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetOpenProjectInfoResponseBody} returns this
 */
proto.mcapi.GetOpenProjectInfoResponseBody.prototype.setFilmPerf = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetCustomProjectDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetCustomProjectDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetCustomProjectDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetCustomProjectDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetCustomProjectDataRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetCustomProjectDataRequest}
 */
proto.mcapi.GetCustomProjectDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetCustomProjectDataRequest;
  return proto.mcapi.GetCustomProjectDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetCustomProjectDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetCustomProjectDataRequest}
 */
proto.mcapi.GetCustomProjectDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetCustomProjectDataRequestBody;
      reader.readMessage(value,proto.mcapi.GetCustomProjectDataRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetCustomProjectDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetCustomProjectDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetCustomProjectDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetCustomProjectDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetCustomProjectDataRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetCustomProjectDataRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetCustomProjectDataRequest} returns this
*/
proto.mcapi.GetCustomProjectDataRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetCustomProjectDataRequest} returns this
 */
proto.mcapi.GetCustomProjectDataRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetCustomProjectDataRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetCustomProjectDataRequestBody body = 2;
 * @return {?proto.mcapi.GetCustomProjectDataRequestBody}
 */
proto.mcapi.GetCustomProjectDataRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetCustomProjectDataRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetCustomProjectDataRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetCustomProjectDataRequestBody|undefined} value
 * @return {!proto.mcapi.GetCustomProjectDataRequest} returns this
*/
proto.mcapi.GetCustomProjectDataRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetCustomProjectDataRequest} returns this
 */
proto.mcapi.GetCustomProjectDataRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetCustomProjectDataRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetCustomProjectDataRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetCustomProjectDataRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetCustomProjectDataRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetCustomProjectDataRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetCustomProjectDataRequestBody}
 */
proto.mcapi.GetCustomProjectDataRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetCustomProjectDataRequestBody;
  return proto.mcapi.GetCustomProjectDataRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetCustomProjectDataRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetCustomProjectDataRequestBody}
 */
proto.mcapi.GetCustomProjectDataRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetCustomProjectDataRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetCustomProjectDataRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetCustomProjectDataRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetCustomProjectDataRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string data_id = 1;
 * @return {string}
 */
proto.mcapi.GetCustomProjectDataRequestBody.prototype.getDataId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetCustomProjectDataRequestBody} returns this
 */
proto.mcapi.GetCustomProjectDataRequestBody.prototype.setDataId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data_key = 2;
 * @return {string}
 */
proto.mcapi.GetCustomProjectDataRequestBody.prototype.getDataKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetCustomProjectDataRequestBody} returns this
 */
proto.mcapi.GetCustomProjectDataRequestBody.prototype.setDataKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetCustomProjectDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetCustomProjectDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetCustomProjectDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetCustomProjectDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetCustomProjectDataResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetCustomProjectDataResponse}
 */
proto.mcapi.GetCustomProjectDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetCustomProjectDataResponse;
  return proto.mcapi.GetCustomProjectDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetCustomProjectDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetCustomProjectDataResponse}
 */
proto.mcapi.GetCustomProjectDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetCustomProjectDataResponseBody;
      reader.readMessage(value,proto.mcapi.GetCustomProjectDataResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetCustomProjectDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetCustomProjectDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetCustomProjectDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetCustomProjectDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetCustomProjectDataResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetCustomProjectDataResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetCustomProjectDataResponse} returns this
*/
proto.mcapi.GetCustomProjectDataResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetCustomProjectDataResponse} returns this
 */
proto.mcapi.GetCustomProjectDataResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetCustomProjectDataResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetCustomProjectDataResponseBody body = 2;
 * @return {?proto.mcapi.GetCustomProjectDataResponseBody}
 */
proto.mcapi.GetCustomProjectDataResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetCustomProjectDataResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetCustomProjectDataResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetCustomProjectDataResponseBody|undefined} value
 * @return {!proto.mcapi.GetCustomProjectDataResponse} returns this
*/
proto.mcapi.GetCustomProjectDataResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetCustomProjectDataResponse} returns this
 */
proto.mcapi.GetCustomProjectDataResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetCustomProjectDataResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetCustomProjectDataResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetCustomProjectDataResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetCustomProjectDataResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetCustomProjectDataResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataValue: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetCustomProjectDataResponseBody}
 */
proto.mcapi.GetCustomProjectDataResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetCustomProjectDataResponseBody;
  return proto.mcapi.GetCustomProjectDataResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetCustomProjectDataResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetCustomProjectDataResponseBody}
 */
proto.mcapi.GetCustomProjectDataResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetCustomProjectDataResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetCustomProjectDataResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetCustomProjectDataResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetCustomProjectDataResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data_value = 1;
 * @return {string}
 */
proto.mcapi.GetCustomProjectDataResponseBody.prototype.getDataValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetCustomProjectDataResponseBody} returns this
 */
proto.mcapi.GetCustomProjectDataResponseBody.prototype.setDataValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetCustomProjectDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetCustomProjectDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetCustomProjectDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetCustomProjectDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.SetCustomProjectDataRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetCustomProjectDataRequest}
 */
proto.mcapi.SetCustomProjectDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetCustomProjectDataRequest;
  return proto.mcapi.SetCustomProjectDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetCustomProjectDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetCustomProjectDataRequest}
 */
proto.mcapi.SetCustomProjectDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.SetCustomProjectDataRequestBody;
      reader.readMessage(value,proto.mcapi.SetCustomProjectDataRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetCustomProjectDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetCustomProjectDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetCustomProjectDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetCustomProjectDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.SetCustomProjectDataRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.SetCustomProjectDataRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.SetCustomProjectDataRequest} returns this
*/
proto.mcapi.SetCustomProjectDataRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetCustomProjectDataRequest} returns this
 */
proto.mcapi.SetCustomProjectDataRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetCustomProjectDataRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetCustomProjectDataRequestBody body = 2;
 * @return {?proto.mcapi.SetCustomProjectDataRequestBody}
 */
proto.mcapi.SetCustomProjectDataRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.SetCustomProjectDataRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.SetCustomProjectDataRequestBody, 2));
};


/**
 * @param {?proto.mcapi.SetCustomProjectDataRequestBody|undefined} value
 * @return {!proto.mcapi.SetCustomProjectDataRequest} returns this
*/
proto.mcapi.SetCustomProjectDataRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetCustomProjectDataRequest} returns this
 */
proto.mcapi.SetCustomProjectDataRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetCustomProjectDataRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetCustomProjectDataRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetCustomProjectDataRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetCustomProjectDataRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetCustomProjectDataRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataValue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetCustomProjectDataRequestBody}
 */
proto.mcapi.SetCustomProjectDataRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetCustomProjectDataRequestBody;
  return proto.mcapi.SetCustomProjectDataRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetCustomProjectDataRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetCustomProjectDataRequestBody}
 */
proto.mcapi.SetCustomProjectDataRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetCustomProjectDataRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetCustomProjectDataRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetCustomProjectDataRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetCustomProjectDataRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string data_id = 1;
 * @return {string}
 */
proto.mcapi.SetCustomProjectDataRequestBody.prototype.getDataId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetCustomProjectDataRequestBody} returns this
 */
proto.mcapi.SetCustomProjectDataRequestBody.prototype.setDataId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data_key = 2;
 * @return {string}
 */
proto.mcapi.SetCustomProjectDataRequestBody.prototype.getDataKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetCustomProjectDataRequestBody} returns this
 */
proto.mcapi.SetCustomProjectDataRequestBody.prototype.setDataKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string data_value = 3;
 * @return {string}
 */
proto.mcapi.SetCustomProjectDataRequestBody.prototype.getDataValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.SetCustomProjectDataRequestBody} returns this
 */
proto.mcapi.SetCustomProjectDataRequestBody.prototype.setDataValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetCustomProjectDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetCustomProjectDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetCustomProjectDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetCustomProjectDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.SetCustomProjectDataResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetCustomProjectDataResponse}
 */
proto.mcapi.SetCustomProjectDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetCustomProjectDataResponse;
  return proto.mcapi.SetCustomProjectDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetCustomProjectDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetCustomProjectDataResponse}
 */
proto.mcapi.SetCustomProjectDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.SetCustomProjectDataResponseBody;
      reader.readMessage(value,proto.mcapi.SetCustomProjectDataResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetCustomProjectDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetCustomProjectDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetCustomProjectDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetCustomProjectDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.SetCustomProjectDataResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.SetCustomProjectDataResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.SetCustomProjectDataResponse} returns this
*/
proto.mcapi.SetCustomProjectDataResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetCustomProjectDataResponse} returns this
 */
proto.mcapi.SetCustomProjectDataResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetCustomProjectDataResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetCustomProjectDataResponseBody body = 2;
 * @return {?proto.mcapi.SetCustomProjectDataResponseBody}
 */
proto.mcapi.SetCustomProjectDataResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.SetCustomProjectDataResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.SetCustomProjectDataResponseBody, 2));
};


/**
 * @param {?proto.mcapi.SetCustomProjectDataResponseBody|undefined} value
 * @return {!proto.mcapi.SetCustomProjectDataResponse} returns this
*/
proto.mcapi.SetCustomProjectDataResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.SetCustomProjectDataResponse} returns this
 */
proto.mcapi.SetCustomProjectDataResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.SetCustomProjectDataResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.SetCustomProjectDataResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.SetCustomProjectDataResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.SetCustomProjectDataResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetCustomProjectDataResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.SetCustomProjectDataResponseBody}
 */
proto.mcapi.SetCustomProjectDataResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.SetCustomProjectDataResponseBody;
  return proto.mcapi.SetCustomProjectDataResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.SetCustomProjectDataResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.SetCustomProjectDataResponseBody}
 */
proto.mcapi.SetCustomProjectDataResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.SetCustomProjectDataResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.SetCustomProjectDataResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.SetCustomProjectDataResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.SetCustomProjectDataResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.FrameRate.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.FrameRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.FrameRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.FrameRate.toObject = function(includeInstance, msg) {
  var f, obj = {
    num: jspb.Message.getFieldWithDefault(msg, 1, 0),
    den: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.FrameRate}
 */
proto.mcapi.FrameRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.FrameRate;
  return proto.mcapi.FrameRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.FrameRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.FrameRate}
 */
proto.mcapi.FrameRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.FrameRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.FrameRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.FrameRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.FrameRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNum();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDen();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 num = 1;
 * @return {number}
 */
proto.mcapi.FrameRate.prototype.getNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.FrameRate} returns this
 */
proto.mcapi.FrameRate.prototype.setNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 den = 2;
 * @return {number}
 */
proto.mcapi.FrameRate.prototype.getDen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.FrameRate} returns this
 */
proto.mcapi.FrameRate.prototype.setDen = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ScanAvidMediaFilesFolderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ScanAvidMediaFilesFolderRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderRequest}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ScanAvidMediaFilesFolderRequest;
  return proto.mcapi.ScanAvidMediaFilesFolderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderRequest}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ScanAvidMediaFilesFolderRequestBody;
      reader.readMessage(value,proto.mcapi.ScanAvidMediaFilesFolderRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ScanAvidMediaFilesFolderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ScanAvidMediaFilesFolderRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderRequest} returns this
*/
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderRequest} returns this
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScanAvidMediaFilesFolderRequestBody body = 2;
 * @return {?proto.mcapi.ScanAvidMediaFilesFolderRequestBody}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ScanAvidMediaFilesFolderRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ScanAvidMediaFilesFolderRequestBody, 2));
};


/**
 * @param {?proto.mcapi.ScanAvidMediaFilesFolderRequestBody|undefined} value
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderRequest} returns this
*/
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderRequest} returns this
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ScanAvidMediaFilesFolderRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ScanAvidMediaFilesFolderRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    destinationPath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderRequestBody}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ScanAvidMediaFilesFolderRequestBody;
  return proto.mcapi.ScanAvidMediaFilesFolderRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderRequestBody}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ScanAvidMediaFilesFolderRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ScanAvidMediaFilesFolderRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestinationPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string destination_path = 1;
 * @return {string}
 */
proto.mcapi.ScanAvidMediaFilesFolderRequestBody.prototype.getDestinationPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderRequestBody} returns this
 */
proto.mcapi.ScanAvidMediaFilesFolderRequestBody.prototype.setDestinationPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ScanAvidMediaFilesFolderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ScanAvidMediaFilesFolderResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderResponse}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ScanAvidMediaFilesFolderResponse;
  return proto.mcapi.ScanAvidMediaFilesFolderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderResponse}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ScanAvidMediaFilesFolderResponseBody;
      reader.readMessage(value,proto.mcapi.ScanAvidMediaFilesFolderResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ScanAvidMediaFilesFolderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ScanAvidMediaFilesFolderResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderResponse} returns this
*/
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderResponse} returns this
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScanAvidMediaFilesFolderResponseBody body = 2;
 * @return {?proto.mcapi.ScanAvidMediaFilesFolderResponseBody}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ScanAvidMediaFilesFolderResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ScanAvidMediaFilesFolderResponseBody, 2));
};


/**
 * @param {?proto.mcapi.ScanAvidMediaFilesFolderResponseBody|undefined} value
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderResponse} returns this
*/
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderResponse} returns this
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ScanAvidMediaFilesFolderResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ScanAvidMediaFilesFolderResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderResponseBody}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ScanAvidMediaFilesFolderResponseBody;
  return proto.mcapi.ScanAvidMediaFilesFolderResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ScanAvidMediaFilesFolderResponseBody}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ScanAvidMediaFilesFolderResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ScanAvidMediaFilesFolderResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ScanAvidMediaFilesFolderResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ScanAvidMediaFilesFolderResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest;
  return proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody;
      reader.readMessage(value,proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} returns this
*/
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} returns this
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateClipsFromAvidMediaFilesFolderRequestBody body = 2;
 * @return {?proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody, 2));
};


/**
 * @param {?proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody|undefined} value
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} returns this
*/
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest} returns this
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    binPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mediaFolderPath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody;
  return proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBinPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMediaFolderPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBinPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMediaFolderPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string bin_path = 1;
 * @return {string}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.prototype.getBinPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody} returns this
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.prototype.setBinPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string media_folder_path = 2;
 * @return {string}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.prototype.getMediaFolderPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody} returns this
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderRequestBody.prototype.setMediaFolderPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse;
  return proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody;
      reader.readMessage(value,proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse} returns this
*/
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse} returns this
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateClipsFromAvidMediaFilesFolderResponseBody body = 2;
 * @return {?proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody, 2));
};


/**
 * @param {?proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody|undefined} value
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse} returns this
*/
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse} returns this
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody;
  return proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateClipsFromAvidMediaFilesFolderResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateCustomColumnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateCustomColumnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateCustomColumnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateCustomColumnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.CreateCustomColumnRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateCustomColumnRequest}
 */
proto.mcapi.CreateCustomColumnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateCustomColumnRequest;
  return proto.mcapi.CreateCustomColumnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateCustomColumnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateCustomColumnRequest}
 */
proto.mcapi.CreateCustomColumnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.CreateCustomColumnRequestBody;
      reader.readMessage(value,proto.mcapi.CreateCustomColumnRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateCustomColumnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateCustomColumnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateCustomColumnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateCustomColumnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.CreateCustomColumnRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.CreateCustomColumnRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.CreateCustomColumnRequest} returns this
*/
proto.mcapi.CreateCustomColumnRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateCustomColumnRequest} returns this
 */
proto.mcapi.CreateCustomColumnRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateCustomColumnRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateCustomColumnRequestBody body = 2;
 * @return {?proto.mcapi.CreateCustomColumnRequestBody}
 */
proto.mcapi.CreateCustomColumnRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.CreateCustomColumnRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.CreateCustomColumnRequestBody, 2));
};


/**
 * @param {?proto.mcapi.CreateCustomColumnRequestBody|undefined} value
 * @return {!proto.mcapi.CreateCustomColumnRequest} returns this
*/
proto.mcapi.CreateCustomColumnRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateCustomColumnRequest} returns this
 */
proto.mcapi.CreateCustomColumnRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateCustomColumnRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateCustomColumnRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateCustomColumnRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateCustomColumnRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    absoluteBinPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newColumnName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    afterColumnName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    columnHidden: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateCustomColumnRequestBody}
 */
proto.mcapi.CreateCustomColumnRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateCustomColumnRequestBody;
  return proto.mcapi.CreateCustomColumnRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateCustomColumnRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateCustomColumnRequestBody}
 */
proto.mcapi.CreateCustomColumnRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbsoluteBinPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewColumnName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterColumnName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setColumnHidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateCustomColumnRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateCustomColumnRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateCustomColumnRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbsoluteBinPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewColumnName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAfterColumnName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getColumnHidden();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string absolute_bin_path = 1;
 * @return {string}
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.getAbsoluteBinPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.CreateCustomColumnRequestBody} returns this
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.setAbsoluteBinPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_column_name = 2;
 * @return {string}
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.getNewColumnName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.CreateCustomColumnRequestBody} returns this
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.setNewColumnName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string after_column_name = 3;
 * @return {string}
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.getAfterColumnName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.CreateCustomColumnRequestBody} returns this
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.setAfterColumnName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool column_hidden = 4;
 * @return {boolean}
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.getColumnHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.CreateCustomColumnRequestBody} returns this
 */
proto.mcapi.CreateCustomColumnRequestBody.prototype.setColumnHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateCustomColumnResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateCustomColumnResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateCustomColumnResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateCustomColumnResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.CreateCustomColumnResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateCustomColumnResponse}
 */
proto.mcapi.CreateCustomColumnResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateCustomColumnResponse;
  return proto.mcapi.CreateCustomColumnResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateCustomColumnResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateCustomColumnResponse}
 */
proto.mcapi.CreateCustomColumnResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.CreateCustomColumnResponseBody;
      reader.readMessage(value,proto.mcapi.CreateCustomColumnResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateCustomColumnResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateCustomColumnResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateCustomColumnResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateCustomColumnResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.CreateCustomColumnResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.CreateCustomColumnResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.CreateCustomColumnResponse} returns this
*/
proto.mcapi.CreateCustomColumnResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateCustomColumnResponse} returns this
 */
proto.mcapi.CreateCustomColumnResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateCustomColumnResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateCustomColumnResponseBody body = 2;
 * @return {?proto.mcapi.CreateCustomColumnResponseBody}
 */
proto.mcapi.CreateCustomColumnResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.CreateCustomColumnResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.CreateCustomColumnResponseBody, 2));
};


/**
 * @param {?proto.mcapi.CreateCustomColumnResponseBody|undefined} value
 * @return {!proto.mcapi.CreateCustomColumnResponse} returns this
*/
proto.mcapi.CreateCustomColumnResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.CreateCustomColumnResponse} returns this
 */
proto.mcapi.CreateCustomColumnResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.CreateCustomColumnResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CreateCustomColumnResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CreateCustomColumnResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CreateCustomColumnResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateCustomColumnResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CreateCustomColumnResponseBody}
 */
proto.mcapi.CreateCustomColumnResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CreateCustomColumnResponseBody;
  return proto.mcapi.CreateCustomColumnResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CreateCustomColumnResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CreateCustomColumnResponseBody}
 */
proto.mcapi.CreateCustomColumnResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CreateCustomColumnResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CreateCustomColumnResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CreateCustomColumnResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CreateCustomColumnResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfExportEDLSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfExportEDLSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetListOfExportEDLSettingsRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsRequest}
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfExportEDLSettingsRequest;
  return proto.mcapi.GetListOfExportEDLSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfExportEDLSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsRequest}
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetListOfExportEDLSettingsRequestBody;
      reader.readMessage(value,proto.mcapi.GetListOfExportEDLSettingsRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfExportEDLSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfExportEDLSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetListOfExportEDLSettingsRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetListOfExportEDLSettingsRequest} returns this
*/
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsRequest} returns this
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetListOfExportEDLSettingsRequestBody body = 2;
 * @return {?proto.mcapi.GetListOfExportEDLSettingsRequestBody}
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetListOfExportEDLSettingsRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetListOfExportEDLSettingsRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetListOfExportEDLSettingsRequestBody|undefined} value
 * @return {!proto.mcapi.GetListOfExportEDLSettingsRequest} returns this
*/
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsRequest} returns this
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfExportEDLSettingsRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfExportEDLSettingsRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfExportEDLSettingsRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfExportEDLSettingsRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportEDLSettingsRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsRequestBody}
 */
proto.mcapi.GetListOfExportEDLSettingsRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfExportEDLSettingsRequestBody;
  return proto.mcapi.GetListOfExportEDLSettingsRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfExportEDLSettingsRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsRequestBody}
 */
proto.mcapi.GetListOfExportEDLSettingsRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfExportEDLSettingsRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfExportEDLSettingsRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfExportEDLSettingsRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportEDLSettingsRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfExportEDLSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfExportEDLSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetListOfExportEDLSettingsResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponse}
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfExportEDLSettingsResponse;
  return proto.mcapi.GetListOfExportEDLSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfExportEDLSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponse}
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetListOfExportEDLSettingsResponseBody;
      reader.readMessage(value,proto.mcapi.GetListOfExportEDLSettingsResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfExportEDLSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfExportEDLSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetListOfExportEDLSettingsResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponse} returns this
*/
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponse} returns this
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetListOfExportEDLSettingsResponseBody body = 2;
 * @return {?proto.mcapi.GetListOfExportEDLSettingsResponseBody}
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetListOfExportEDLSettingsResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetListOfExportEDLSettingsResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetListOfExportEDLSettingsResponseBody|undefined} value
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponse} returns this
*/
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponse} returns this
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfExportEDLSettingsResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfExportEDLSettingsResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfExportEDLSettingsResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponseBody}
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfExportEDLSettingsResponseBody;
  return proto.mcapi.GetListOfExportEDLSettingsResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfExportEDLSettingsResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponseBody}
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSettingNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfExportEDLSettingsResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfExportEDLSettingsResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string setting_names = 1;
 * @return {!Array<string>}
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.prototype.getSettingNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponseBody} returns this
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.prototype.setSettingNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponseBody} returns this
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.prototype.addSettingNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.GetListOfExportEDLSettingsResponseBody} returns this
 */
proto.mcapi.GetListOfExportEDLSettingsResponseBody.prototype.clearSettingNamesList = function() {
  return this.setSettingNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ExportEDLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ExportEDLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ExportEDLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportEDLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ExportEDLRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ExportEDLRequest}
 */
proto.mcapi.ExportEDLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ExportEDLRequest;
  return proto.mcapi.ExportEDLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ExportEDLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ExportEDLRequest}
 */
proto.mcapi.ExportEDLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ExportEDLRequestBody;
      reader.readMessage(value,proto.mcapi.ExportEDLRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ExportEDLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ExportEDLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ExportEDLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportEDLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ExportEDLRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.ExportEDLRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.ExportEDLRequest} returns this
*/
proto.mcapi.ExportEDLRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ExportEDLRequest} returns this
 */
proto.mcapi.ExportEDLRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ExportEDLRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExportEDLRequestBody body = 2;
 * @return {?proto.mcapi.ExportEDLRequestBody}
 */
proto.mcapi.ExportEDLRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ExportEDLRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ExportEDLRequestBody, 2));
};


/**
 * @param {?proto.mcapi.ExportEDLRequestBody|undefined} value
 * @return {!proto.mcapi.ExportEDLRequest} returns this
*/
proto.mcapi.ExportEDLRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ExportEDLRequest} returns this
 */
proto.mcapi.ExportEDLRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ExportEDLRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ExportEDLRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ExportEDLRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ExportEDLRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportEDLRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    edlSettingsName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trackList: (f = msg.getTrackList()) && proto.mcapi.TrackList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ExportEDLRequestBody}
 */
proto.mcapi.ExportEDLRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ExportEDLRequestBody;
  return proto.mcapi.ExportEDLRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ExportEDLRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ExportEDLRequestBody}
 */
proto.mcapi.ExportEDLRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdlSettingsName(value);
      break;
    case 3:
      var value = new proto.mcapi.TrackList;
      reader.readMessage(value,proto.mcapi.TrackList.deserializeBinaryFromReader);
      msg.setTrackList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ExportEDLRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ExportEDLRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ExportEDLRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportEDLRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEdlSettingsName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrackList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mcapi.TrackList.serializeBinaryToWriter
    );
  }
};


/**
 * optional string mob_id = 1;
 * @return {string}
 */
proto.mcapi.ExportEDLRequestBody.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ExportEDLRequestBody} returns this
 */
proto.mcapi.ExportEDLRequestBody.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string edl_settings_name = 2;
 * @return {string}
 */
proto.mcapi.ExportEDLRequestBody.prototype.getEdlSettingsName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ExportEDLRequestBody} returns this
 */
proto.mcapi.ExportEDLRequestBody.prototype.setEdlSettingsName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TrackList track_list = 3;
 * @return {?proto.mcapi.TrackList}
 */
proto.mcapi.ExportEDLRequestBody.prototype.getTrackList = function() {
  return /** @type{?proto.mcapi.TrackList} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.TrackList, 3));
};


/**
 * @param {?proto.mcapi.TrackList|undefined} value
 * @return {!proto.mcapi.ExportEDLRequestBody} returns this
*/
proto.mcapi.ExportEDLRequestBody.prototype.setTrackList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ExportEDLRequestBody} returns this
 */
proto.mcapi.ExportEDLRequestBody.prototype.clearTrackList = function() {
  return this.setTrackList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ExportEDLRequestBody.prototype.hasTrackList = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.TrackList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.TrackList.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.TrackList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.TrackList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.TrackList.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackLabelsList: jspb.Message.toObjectList(msg.getTrackLabelsList(),
    proto.mcapi.TrackLabel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.TrackList}
 */
proto.mcapi.TrackList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.TrackList;
  return proto.mcapi.TrackList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.TrackList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.TrackList}
 */
proto.mcapi.TrackList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.TrackLabel;
      reader.readMessage(value,proto.mcapi.TrackLabel.deserializeBinaryFromReader);
      msg.addTrackLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.TrackList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.TrackList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.TrackList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.TrackList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mcapi.TrackLabel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrackLabel track_labels = 1;
 * @return {!Array<!proto.mcapi.TrackLabel>}
 */
proto.mcapi.TrackList.prototype.getTrackLabelsList = function() {
  return /** @type{!Array<!proto.mcapi.TrackLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mcapi.TrackLabel, 1));
};


/**
 * @param {!Array<!proto.mcapi.TrackLabel>} value
 * @return {!proto.mcapi.TrackList} returns this
*/
proto.mcapi.TrackList.prototype.setTrackLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mcapi.TrackLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mcapi.TrackLabel}
 */
proto.mcapi.TrackList.prototype.addTrackLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mcapi.TrackLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.TrackList} returns this
 */
proto.mcapi.TrackList.prototype.clearTrackLabelsList = function() {
  return this.setTrackLabelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.TrackLabel.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.TrackLabel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.TrackLabel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.TrackLabel.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.TrackLabel}
 */
proto.mcapi.TrackLabel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.TrackLabel;
  return proto.mcapi.TrackLabel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.TrackLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.TrackLabel}
 */
proto.mcapi.TrackLabel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mcapi.TrackType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.TrackLabel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.TrackLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.TrackLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.TrackLabel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional TrackType type = 1;
 * @return {!proto.mcapi.TrackType}
 */
proto.mcapi.TrackLabel.prototype.getType = function() {
  return /** @type {!proto.mcapi.TrackType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mcapi.TrackType} value
 * @return {!proto.mcapi.TrackLabel} returns this
 */
proto.mcapi.TrackLabel.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 number = 2;
 * @return {number}
 */
proto.mcapi.TrackLabel.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.TrackLabel} returns this
 */
proto.mcapi.TrackLabel.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ExportEDLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ExportEDLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ExportEDLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportEDLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ExportEDLResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ExportEDLResponse}
 */
proto.mcapi.ExportEDLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ExportEDLResponse;
  return proto.mcapi.ExportEDLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ExportEDLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ExportEDLResponse}
 */
proto.mcapi.ExportEDLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ExportEDLResponseBody;
      reader.readMessage(value,proto.mcapi.ExportEDLResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ExportEDLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ExportEDLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ExportEDLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportEDLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ExportEDLResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.ExportEDLResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.ExportEDLResponse} returns this
*/
proto.mcapi.ExportEDLResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ExportEDLResponse} returns this
 */
proto.mcapi.ExportEDLResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ExportEDLResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExportEDLResponseBody body = 2;
 * @return {?proto.mcapi.ExportEDLResponseBody}
 */
proto.mcapi.ExportEDLResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ExportEDLResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ExportEDLResponseBody, 2));
};


/**
 * @param {?proto.mcapi.ExportEDLResponseBody|undefined} value
 * @return {!proto.mcapi.ExportEDLResponse} returns this
*/
proto.mcapi.ExportEDLResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ExportEDLResponse} returns this
 */
proto.mcapi.ExportEDLResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ExportEDLResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.ExportEDLResponseBody.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ExportEDLResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ExportEDLResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ExportEDLResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportEDLResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dialogContentsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ExportEDLResponseBody}
 */
proto.mcapi.ExportEDLResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ExportEDLResponseBody;
  return proto.mcapi.ExportEDLResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ExportEDLResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ExportEDLResponseBody}
 */
proto.mcapi.ExportEDLResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDialogContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ExportEDLResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ExportEDLResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ExportEDLResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportEDLResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDialogContentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.mcapi.ExportEDLResponseBody.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ExportEDLResponseBody} returns this
 */
proto.mcapi.ExportEDLResponseBody.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string dialog_contents = 2;
 * @return {!Array<string>}
 */
proto.mcapi.ExportEDLResponseBody.prototype.getDialogContentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mcapi.ExportEDLResponseBody} returns this
 */
proto.mcapi.ExportEDLResponseBody.prototype.setDialogContentsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mcapi.ExportEDLResponseBody} returns this
 */
proto.mcapi.ExportEDLResponseBody.prototype.addDialogContents = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.ExportEDLResponseBody} returns this
 */
proto.mcapi.ExportEDLResponseBody.prototype.clearDialogContentsList = function() {
  return this.setDialogContentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMobTrackInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMobTrackInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMobTrackInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobTrackInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetMobTrackInfoRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMobTrackInfoRequest}
 */
proto.mcapi.GetMobTrackInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMobTrackInfoRequest;
  return proto.mcapi.GetMobTrackInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMobTrackInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMobTrackInfoRequest}
 */
proto.mcapi.GetMobTrackInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetMobTrackInfoRequestBody;
      reader.readMessage(value,proto.mcapi.GetMobTrackInfoRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMobTrackInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMobTrackInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMobTrackInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobTrackInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetMobTrackInfoRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetMobTrackInfoRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetMobTrackInfoRequest} returns this
*/
proto.mcapi.GetMobTrackInfoRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMobTrackInfoRequest} returns this
 */
proto.mcapi.GetMobTrackInfoRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMobTrackInfoRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetMobTrackInfoRequestBody body = 2;
 * @return {?proto.mcapi.GetMobTrackInfoRequestBody}
 */
proto.mcapi.GetMobTrackInfoRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetMobTrackInfoRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetMobTrackInfoRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetMobTrackInfoRequestBody|undefined} value
 * @return {!proto.mcapi.GetMobTrackInfoRequest} returns this
*/
proto.mcapi.GetMobTrackInfoRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMobTrackInfoRequest} returns this
 */
proto.mcapi.GetMobTrackInfoRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMobTrackInfoRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMobTrackInfoRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMobTrackInfoRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMobTrackInfoRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobTrackInfoRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMobTrackInfoRequestBody}
 */
proto.mcapi.GetMobTrackInfoRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMobTrackInfoRequestBody;
  return proto.mcapi.GetMobTrackInfoRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMobTrackInfoRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMobTrackInfoRequestBody}
 */
proto.mcapi.GetMobTrackInfoRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMobTrackInfoRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMobTrackInfoRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMobTrackInfoRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobTrackInfoRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string mob_id = 1;
 * @return {string}
 */
proto.mcapi.GetMobTrackInfoRequestBody.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.GetMobTrackInfoRequestBody} returns this
 */
proto.mcapi.GetMobTrackInfoRequestBody.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMobTrackInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMobTrackInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMobTrackInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobTrackInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetMobTrackInfoResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMobTrackInfoResponse}
 */
proto.mcapi.GetMobTrackInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMobTrackInfoResponse;
  return proto.mcapi.GetMobTrackInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMobTrackInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMobTrackInfoResponse}
 */
proto.mcapi.GetMobTrackInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetMobTrackInfoResponseBody;
      reader.readMessage(value,proto.mcapi.GetMobTrackInfoResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMobTrackInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMobTrackInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMobTrackInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobTrackInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetMobTrackInfoResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetMobTrackInfoResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetMobTrackInfoResponse} returns this
*/
proto.mcapi.GetMobTrackInfoResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMobTrackInfoResponse} returns this
 */
proto.mcapi.GetMobTrackInfoResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMobTrackInfoResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetMobTrackInfoResponseBody body = 2;
 * @return {?proto.mcapi.GetMobTrackInfoResponseBody}
 */
proto.mcapi.GetMobTrackInfoResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetMobTrackInfoResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetMobTrackInfoResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetMobTrackInfoResponseBody|undefined} value
 * @return {!proto.mcapi.GetMobTrackInfoResponse} returns this
*/
proto.mcapi.GetMobTrackInfoResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMobTrackInfoResponse} returns this
 */
proto.mcapi.GetMobTrackInfoResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMobTrackInfoResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.TrackInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.TrackInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.TrackInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.TrackInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && proto.mcapi.TrackLabel.toObject(includeInstance, f),
    customName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    monitored: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    solo: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    mute: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    locked: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    syncLocked: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    numSegments: jspb.Message.getFieldWithDefault(msg, 10, 0),
    audioType: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.TrackInfo}
 */
proto.mcapi.TrackInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.TrackInfo;
  return proto.mcapi.TrackInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.TrackInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.TrackInfo}
 */
proto.mcapi.TrackInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.TrackLabel;
      reader.readMessage(value,proto.mcapi.TrackLabel.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMonitored(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSolo(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMute(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocked(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyncLocked(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumSegments(value);
      break;
    case 11:
      var value = /** @type {!proto.mcapi.AudioTrackType} */ (reader.readEnum());
      msg.setAudioType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.TrackInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.TrackInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.TrackInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.TrackInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.TrackLabel.serializeBinaryToWriter
    );
  }
  f = message.getCustomName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMonitored();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSolo();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getMute();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLocked();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSyncLocked();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getNumSegments();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAudioType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional TrackLabel label = 1;
 * @return {?proto.mcapi.TrackLabel}
 */
proto.mcapi.TrackInfo.prototype.getLabel = function() {
  return /** @type{?proto.mcapi.TrackLabel} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.TrackLabel, 1));
};


/**
 * @param {?proto.mcapi.TrackLabel|undefined} value
 * @return {!proto.mcapi.TrackInfo} returns this
*/
proto.mcapi.TrackInfo.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.TrackInfo.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string custom_name = 2;
 * @return {string}
 */
proto.mcapi.TrackInfo.prototype.getCustomName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setCustomName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool selected = 3;
 * @return {boolean}
 */
proto.mcapi.TrackInfo.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool monitored = 4;
 * @return {boolean}
 */
proto.mcapi.TrackInfo.prototype.getMonitored = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setMonitored = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool enabled = 5;
 * @return {boolean}
 */
proto.mcapi.TrackInfo.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool solo = 6;
 * @return {boolean}
 */
proto.mcapi.TrackInfo.prototype.getSolo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setSolo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool mute = 7;
 * @return {boolean}
 */
proto.mcapi.TrackInfo.prototype.getMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setMute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool locked = 8;
 * @return {boolean}
 */
proto.mcapi.TrackInfo.prototype.getLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool sync_locked = 9;
 * @return {boolean}
 */
proto.mcapi.TrackInfo.prototype.getSyncLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setSyncLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 num_segments = 10;
 * @return {number}
 */
proto.mcapi.TrackInfo.prototype.getNumSegments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setNumSegments = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional AudioTrackType audio_type = 11;
 * @return {!proto.mcapi.AudioTrackType}
 */
proto.mcapi.TrackInfo.prototype.getAudioType = function() {
  return /** @type {!proto.mcapi.AudioTrackType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.mcapi.AudioTrackType} value
 * @return {!proto.mcapi.TrackInfo} returns this
 */
proto.mcapi.TrackInfo.prototype.setAudioType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.TrackInfoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.TrackInfoList.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.TrackInfoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.TrackInfoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.TrackInfoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackInfoList: jspb.Message.toObjectList(msg.getTrackInfoList(),
    proto.mcapi.TrackInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.TrackInfoList}
 */
proto.mcapi.TrackInfoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.TrackInfoList;
  return proto.mcapi.TrackInfoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.TrackInfoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.TrackInfoList}
 */
proto.mcapi.TrackInfoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.TrackInfo;
      reader.readMessage(value,proto.mcapi.TrackInfo.deserializeBinaryFromReader);
      msg.addTrackInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.TrackInfoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.TrackInfoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.TrackInfoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.TrackInfoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mcapi.TrackInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrackInfo track_info = 1;
 * @return {!Array<!proto.mcapi.TrackInfo>}
 */
proto.mcapi.TrackInfoList.prototype.getTrackInfoList = function() {
  return /** @type{!Array<!proto.mcapi.TrackInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mcapi.TrackInfo, 1));
};


/**
 * @param {!Array<!proto.mcapi.TrackInfo>} value
 * @return {!proto.mcapi.TrackInfoList} returns this
*/
proto.mcapi.TrackInfoList.prototype.setTrackInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mcapi.TrackInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mcapi.TrackInfo}
 */
proto.mcapi.TrackInfoList.prototype.addTrackInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mcapi.TrackInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.TrackInfoList} returns this
 */
proto.mcapi.TrackInfoList.prototype.clearTrackInfoList = function() {
  return this.setTrackInfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetMobTrackInfoResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetMobTrackInfoResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetMobTrackInfoResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobTrackInfoResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackInfoList: (f = msg.getTrackInfoList()) && proto.mcapi.TrackInfoList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetMobTrackInfoResponseBody}
 */
proto.mcapi.GetMobTrackInfoResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetMobTrackInfoResponseBody;
  return proto.mcapi.GetMobTrackInfoResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetMobTrackInfoResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetMobTrackInfoResponseBody}
 */
proto.mcapi.GetMobTrackInfoResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.TrackInfoList;
      reader.readMessage(value,proto.mcapi.TrackInfoList.deserializeBinaryFromReader);
      msg.setTrackInfoList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetMobTrackInfoResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetMobTrackInfoResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetMobTrackInfoResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetMobTrackInfoResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackInfoList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.TrackInfoList.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrackInfoList track_info_list = 1;
 * @return {?proto.mcapi.TrackInfoList}
 */
proto.mcapi.GetMobTrackInfoResponseBody.prototype.getTrackInfoList = function() {
  return /** @type{?proto.mcapi.TrackInfoList} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.TrackInfoList, 1));
};


/**
 * @param {?proto.mcapi.TrackInfoList|undefined} value
 * @return {!proto.mcapi.GetMobTrackInfoResponseBody} returns this
*/
proto.mcapi.GetMobTrackInfoResponseBody.prototype.setTrackInfoList = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetMobTrackInfoResponseBody} returns this
 */
proto.mcapi.GetMobTrackInfoResponseBody.prototype.clearTrackInfoList = function() {
  return this.setTrackInfoList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetMobTrackInfoResponseBody.prototype.hasTrackInfoList = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.RequestHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.RequestHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.RequestHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.RequestHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.RequestHeader}
 */
proto.mcapi.RequestHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.RequestHeader;
  return proto.mcapi.RequestHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.RequestHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.RequestHeader}
 */
proto.mcapi.RequestHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.RequestHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.RequestHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.RequestHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.RequestHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_id = 1;
 * @return {string}
 */
proto.mcapi.RequestHeader.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.RequestHeader} returns this
 */
proto.mcapi.RequestHeader.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string access_token = 2;
 * @return {string}
 */
proto.mcapi.RequestHeader.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.RequestHeader} returns this
 */
proto.mcapi.RequestHeader.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ResponseHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ResponseHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ResponseHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ResponseHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    progress: jspb.Message.getFieldWithDefault(msg, 4, 0),
    error: (f = msg.getError()) && proto.mcapi.CommandError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ResponseHeader}
 */
proto.mcapi.ResponseHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ResponseHeader;
  return proto.mcapi.ResponseHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ResponseHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ResponseHeader}
 */
proto.mcapi.ResponseHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 3:
      var value = /** @type {!proto.mcapi.TaskStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 5:
      var value = new proto.mcapi.CommandError;
      reader.readMessage(value,proto.mcapi.CommandError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ResponseHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ResponseHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ResponseHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ResponseHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mcapi.CommandError.serializeBinaryToWriter
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.mcapi.ResponseHeader.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ResponseHeader} returns this
 */
proto.mcapi.ResponseHeader.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TaskStatus status = 3;
 * @return {!proto.mcapi.TaskStatus}
 */
proto.mcapi.ResponseHeader.prototype.getStatus = function() {
  return /** @type {!proto.mcapi.TaskStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mcapi.TaskStatus} value
 * @return {!proto.mcapi.ResponseHeader} returns this
 */
proto.mcapi.ResponseHeader.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 progress = 4;
 * @return {number}
 */
proto.mcapi.ResponseHeader.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.ResponseHeader} returns this
 */
proto.mcapi.ResponseHeader.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional CommandError error = 5;
 * @return {?proto.mcapi.CommandError}
 */
proto.mcapi.ResponseHeader.prototype.getError = function() {
  return /** @type{?proto.mcapi.CommandError} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.CommandError, 5));
};


/**
 * @param {?proto.mcapi.CommandError|undefined} value
 * @return {!proto.mcapi.ResponseHeader} returns this
*/
proto.mcapi.ResponseHeader.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ResponseHeader} returns this
 */
proto.mcapi.ResponseHeader.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ResponseHeader.prototype.hasError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.Timestamp.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.Timestamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.Timestamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.Timestamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    seconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nanos: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.Timestamp}
 */
proto.mcapi.Timestamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.Timestamp;
  return proto.mcapi.Timestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.Timestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.Timestamp}
 */
proto.mcapi.Timestamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNanos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.Timestamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.Timestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.Timestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.Timestamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNanos();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 seconds = 1;
 * @return {number}
 */
proto.mcapi.Timestamp.prototype.getSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.Timestamp} returns this
 */
proto.mcapi.Timestamp.prototype.setSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 nanos = 2;
 * @return {number}
 */
proto.mcapi.Timestamp.prototype.getNanos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.Timestamp} returns this
 */
proto.mcapi.Timestamp.prototype.setNanos = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.CommandError.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.CommandError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.CommandError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CommandError.toObject = function(includeInstance, msg) {
  var f, obj = {
    commandErrorType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    commandErrorMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isWarning: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.CommandError}
 */
proto.mcapi.CommandError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.CommandError;
  return proto.mcapi.CommandError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.CommandError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.CommandError}
 */
proto.mcapi.CommandError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mcapi.CommandErrorType} */ (reader.readEnum());
      msg.setCommandErrorType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommandErrorMessage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWarning(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.CommandError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.CommandError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.CommandError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.CommandError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommandErrorType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCommandErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsWarning();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional CommandErrorType command_error_type = 1;
 * @return {!proto.mcapi.CommandErrorType}
 */
proto.mcapi.CommandError.prototype.getCommandErrorType = function() {
  return /** @type {!proto.mcapi.CommandErrorType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mcapi.CommandErrorType} value
 * @return {!proto.mcapi.CommandError} returns this
 */
proto.mcapi.CommandError.prototype.setCommandErrorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string command_error_message = 2;
 * @return {string}
 */
proto.mcapi.CommandError.prototype.getCommandErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.CommandError} returns this
 */
proto.mcapi.CommandError.prototype.setCommandErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_warning = 3;
 * @return {boolean}
 */
proto.mcapi.CommandError.prototype.getIsWarning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcapi.CommandError} returns this
 */
proto.mcapi.CommandError.prototype.setIsWarning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ExportFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ExportFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ExportFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ExportFileRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ExportFileRequest}
 */
proto.mcapi.ExportFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ExportFileRequest;
  return proto.mcapi.ExportFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ExportFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ExportFileRequest}
 */
proto.mcapi.ExportFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ExportFileRequestBody;
      reader.readMessage(value,proto.mcapi.ExportFileRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ExportFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ExportFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ExportFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ExportFileRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.ExportFileRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.ExportFileRequest} returns this
*/
proto.mcapi.ExportFileRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ExportFileRequest} returns this
 */
proto.mcapi.ExportFileRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ExportFileRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExportFileRequestBody body = 2;
 * @return {?proto.mcapi.ExportFileRequestBody}
 */
proto.mcapi.ExportFileRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ExportFileRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ExportFileRequestBody, 2));
};


/**
 * @param {?proto.mcapi.ExportFileRequestBody|undefined} value
 * @return {!proto.mcapi.ExportFileRequest} returns this
*/
proto.mcapi.ExportFileRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ExportFileRequest} returns this
 */
proto.mcapi.ExportFileRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ExportFileRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.ExportFileRequestBody.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ExportFileRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ExportFileRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ExportFileRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportFileRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exportSettingsName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    destinationPath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inDirectory: jspb.Message.getFieldWithDefault(msg, 4, ""),
    optionFlagsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ExportFileRequestBody}
 */
proto.mcapi.ExportFileRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ExportFileRequestBody;
  return proto.mcapi.ExportFileRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ExportFileRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ExportFileRequestBody}
 */
proto.mcapi.ExportFileRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExportSettingsName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationPath(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInDirectory(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.mcapi.ExportFileOption>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOptionFlags(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ExportFileRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ExportFileRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ExportFileRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportFileRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExportSettingsName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDestinationPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInDirectory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOptionFlagsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
};


/**
 * optional string mob_id = 1;
 * @return {string}
 */
proto.mcapi.ExportFileRequestBody.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ExportFileRequestBody} returns this
 */
proto.mcapi.ExportFileRequestBody.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string export_settings_name = 2;
 * @return {string}
 */
proto.mcapi.ExportFileRequestBody.prototype.getExportSettingsName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ExportFileRequestBody} returns this
 */
proto.mcapi.ExportFileRequestBody.prototype.setExportSettingsName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string destination_path = 3;
 * @return {string}
 */
proto.mcapi.ExportFileRequestBody.prototype.getDestinationPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ExportFileRequestBody} returns this
 */
proto.mcapi.ExportFileRequestBody.prototype.setDestinationPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string in_directory = 4;
 * @return {string}
 */
proto.mcapi.ExportFileRequestBody.prototype.getInDirectory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ExportFileRequestBody} returns this
 */
proto.mcapi.ExportFileRequestBody.prototype.setInDirectory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ExportFileOption option_flags = 5;
 * @return {!Array<!proto.mcapi.ExportFileOption>}
 */
proto.mcapi.ExportFileRequestBody.prototype.getOptionFlagsList = function() {
  return /** @type {!Array<!proto.mcapi.ExportFileOption>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.mcapi.ExportFileOption>} value
 * @return {!proto.mcapi.ExportFileRequestBody} returns this
 */
proto.mcapi.ExportFileRequestBody.prototype.setOptionFlagsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.mcapi.ExportFileOption} value
 * @param {number=} opt_index
 * @return {!proto.mcapi.ExportFileRequestBody} returns this
 */
proto.mcapi.ExportFileRequestBody.prototype.addOptionFlags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.ExportFileRequestBody} returns this
 */
proto.mcapi.ExportFileRequestBody.prototype.clearOptionFlagsList = function() {
  return this.setOptionFlagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ExportFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ExportFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ExportFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ExportFileResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ExportFileResponse}
 */
proto.mcapi.ExportFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ExportFileResponse;
  return proto.mcapi.ExportFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ExportFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ExportFileResponse}
 */
proto.mcapi.ExportFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ExportFileResponseBody;
      reader.readMessage(value,proto.mcapi.ExportFileResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ExportFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ExportFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ExportFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ExportFileResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.ExportFileResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.ExportFileResponse} returns this
*/
proto.mcapi.ExportFileResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ExportFileResponse} returns this
 */
proto.mcapi.ExportFileResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ExportFileResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExportFileResponseBody body = 2;
 * @return {?proto.mcapi.ExportFileResponseBody}
 */
proto.mcapi.ExportFileResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ExportFileResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ExportFileResponseBody, 2));
};


/**
 * @param {?proto.mcapi.ExportFileResponseBody|undefined} value
 * @return {!proto.mcapi.ExportFileResponse} returns this
*/
proto.mcapi.ExportFileResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ExportFileResponse} returns this
 */
proto.mcapi.ExportFileResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ExportFileResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ExportFileResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ExportFileResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ExportFileResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportFileResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ExportFileResponseBody}
 */
proto.mcapi.ExportFileResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ExportFileResponseBody;
  return proto.mcapi.ExportFileResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ExportFileResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ExportFileResponseBody}
 */
proto.mcapi.ExportFileResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ExportFileResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ExportFileResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ExportFileResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ExportFileResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.mcapi.ExportFileResponseBody.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ExportFileResponseBody} returns this
 */
proto.mcapi.ExportFileResponseBody.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ImportFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ImportFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ImportFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ImportFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ImportFileRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ImportFileRequest}
 */
proto.mcapi.ImportFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ImportFileRequest;
  return proto.mcapi.ImportFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ImportFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ImportFileRequest}
 */
proto.mcapi.ImportFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ImportFileRequestBody;
      reader.readMessage(value,proto.mcapi.ImportFileRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ImportFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ImportFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ImportFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ImportFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ImportFileRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.ImportFileRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.ImportFileRequest} returns this
*/
proto.mcapi.ImportFileRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ImportFileRequest} returns this
 */
proto.mcapi.ImportFileRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ImportFileRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ImportFileRequestBody body = 2;
 * @return {?proto.mcapi.ImportFileRequestBody}
 */
proto.mcapi.ImportFileRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ImportFileRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ImportFileRequestBody, 2));
};


/**
 * @param {?proto.mcapi.ImportFileRequestBody|undefined} value
 * @return {!proto.mcapi.ImportFileRequest} returns this
*/
proto.mcapi.ImportFileRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ImportFileRequest} returns this
 */
proto.mcapi.ImportFileRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ImportFileRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.ImportFileRequestBody.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ImportFileRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ImportFileRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ImportFileRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ImportFileRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    filePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    importSettingsName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    destinationVideoDrive: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationAudioDrive: jspb.Message.getFieldWithDefault(msg, 4, ""),
    compression: jspb.Message.getFieldWithDefault(msg, 5, ""),
    videoWrapperFormat: jspb.Message.getFieldWithDefault(msg, 6, ""),
    audioWrapperFormat: jspb.Message.getFieldWithDefault(msg, 7, ""),
    destinationBin: jspb.Message.getFieldWithDefault(msg, 8, ""),
    optionFlagsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ImportFileRequestBody}
 */
proto.mcapi.ImportFileRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ImportFileRequestBody;
  return proto.mcapi.ImportFileRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ImportFileRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ImportFileRequestBody}
 */
proto.mcapi.ImportFileRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImportSettingsName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationVideoDrive(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationAudioDrive(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompression(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideoWrapperFormat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudioWrapperFormat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationBin(value);
      break;
    case 9:
      var values = /** @type {!Array<!proto.mcapi.ImportFileOption>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOptionFlags(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ImportFileRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ImportFileRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ImportFileRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ImportFileRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImportSettingsName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDestinationVideoDrive();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationAudioDrive();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompression();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVideoWrapperFormat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAudioWrapperFormat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDestinationBin();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOptionFlagsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
};


/**
 * optional string file_path = 1;
 * @return {string}
 */
proto.mcapi.ImportFileRequestBody.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string import_settings_name = 2;
 * @return {string}
 */
proto.mcapi.ImportFileRequestBody.prototype.getImportSettingsName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.setImportSettingsName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string destination_video_drive = 3;
 * @return {string}
 */
proto.mcapi.ImportFileRequestBody.prototype.getDestinationVideoDrive = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.setDestinationVideoDrive = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string destination_audio_drive = 4;
 * @return {string}
 */
proto.mcapi.ImportFileRequestBody.prototype.getDestinationAudioDrive = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.setDestinationAudioDrive = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string compression = 5;
 * @return {string}
 */
proto.mcapi.ImportFileRequestBody.prototype.getCompression = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.setCompression = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string video_wrapper_format = 6;
 * @return {string}
 */
proto.mcapi.ImportFileRequestBody.prototype.getVideoWrapperFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.setVideoWrapperFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string audio_wrapper_format = 7;
 * @return {string}
 */
proto.mcapi.ImportFileRequestBody.prototype.getAudioWrapperFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.setAudioWrapperFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string destination_bin = 8;
 * @return {string}
 */
proto.mcapi.ImportFileRequestBody.prototype.getDestinationBin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.setDestinationBin = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ImportFileOption option_flags = 9;
 * @return {!Array<!proto.mcapi.ImportFileOption>}
 */
proto.mcapi.ImportFileRequestBody.prototype.getOptionFlagsList = function() {
  return /** @type {!Array<!proto.mcapi.ImportFileOption>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.mcapi.ImportFileOption>} value
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.setOptionFlagsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.mcapi.ImportFileOption} value
 * @param {number=} opt_index
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.addOptionFlags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.ImportFileRequestBody} returns this
 */
proto.mcapi.ImportFileRequestBody.prototype.clearOptionFlagsList = function() {
  return this.setOptionFlagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ImportFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ImportFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ImportFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ImportFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.ImportFileResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ImportFileResponse}
 */
proto.mcapi.ImportFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ImportFileResponse;
  return proto.mcapi.ImportFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ImportFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ImportFileResponse}
 */
proto.mcapi.ImportFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.ImportFileResponseBody;
      reader.readMessage(value,proto.mcapi.ImportFileResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ImportFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ImportFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ImportFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ImportFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.ImportFileResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.ImportFileResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.ImportFileResponse} returns this
*/
proto.mcapi.ImportFileResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ImportFileResponse} returns this
 */
proto.mcapi.ImportFileResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ImportFileResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ImportFileResponseBody body = 2;
 * @return {?proto.mcapi.ImportFileResponseBody}
 */
proto.mcapi.ImportFileResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.ImportFileResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ImportFileResponseBody, 2));
};


/**
 * @param {?proto.mcapi.ImportFileResponseBody|undefined} value
 * @return {!proto.mcapi.ImportFileResponse} returns this
*/
proto.mcapi.ImportFileResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.ImportFileResponse} returns this
 */
proto.mcapi.ImportFileResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.ImportFileResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.ImportFileResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.ImportFileResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.ImportFileResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ImportFileResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.ImportFileResponseBody}
 */
proto.mcapi.ImportFileResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.ImportFileResponseBody;
  return proto.mcapi.ImportFileResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.ImportFileResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.ImportFileResponseBody}
 */
proto.mcapi.ImportFileResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.ImportFileResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.ImportFileResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.ImportFileResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.ImportFileResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string mob_id = 1;
 * @return {string}
 */
proto.mcapi.ImportFileResponseBody.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.ImportFileResponseBody} returns this
 */
proto.mcapi.ImportFileResponseBody.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.LoadSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.LoadSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.LoadSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.LoadSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.LoadSettingRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.LoadSettingRequest}
 */
proto.mcapi.LoadSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.LoadSettingRequest;
  return proto.mcapi.LoadSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.LoadSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.LoadSettingRequest}
 */
proto.mcapi.LoadSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.LoadSettingRequestBody;
      reader.readMessage(value,proto.mcapi.LoadSettingRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.LoadSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.LoadSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.LoadSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.LoadSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.LoadSettingRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.LoadSettingRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.LoadSettingRequest} returns this
*/
proto.mcapi.LoadSettingRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.LoadSettingRequest} returns this
 */
proto.mcapi.LoadSettingRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.LoadSettingRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LoadSettingRequestBody body = 2;
 * @return {?proto.mcapi.LoadSettingRequestBody}
 */
proto.mcapi.LoadSettingRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.LoadSettingRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.LoadSettingRequestBody, 2));
};


/**
 * @param {?proto.mcapi.LoadSettingRequestBody|undefined} value
 * @return {!proto.mcapi.LoadSettingRequest} returns this
*/
proto.mcapi.LoadSettingRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.LoadSettingRequest} returns this
 */
proto.mcapi.LoadSettingRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.LoadSettingRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.LoadSettingRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.LoadSettingRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.LoadSettingRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.LoadSettingRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    uniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    xmlSetting: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.LoadSettingRequestBody}
 */
proto.mcapi.LoadSettingRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.LoadSettingRequestBody;
  return proto.mcapi.LoadSettingRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.LoadSettingRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.LoadSettingRequestBody}
 */
proto.mcapi.LoadSettingRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setXmlSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.LoadSettingRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.LoadSettingRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.LoadSettingRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.LoadSettingRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getXmlSetting();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string unique_id = 1;
 * @return {string}
 */
proto.mcapi.LoadSettingRequestBody.prototype.getUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.LoadSettingRequestBody} returns this
 */
proto.mcapi.LoadSettingRequestBody.prototype.setUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string xml_setting = 2;
 * @return {string}
 */
proto.mcapi.LoadSettingRequestBody.prototype.getXmlSetting = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.LoadSettingRequestBody} returns this
 */
proto.mcapi.LoadSettingRequestBody.prototype.setXmlSetting = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.LoadSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.LoadSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.LoadSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.LoadSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.LoadSettingResponse}
 */
proto.mcapi.LoadSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.LoadSettingResponse;
  return proto.mcapi.LoadSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.LoadSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.LoadSettingResponse}
 */
proto.mcapi.LoadSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.LoadSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.LoadSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.LoadSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.LoadSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.LoadSettingResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.LoadSettingResponse} returns this
*/
proto.mcapi.LoadSettingResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.LoadSettingResponse} returns this
 */
proto.mcapi.LoadSettingResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.LoadSettingResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfExportSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfExportSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfExportSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetListOfExportSettingsRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfExportSettingsRequest}
 */
proto.mcapi.GetListOfExportSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfExportSettingsRequest;
  return proto.mcapi.GetListOfExportSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfExportSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfExportSettingsRequest}
 */
proto.mcapi.GetListOfExportSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetListOfExportSettingsRequestBody;
      reader.readMessage(value,proto.mcapi.GetListOfExportSettingsRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfExportSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfExportSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfExportSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetListOfExportSettingsRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetListOfExportSettingsRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetListOfExportSettingsRequest} returns this
*/
proto.mcapi.GetListOfExportSettingsRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfExportSettingsRequest} returns this
 */
proto.mcapi.GetListOfExportSettingsRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfExportSettingsRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetListOfExportSettingsRequestBody body = 2;
 * @return {?proto.mcapi.GetListOfExportSettingsRequestBody}
 */
proto.mcapi.GetListOfExportSettingsRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetListOfExportSettingsRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetListOfExportSettingsRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetListOfExportSettingsRequestBody|undefined} value
 * @return {!proto.mcapi.GetListOfExportSettingsRequest} returns this
*/
proto.mcapi.GetListOfExportSettingsRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfExportSettingsRequest} returns this
 */
proto.mcapi.GetListOfExportSettingsRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfExportSettingsRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfExportSettingsRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfExportSettingsRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfExportSettingsRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportSettingsRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfExportSettingsRequestBody}
 */
proto.mcapi.GetListOfExportSettingsRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfExportSettingsRequestBody;
  return proto.mcapi.GetListOfExportSettingsRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfExportSettingsRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfExportSettingsRequestBody}
 */
proto.mcapi.GetListOfExportSettingsRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfExportSettingsRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfExportSettingsRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfExportSettingsRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportSettingsRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfExportSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfExportSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfExportSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetListOfExportSettingsResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfExportSettingsResponse}
 */
proto.mcapi.GetListOfExportSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfExportSettingsResponse;
  return proto.mcapi.GetListOfExportSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfExportSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfExportSettingsResponse}
 */
proto.mcapi.GetListOfExportSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetListOfExportSettingsResponseBody;
      reader.readMessage(value,proto.mcapi.GetListOfExportSettingsResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfExportSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfExportSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfExportSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetListOfExportSettingsResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetListOfExportSettingsResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetListOfExportSettingsResponse} returns this
*/
proto.mcapi.GetListOfExportSettingsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfExportSettingsResponse} returns this
 */
proto.mcapi.GetListOfExportSettingsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfExportSettingsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetListOfExportSettingsResponseBody body = 2;
 * @return {?proto.mcapi.GetListOfExportSettingsResponseBody}
 */
proto.mcapi.GetListOfExportSettingsResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetListOfExportSettingsResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetListOfExportSettingsResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetListOfExportSettingsResponseBody|undefined} value
 * @return {!proto.mcapi.GetListOfExportSettingsResponse} returns this
*/
proto.mcapi.GetListOfExportSettingsResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfExportSettingsResponse} returns this
 */
proto.mcapi.GetListOfExportSettingsResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfExportSettingsResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.GetListOfExportSettingsResponseBody.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfExportSettingsResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfExportSettingsResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfExportSettingsResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportSettingsResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfExportSettingsResponseBody}
 */
proto.mcapi.GetListOfExportSettingsResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfExportSettingsResponseBody;
  return proto.mcapi.GetListOfExportSettingsResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfExportSettingsResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfExportSettingsResponseBody}
 */
proto.mcapi.GetListOfExportSettingsResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSettingNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfExportSettingsResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfExportSettingsResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfExportSettingsResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfExportSettingsResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string setting_names = 1;
 * @return {!Array<string>}
 */
proto.mcapi.GetListOfExportSettingsResponseBody.prototype.getSettingNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mcapi.GetListOfExportSettingsResponseBody} returns this
 */
proto.mcapi.GetListOfExportSettingsResponseBody.prototype.setSettingNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mcapi.GetListOfExportSettingsResponseBody} returns this
 */
proto.mcapi.GetListOfExportSettingsResponseBody.prototype.addSettingNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.GetListOfExportSettingsResponseBody} returns this
 */
proto.mcapi.GetListOfExportSettingsResponseBody.prototype.clearSettingNamesList = function() {
  return this.setSettingNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfImportSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfImportSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfImportSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfImportSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetListOfImportSettingsRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfImportSettingsRequest}
 */
proto.mcapi.GetListOfImportSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfImportSettingsRequest;
  return proto.mcapi.GetListOfImportSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfImportSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfImportSettingsRequest}
 */
proto.mcapi.GetListOfImportSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetListOfImportSettingsRequestBody;
      reader.readMessage(value,proto.mcapi.GetListOfImportSettingsRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfImportSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfImportSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfImportSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfImportSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetListOfImportSettingsRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.GetListOfImportSettingsRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.GetListOfImportSettingsRequest} returns this
*/
proto.mcapi.GetListOfImportSettingsRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfImportSettingsRequest} returns this
 */
proto.mcapi.GetListOfImportSettingsRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfImportSettingsRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetListOfImportSettingsRequestBody body = 2;
 * @return {?proto.mcapi.GetListOfImportSettingsRequestBody}
 */
proto.mcapi.GetListOfImportSettingsRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetListOfImportSettingsRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetListOfImportSettingsRequestBody, 2));
};


/**
 * @param {?proto.mcapi.GetListOfImportSettingsRequestBody|undefined} value
 * @return {!proto.mcapi.GetListOfImportSettingsRequest} returns this
*/
proto.mcapi.GetListOfImportSettingsRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfImportSettingsRequest} returns this
 */
proto.mcapi.GetListOfImportSettingsRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfImportSettingsRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfImportSettingsRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfImportSettingsRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfImportSettingsRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfImportSettingsRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfImportSettingsRequestBody}
 */
proto.mcapi.GetListOfImportSettingsRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfImportSettingsRequestBody;
  return proto.mcapi.GetListOfImportSettingsRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfImportSettingsRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfImportSettingsRequestBody}
 */
proto.mcapi.GetListOfImportSettingsRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfImportSettingsRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfImportSettingsRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfImportSettingsRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfImportSettingsRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfImportSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfImportSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfImportSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfImportSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.GetListOfImportSettingsResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfImportSettingsResponse}
 */
proto.mcapi.GetListOfImportSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfImportSettingsResponse;
  return proto.mcapi.GetListOfImportSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfImportSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfImportSettingsResponse}
 */
proto.mcapi.GetListOfImportSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.GetListOfImportSettingsResponseBody;
      reader.readMessage(value,proto.mcapi.GetListOfImportSettingsResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfImportSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfImportSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfImportSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfImportSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.GetListOfImportSettingsResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.GetListOfImportSettingsResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.GetListOfImportSettingsResponse} returns this
*/
proto.mcapi.GetListOfImportSettingsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfImportSettingsResponse} returns this
 */
proto.mcapi.GetListOfImportSettingsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfImportSettingsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetListOfImportSettingsResponseBody body = 2;
 * @return {?proto.mcapi.GetListOfImportSettingsResponseBody}
 */
proto.mcapi.GetListOfImportSettingsResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.GetListOfImportSettingsResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.GetListOfImportSettingsResponseBody, 2));
};


/**
 * @param {?proto.mcapi.GetListOfImportSettingsResponseBody|undefined} value
 * @return {!proto.mcapi.GetListOfImportSettingsResponse} returns this
*/
proto.mcapi.GetListOfImportSettingsResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.GetListOfImportSettingsResponse} returns this
 */
proto.mcapi.GetListOfImportSettingsResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.GetListOfImportSettingsResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcapi.GetListOfImportSettingsResponseBody.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.GetListOfImportSettingsResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.GetListOfImportSettingsResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.GetListOfImportSettingsResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfImportSettingsResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.GetListOfImportSettingsResponseBody}
 */
proto.mcapi.GetListOfImportSettingsResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.GetListOfImportSettingsResponseBody;
  return proto.mcapi.GetListOfImportSettingsResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.GetListOfImportSettingsResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.GetListOfImportSettingsResponseBody}
 */
proto.mcapi.GetListOfImportSettingsResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSettingNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.GetListOfImportSettingsResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.GetListOfImportSettingsResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.GetListOfImportSettingsResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.GetListOfImportSettingsResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string setting_names = 1;
 * @return {!Array<string>}
 */
proto.mcapi.GetListOfImportSettingsResponseBody.prototype.getSettingNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mcapi.GetListOfImportSettingsResponseBody} returns this
 */
proto.mcapi.GetListOfImportSettingsResponseBody.prototype.setSettingNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mcapi.GetListOfImportSettingsResponseBody} returns this
 */
proto.mcapi.GetListOfImportSettingsResponseBody.prototype.addSettingNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcapi.GetListOfImportSettingsResponseBody} returns this
 */
proto.mcapi.GetListOfImportSettingsResponseBody.prototype.clearSettingNamesList = function() {
  return this.setSettingNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.AddMarkerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.AddMarkerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.AddMarkerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.AddMarkerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.RequestHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.AddMarkerRequestBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.AddMarkerRequest}
 */
proto.mcapi.AddMarkerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.AddMarkerRequest;
  return proto.mcapi.AddMarkerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.AddMarkerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.AddMarkerRequest}
 */
proto.mcapi.AddMarkerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.RequestHeader;
      reader.readMessage(value,proto.mcapi.RequestHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.AddMarkerRequestBody;
      reader.readMessage(value,proto.mcapi.AddMarkerRequestBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.AddMarkerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.AddMarkerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.AddMarkerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.AddMarkerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.RequestHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.AddMarkerRequestBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestHeader header = 1;
 * @return {?proto.mcapi.RequestHeader}
 */
proto.mcapi.AddMarkerRequest.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.RequestHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.RequestHeader, 1));
};


/**
 * @param {?proto.mcapi.RequestHeader|undefined} value
 * @return {!proto.mcapi.AddMarkerRequest} returns this
*/
proto.mcapi.AddMarkerRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.AddMarkerRequest} returns this
 */
proto.mcapi.AddMarkerRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.AddMarkerRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddMarkerRequestBody body = 2;
 * @return {?proto.mcapi.AddMarkerRequestBody}
 */
proto.mcapi.AddMarkerRequest.prototype.getBody = function() {
  return /** @type{?proto.mcapi.AddMarkerRequestBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.AddMarkerRequestBody, 2));
};


/**
 * @param {?proto.mcapi.AddMarkerRequestBody|undefined} value
 * @return {!proto.mcapi.AddMarkerRequest} returns this
*/
proto.mcapi.AddMarkerRequest.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.AddMarkerRequest} returns this
 */
proto.mcapi.AddMarkerRequest.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.AddMarkerRequest.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.AddMarkerRequestBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.AddMarkerRequestBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.AddMarkerRequestBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.AddMarkerRequestBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trackLabel: (f = msg.getTrackLabel()) && proto.mcapi.TrackLabel.toObject(includeInstance, f),
    markerFramePosition: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timecode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    markerLength: jspb.Message.getFieldWithDefault(msg, 5, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 6, ""),
    color: jspb.Message.getFieldWithDefault(msg, 7, 0),
    name: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.AddMarkerRequestBody}
 */
proto.mcapi.AddMarkerRequestBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.AddMarkerRequestBody;
  return proto.mcapi.AddMarkerRequestBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.AddMarkerRequestBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.AddMarkerRequestBody}
 */
proto.mcapi.AddMarkerRequestBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobId(value);
      break;
    case 2:
      var value = new proto.mcapi.TrackLabel;
      reader.readMessage(value,proto.mcapi.TrackLabel.deserializeBinaryFromReader);
      msg.setTrackLabel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarkerFramePosition(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimecode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarkerLength(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 7:
      var value = /** @type {!proto.mcapi.MarkerColor} */ (reader.readEnum());
      msg.setColor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.AddMarkerRequestBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.AddMarkerRequestBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.AddMarkerRequestBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.AddMarkerRequestBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrackLabel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.TrackLabel.serializeBinaryToWriter
    );
  }
  f = message.getMarkerFramePosition();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTimecode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMarkerLength();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string mob_id = 1;
 * @return {string}
 */
proto.mcapi.AddMarkerRequestBody.prototype.getMobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.AddMarkerRequestBody} returns this
 */
proto.mcapi.AddMarkerRequestBody.prototype.setMobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TrackLabel track_label = 2;
 * @return {?proto.mcapi.TrackLabel}
 */
proto.mcapi.AddMarkerRequestBody.prototype.getTrackLabel = function() {
  return /** @type{?proto.mcapi.TrackLabel} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.TrackLabel, 2));
};


/**
 * @param {?proto.mcapi.TrackLabel|undefined} value
 * @return {!proto.mcapi.AddMarkerRequestBody} returns this
*/
proto.mcapi.AddMarkerRequestBody.prototype.setTrackLabel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.AddMarkerRequestBody} returns this
 */
proto.mcapi.AddMarkerRequestBody.prototype.clearTrackLabel = function() {
  return this.setTrackLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.AddMarkerRequestBody.prototype.hasTrackLabel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 marker_frame_position = 3;
 * @return {number}
 */
proto.mcapi.AddMarkerRequestBody.prototype.getMarkerFramePosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.AddMarkerRequestBody} returns this
 */
proto.mcapi.AddMarkerRequestBody.prototype.setMarkerFramePosition = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string timecode = 4;
 * @return {string}
 */
proto.mcapi.AddMarkerRequestBody.prototype.getTimecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.AddMarkerRequestBody} returns this
 */
proto.mcapi.AddMarkerRequestBody.prototype.setTimecode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 marker_length = 5;
 * @return {number}
 */
proto.mcapi.AddMarkerRequestBody.prototype.getMarkerLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcapi.AddMarkerRequestBody} returns this
 */
proto.mcapi.AddMarkerRequestBody.prototype.setMarkerLength = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string comment = 6;
 * @return {string}
 */
proto.mcapi.AddMarkerRequestBody.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.AddMarkerRequestBody} returns this
 */
proto.mcapi.AddMarkerRequestBody.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional MarkerColor color = 7;
 * @return {!proto.mcapi.MarkerColor}
 */
proto.mcapi.AddMarkerRequestBody.prototype.getColor = function() {
  return /** @type {!proto.mcapi.MarkerColor} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.mcapi.MarkerColor} value
 * @return {!proto.mcapi.AddMarkerRequestBody} returns this
 */
proto.mcapi.AddMarkerRequestBody.prototype.setColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.mcapi.AddMarkerRequestBody.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcapi.AddMarkerRequestBody} returns this
 */
proto.mcapi.AddMarkerRequestBody.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.AddMarkerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.AddMarkerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.AddMarkerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.AddMarkerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.mcapi.ResponseHeader.toObject(includeInstance, f),
    body: (f = msg.getBody()) && proto.mcapi.AddMarkerResponseBody.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.AddMarkerResponse}
 */
proto.mcapi.AddMarkerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.AddMarkerResponse;
  return proto.mcapi.AddMarkerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.AddMarkerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.AddMarkerResponse}
 */
proto.mcapi.AddMarkerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mcapi.ResponseHeader;
      reader.readMessage(value,proto.mcapi.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.mcapi.AddMarkerResponseBody;
      reader.readMessage(value,proto.mcapi.AddMarkerResponseBody.deserializeBinaryFromReader);
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.AddMarkerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.AddMarkerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.AddMarkerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.AddMarkerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mcapi.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcapi.AddMarkerResponseBody.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.mcapi.ResponseHeader}
 */
proto.mcapi.AddMarkerResponse.prototype.getHeader = function() {
  return /** @type{?proto.mcapi.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.ResponseHeader, 1));
};


/**
 * @param {?proto.mcapi.ResponseHeader|undefined} value
 * @return {!proto.mcapi.AddMarkerResponse} returns this
*/
proto.mcapi.AddMarkerResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.AddMarkerResponse} returns this
 */
proto.mcapi.AddMarkerResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.AddMarkerResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddMarkerResponseBody body = 2;
 * @return {?proto.mcapi.AddMarkerResponseBody}
 */
proto.mcapi.AddMarkerResponse.prototype.getBody = function() {
  return /** @type{?proto.mcapi.AddMarkerResponseBody} */ (
    jspb.Message.getWrapperField(this, proto.mcapi.AddMarkerResponseBody, 2));
};


/**
 * @param {?proto.mcapi.AddMarkerResponseBody|undefined} value
 * @return {!proto.mcapi.AddMarkerResponse} returns this
*/
proto.mcapi.AddMarkerResponse.prototype.setBody = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcapi.AddMarkerResponse} returns this
 */
proto.mcapi.AddMarkerResponse.prototype.clearBody = function() {
  return this.setBody(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcapi.AddMarkerResponse.prototype.hasBody = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcapi.AddMarkerResponseBody.prototype.toObject = function(opt_includeInstance) {
  return proto.mcapi.AddMarkerResponseBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcapi.AddMarkerResponseBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.AddMarkerResponseBody.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcapi.AddMarkerResponseBody}
 */
proto.mcapi.AddMarkerResponseBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcapi.AddMarkerResponseBody;
  return proto.mcapi.AddMarkerResponseBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcapi.AddMarkerResponseBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcapi.AddMarkerResponseBody}
 */
proto.mcapi.AddMarkerResponseBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcapi.AddMarkerResponseBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcapi.AddMarkerResponseBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcapi.AddMarkerResponseBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcapi.AddMarkerResponseBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.mcapi.Stereoscopic = {
  UNDEF: 0,
  MONOSCOPIC: 1,
  MONOLEFT: 2,
  MONORIGHT: 3,
  MONOLEADINGEYE: 4,
  HALFRESX: 5,
  HALFRESY: 6,
  FULL: 7
};

/**
 * @enum {number}
 */
proto.mcapi.TrackType = {
  TRACKTYPE_PICTURE: 0,
  TRACKTYPE_SOUND: 1,
  TRACKTYPE_TIMECODE: 2,
  TRACKTYPE_EDGECODE: 3,
  TRACKTYPE_DATA: 4
};

/**
 * @enum {number}
 */
proto.mcapi.AudioTrackType = {
  UNSPECIFIED_TRACK_FORMAT: 0,
  MONO_AUDIO_TRACK_FORMAT: 1,
  STEREO_AUDIO_TRACK_FORMAT: 2,
  AUDIO_5_1_TRACK_FORMAT: 3,
  AUDIO_7_1_TRACK_FORMAT: 4,
  WIDEST_AUDIO_TRACK_FORMAT: 5
};

/**
 * @enum {number}
 */
proto.mcapi.CommandErrorType = {
  NOERROR: 0,
  OS_WRITEPERMISSIONS: 1,
  OS_DIRECTORYPATHNOTFOUND: 2,
  OS_FILEPATHLOCATION: 3,
  OS_DUPLICATENAME: 4,
  OS_READERROR: 5,
  OS_DISKSPACE: 6,
  OS_ILLEGALCHARACTERS: 7,
  OS_CHARACTERSLIMIT: 8,
  OS_FILENOTFOUND: 9,
  OS_TIMEOUT: 10,
  OS_FILEOPENFAILED: 11,
  OS_DIRECTORYCANNOTBECREATED: 12,
  MC_BINNOTOPENED: 50,
  MC_FILEEXISTS: 51,
  MC_DOWNLOADFAILED: 52,
  MC_MOBNOTFOUND: 53,
  MC_BINCOLUMNNOTFOUND: 54,
  MC_BINELEMENTISNOTMODIFIABLE: 55,
  MC_PROJECTDATAIDISNOTVALID: 56,
  MC_PROJECTDATAKEYISNOTVALID: 57,
  MC_ACCESSTOKENISNOTVALID: 58,
  MC_EDLSETTINGSNOTFOUND: 59,
  MC_EXPORTSETTINGSNOTFOUND: 60,
  MC_IMPORTSETTINGSNOTFOUND: 61,
  MC_MISSEDPARAMETER: 62,
  MC_IMPORTFILEFAILED: 63,
  MC_EXPORTFILEFAILED: 64,
  MC_SHUTDOWN: 65,
  MC_INVALIDARGUMENT: 66,
  MC_ADDMARKERFAILED: 67,
  MC_PARSEFILEFAILED: 68,
  MC_BINNOTFOUND: 69,
  MC_INTERNALERROR: 70,
  MC_PROJECTNOTOPEN: 71,
  MC_INVALIDMOBID: 72,
  MC_UNKNOWNERROR: 1000
};

/**
 * @enum {number}
 */
proto.mcapi.TaskStatus = {
  QUEUED: 0,
  PENDING: 1,
  INPROGRESS: 2,
  COMPLETED: 3,
  FAILED: 4,
  WAITINGFORUSERINPUT: 5
};

/**
 * @enum {number}
 */
proto.mcapi.ExportFileOption = {
  EXPORT_STOPIF_MIXEDAUDIO: 0,
  EXPORT_STOPIF_OFFLINEMEDIA: 1,
  EXPORT_STOPIF_UNKNOWNFX: 2,
  EXPORT_STOPIF_LONGGOP: 3,
  EXPORT_STOPIF_NONNATIVE: 4,
  EXPORT_STOPIF_32KHZAUDIO: 5,
  EXPORT_OMF_AUDIO_TO_WAVE: 6,
  EXPORT_OMF_AUDIO_TO_AIFFC: 7,
  EXPORT_CONVER_AUDIO_TO_PCM: 8,
  EXPORT_STOPIF_COLOR_WILL_NOT_SEEN_IN_QUICK_TIME_REFERENCE: 9,
  EXPORT_STOPIF_CANNOT_EXPORT_MIXED_RESOLUTION_SEQUENCE: 10,
  EXPORT_KEEP_PARTIAL_RENDER: 11,
  EXPORT_STOPIF_SOME_AUDIO_CLIPS_DO_NOT_MATCH_CURRENT_HARDWARE_OR_SOFTWARE_SAMPLE_RATE: 12,
  EXPORT_STOPIF_CURRENT_MASTER_FADER_FORMAT_CANNOT_ACCOMODATE_SURROUND_OR_7_1_SURROUND_TRACKS: 13,
  EXPORT_STOPIF_SOME_CLIPS_DONT_HAVE_HIGH_RESOLUTION_VIDEO_AVAILABLE: 14
};

/**
 * @enum {number}
 */
proto.mcapi.ImportFileOption = {
  IMPORT_STOPIF_MEDIA_NO_IN_DB: 0
};

/**
 * @enum {number}
 */
proto.mcapi.MarkerColor = {
  RED: 0,
  GREEN: 1,
  BLUE: 2,
  CYAN: 3,
  MAGENTA: 4,
  YELLOW: 5,
  BLACK: 6,
  WHITE: 7
};

goog.object.extend(exports, proto.mcapi);
