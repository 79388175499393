// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CreateBinRequest, CreateBinRequestBody } from '../../grpc-web/MCAPI_Types_pb.js';
import { useMCAPIContext } from './MCAPIContext';

const useCreateBin = () => {
  const { client, getAccessToken } = useMCAPIContext();

  const createBin = (binName: string, folderName?: string): boolean => {
    const request = new CreateBinRequest();
    const createBinRequestBody = new CreateBinRequestBody();

    createBinRequestBody.setFolderPath(folderName || '');
    createBinRequestBody.setBinName(binName);
    createBinRequestBody.setOption(CreateBinRequestBody.OpenBinOption.LASTACTIVEBINCONTAINER);
    request.setBody(createBinRequestBody);

    client.createBin(request, { accessToken: getAccessToken() }, (err, response) => {
      if (err) {
        const errMessage = `Unexpected error: code = ${err.code}` + `, message = "${err.message}"`;
        console.log(errMessage);
        return false;
      } else {
        console.log({ response: response.toObject() });
        return true;
      }
    });
  };

  return createBin;
};

export default useCreateBin;
