import { createContext, useContext, ReactNode } from 'react';
import { useApi } from '../../hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { ONE_DAY } from '../../constants/time';

export interface UserResponse {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  organization_id: string;
  title: string;
  created_at: string;
  updated_at: string;
  status: 'Active' | 'Deactivated';
  terms_and_conditions: {
    ts_and_cs_version?: string;
    ts_and_cs_accepted_date?: string;
  };
}

interface UserCtx {
  isLoading: boolean;
  user: UserResponse | undefined;
}

const UserContext = createContext<UserCtx>({ isLoading: true, user: undefined });

interface UserContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const callApi = useApi();

  const { data, isLoading } = useQuery<UserResponse>(
    ['me'],
    async () => {
      const data = await callApi<UserResponse>({
        url: `${process.env.REACT_APP_API_URL}/v1/admin-v2/users/me`
      });
      return data;
    },
    { staleTime: ONE_DAY }
  );

  const value = { user: data, isLoading };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  return useContext(UserContext);
};
