import { Link } from 'react-router-dom';
import Button from './Button';
import CheckCircleIcon from './icons/CheckCircleIcon';

interface Props {
  text: string;
}

const Notification = ({ text }: Props) => {
  return (
    <div className="w-screen h-screen fixed top-0 left-0 right-0 bottom-0 bg-background-default  flex justify-center items-center z-50 flex-col gap-2">
      <CheckCircleIcon />
      <div>{text}</div>
      <Link to="/">
        <Button>Back to home</Button>
      </Link>
    </div>
  );
};

export default Notification;
