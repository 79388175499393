import { DragEvent, useState } from 'react';
import { BEFileItem } from '../../types/files';
import DropArea from '../common/DropArea';
import SelectedItem from '../common/SelectedItem';
import NotesList from '../notes/NotesList';
import useGetMobInfo from '../mcapi/useGetMobInfo';
import Button from '../common/Button';
import useAddMarker, { MarkerColorFLAGS } from '../mcapi/useAddMarker';
import useGetNotes from '../../hooks/useGetNotes';
import useGetMobTrackInfo from '../mcapi/useGetMobTrackInfo';
import { Listbox } from '@headlessui/react';
import { TrackList } from '../mcapi/useGetMobTrackInfo';
import ChevronDownIcon from '../common/icons/ChevronDownIcon';
import Notification from '../common/Notification';
import FileBrowser from '../pixFileBrowser/FileBrowser';
import { useScopeContext } from '../scope/ScopeContext';
import NotesSyncHeader from '../notes/NotesSyncHeader';
import McFileBrowser from '../mcFileBrowser/McFileBrowser';
import { BinItem } from '../mcapi/useGetBinItems';

const MCAPI_ASSETLIST_MIME_TYPE = 'text/x.avid.mc-api-asset-list+json';

const NotesSyncPage = () => {
  const [selectedFile, setSelectedFile] = useState<BEFileItem>();
  const [isFileBrowserOpened, setFileBrowserOpen] = useState<boolean>(false);
  const [isMcFileBrowserOpened, setMcFileBrowserOpen] = useState<boolean>(false);
  const [selectedFileFromMc, setSelectedFileFromMc] = useState<string>('');
  const [selectedTrack, setSelectedTrack] = useState<TrackList | null>(null);
  const [selectedColor, setSelectedColor] = useState<string>('Red');
  const [done, setDone] = useState<boolean>(false);

  const { scope } = useScopeContext();
  const { data: notes, isLoading: isNotesLoading } = useGetNotes({ assetId: selectedFile?.id });
  const addMarker = useAddMarker();

  const file = selectedFile ? `${selectedFile.name}.${selectedFile.extension}` : '';

  const handleFileSelect = (files: BEFileItem[]) => {
    setSelectedFile(files[0]);
    setFileBrowserOpen(false);
  };

  const handleMcItemSelect = (item: BinItem) => {
    setSelectedFileFromMc(item.id);
    setMcFileBrowserOpen(false);
  };

  const handleDrop = (event: DragEvent<HTMLElement>) => {
    setSelectedTrack(null);
    for (const item of event.dataTransfer.items) {
      if (item.type === MCAPI_ASSETLIST_MIME_TYPE) {
        const mimeData = event.dataTransfer.getData(MCAPI_ASSETLIST_MIME_TYPE);
        const dragList = JSON.parse(mimeData);

        dragList.forEach((element: { [x: string]: any }) => {
          const id = element['id'];

          setSelectedFileFromMc(id || '');
        });
      }
    }
  };

  const handleNotesSync = () => {
    if (!notes) return;

    notes.forEach((note) => {
      const marker = {
        name: note.author_display_name.replace(/[^a-zA-Z0-9 ]/g, ''),
        comment: note.text,
        markerFramePosition: note.start_frame || 0,
        markerLength: Number(Math.max(1, (note.end_frame || 0) - (note.start_frame || 0))),
        mobId: selectedFileFromMc,
        trackLabelNumber: selectedTrack?.trackNumber,
        trackType: selectedTrack?.trackType,
        color: selectedColor
      };

      addMarker(marker);
      setDone(true);
    });
  };

  const { data: trackInfoFromMc } = useGetMobTrackInfo({
    mobId: selectedFileFromMc
  });

  const { data: fileFromMc } = useGetMobInfo({
    mobId: selectedFileFromMc
  });

  const syncButtonDisabled =
    isNotesLoading || !selectedFile || !selectedFileFromMc || !selectedTrack;
  return (
    <>
      <div className="flex flex-col h-full">
        <>
          <NotesSyncHeader />

          <DropArea className="grow" innerClassName="flex flex-col gap-4" onDrop={handleDrop}>
            <SelectedItem
              label="Source (PIX):"
              value={file}
              emptyString="no file selected"
              onClick={() => setFileBrowserOpen(true)}
            />
            <SelectedItem
              label="Target (MC):"
              value={fileFromMc?.['Name'] || ''}
              emptyString="no file selected"
              onClick={() => setMcFileBrowserOpen(true)}
            />

            {/* TODO: Create a dropdown component */}
            <div className="relative">
              <div>Track:</div>
              <Listbox value={selectedTrack} onChange={setSelectedTrack}>
                <Listbox.Button className="group bg-background-light px-2 py-1 w-full outline-none border-border-light border flex justify-between items-center w-min-0 gap-3">
                  {selectedTrack?.label || <span className="opacity-50">select target track</span>}
                  <ChevronDownIcon />
                </Listbox.Button>
                <Listbox.Options className="absolute bg-background-light py-1 w-full outline-none border-border-light border shadow-lg z-50">
                  {trackInfoFromMc &&
                    trackInfoFromMc.map((track) => (
                      <Listbox.Option
                        key={track.label}
                        value={track}
                        className="hover:bg-primary-default hover:text-white px-2 outline-none cursor-pointer">
                        {track.label}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Listbox>
            </div>

            <div className="relative">
              <div>Marker color:</div>
              <Listbox value={selectedColor} onChange={setSelectedColor}>
                <Listbox.Button className="group bg-background-light px-2 py-1 w-full outline-none border-border-light border flex justify-between items-center w-min-0 gap-3">
                  {selectedColor || <span className="opacity-50">select target track</span>}
                  <ChevronDownIcon />
                </Listbox.Button>
                <Listbox.Options className="absolute bg-background-light py-1 w-full outline-none border-border-light border shadow-lg z-50">
                  {Object.keys(MarkerColorFLAGS).map((color) => (
                    <Listbox.Option
                      key={color}
                      value={color}
                      className="hover:bg-primary-default hover:text-white px-2 outline-none cursor-pointer">
                      {color}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>

            <NotesList assetId={selectedFile?.id} />

            <div className="flex justify-center mt-4">
              <Button onClick={handleNotesSync} disabled={syncButtonDisabled}>
                Start sync
              </Button>
            </div>
          </DropArea>
        </>
      </div>

      {isFileBrowserOpened && (
        <FileBrowser
          projectId={scope?.project.id}
          onSelect={(files) => handleFileSelect(files)}
          onClose={() => setFileBrowserOpen(false)}
        />
      )}

      {isMcFileBrowserOpened && (
        <McFileBrowser onSelect={handleMcItemSelect} onClose={() => setMcFileBrowserOpen(false)} />
      )}

      {done && <Notification text="Syncronization sucessful" />}
    </>
  );
};

export default NotesSyncPage;
