import { useNavigate } from 'react-router-dom';
import { useScopeContext } from '../scope/ScopeContext';
import Button from './Button';
import ProjectIcon from './icons/ProjectIcon';

const Scope = () => {
  const { scope } = useScopeContext();
  const navigate = useNavigate();
  return (
    <Button variant="text" onClick={() => navigate('/scope-selector')}>
      {scope ? (
        <div className="flex gap-1 items-center">
          {scope.organization.logo ? (
            <img className="max-h-[0.6rem]" src={scope.organization.logo} />
          ) : (
            <>{scope.organization.title}</>
          )}
          <ProjectIcon className="w-4 h-4" />
          {scope.project.title}
        </div>
      ) : (
        'no scope selected'
      )}
    </Button>
  );
};

export default Scope;
