import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Layout from './common/Layout';
import { MCAPIContextProvider } from './mcapi/MCAPIContext';
import HomePage from './pages/HomePage';
import { ScopeContextProvider } from './scope/ScopeContext';
import ScopeSelectorPage from './pages/ScopeSelectorPage';
import { UserContextProvider } from './user/UserContext';
import NotesSyncPage from './pages/NotesSyncPage';
import ProtectedFrame from './common/ProtectedFrame';
import MetadataSyncPage from './pages/MetadataSyncPage';
import CreateBinPage from './pages/CreateBinPage';

const queryClient = new QueryClient();

const App = () => {
  const onRedirectCallback = (appState: any) => {
    console.log({ appState });
  };

  return (
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        authorizationParams={{
          redirect_uri: `${window.location.origin}`,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        }}
        useRefreshTokens
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}>
        <ProtectedFrame>
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>
              <ScopeContextProvider>
                <MCAPIContextProvider>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      <Route path="" element={<HomePage />} />
                      <Route path="sync-notes" element={<NotesSyncPage />} />
                      <Route path="sync-metadata" element={<MetadataSyncPage />} />
                      <Route path="create-bin" element={<CreateBinPage />} />
                      <Route path="scope-selector" element={<ScopeSelectorPage />} />
                    </Route>
                  </Routes>
                </MCAPIContextProvider>
              </ScopeContextProvider>
            </UserContextProvider>
          </QueryClientProvider>
        </ProtectedFrame>
      </Auth0Provider>
    </BrowserRouter>
  );
};

export default App;
