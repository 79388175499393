import { BEFileItem } from '../../types/files';
import Button from '../common/Button';
import { useFileBrowserContext } from './FileBrowserContext';

interface Props {
  onSelect: (file: BEFileItem[]) => void;
}

const FileBrowserActions = ({ onSelect }: Props) => {
  const { selectedFiles } = useFileBrowserContext();
  return (
    <div className="py-4 flex justify-center">
      <Button disabled={selectedFiles.length === 0} onClick={() => onSelect(selectedFiles)}>
        Select file
      </Button>
    </div>
  );
};

export default FileBrowserActions;
