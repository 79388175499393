import useGetNotes from '../../hooks/useGetNotes';

interface Props {
  assetId: string | undefined;
}

const NotesList = ({ assetId }: Props) => {
  const { data, isLoading } = useGetNotes({ assetId });

  const isNotesEmpty = data?.length === 0;

  return (
    <div className="grow flex flex-col">
      <div>Notes to sync:</div>
      {isLoading && !!assetId && (
        <div className="border border-border-light flex grow items-center h-full justify-center max-h-full bg-neutral-800/25 animate-pulse">
          loading notes from PIX...
        </div>
      )}
      {!isLoading && !!assetId && (
        <div className="border bg-background-light border-border-light select-none overflow-y-auto grow relative">
          {isNotesEmpty && (
            <div className="px-1 flex items-center justify-center h-full">
              No notes for this file
            </div>
          )}
          {!isNotesEmpty && data && (
            <table className="w-full">
              <tr className="bg-black/25">
                <th className="p-1 text-left font-normal">Author</th>
                <th className="p-1 text-left font-normal">Note text</th>
                <th className="p-1 text-left font-normal">Frame</th>
                <th className="p-1 text-left font-normal">Created on</th>
              </tr>
              {data?.map((note) => (
                <tr key={note.id} className="odd:bg-background-default">
                  <td className="px-1">{note.author_display_name}</td>
                  <td className="px-1">{note.text}</td>
                  <td className="px-1">{note.start_frame}</td>
                  <td className="px-1">{note.created_on}</td>
                </tr>
              ))}
            </table>
          )}
        </div>
      )}
      {isLoading && !assetId && (
        <div className="border border-border-light flex grow items-center h-full justify-center max-h-full">
          <span className="opacity-50">no file selected</span>
        </div>
      )}
    </div>
  );
};

export default NotesList;
