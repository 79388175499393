import clsx from 'clsx';
import useGetBinItems, { BinItem } from '../mcapi/useGetBinItems';
import { Bin } from '../mcapi/useGetBins';
import Button from '../common/Button';
import ChevronLeftIcon from '../common/icons/ChevronLeftIcon';
import FilmIcon from '../common/icons/FilmIcon';

interface Props {
  bin: Bin;
  onChange: (item: BinItem) => void;
  onCancel: () => void;
}

const BinContentList = ({ bin, onChange, onCancel }: Props) => {
  const { data, isLoading, error } = useGetBinItems({ binPath: bin.path });

  const handleSelectItem = (item: BinItem) => {
    onChange(item);
  };

  return (
    <>
      {isLoading && (
        <div className="flex grow items-center justify-center max-h-full bg-neutral-800/25 animate-pulse">
          loading content...
        </div>
      )}
      {!isLoading && !error && (
        <div className="h-20 bg-background-light grow border border-border-light select-none overflow-y-auto max-h-full">
          <button
            className="px-1 py-2 bg-border-light/25 outline-none border-none bg-none w-full flex flex-row items-center gap-1"
            onClick={onCancel}>
            <ChevronLeftIcon />{' '}
            <span>
              Content of <span className="italic bold">{bin.name}</span>:
            </span>
          </button>
          {data.map((item) => (
            <div
              className={clsx(
                'cursor-pointer hover:text-white flex items-center gap-1 hover:bg-neutral-700/25 transition-colors p-1 w-full min-w-0'
              )}
              key={item.id}
              onClick={() => handleSelectItem(item)}>
              <div className="shrink-0">
                <FilmIcon />
              </div>
              <div className="truncate">{item.name}</div>
            </div>
          ))}
        </div>
      )}
      {!isLoading && error && (
        <div className="flex flex-col gap-2 grow items-center justify-center max-h-full bg-neutral-800/25">
          {error}
          <Button onClick={onCancel}>Back</Button>
        </div>
      )}
    </>
  );
};

export default BinContentList;
