import { useApi } from './useApi';
import { ONE_HOUR } from '../constants/time';
import { useQuery } from '@tanstack/react-query';

export interface Team {
  id: string;
  title: string;
  status: boolean;
  organization_id: string;
  created_at: string;
  updated_at: string;
  color: string;
}

export interface TeamsResponse {
  data: Team[];
}

const useGetTeams = () => {
  const callApi = useApi();

  const result = useQuery<Team[]>(
    ['teams'],
    async () => {
      const data = await callApi<TeamsResponse>({
        url: `${process.env.REACT_APP_API_URL}/v1/admin-v2/teams`
      });

      return data.data;
    },
    {
      staleTime: ONE_HOUR
    }
  );

  return result;
};

export default useGetTeams;
