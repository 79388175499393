// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from 'react';
import {
  GetListOfBinItemsRequest,
  GetListOfBinItemsRequestBody
} from '../../grpc-web/MCAPI_Types_pb.js';
import { useMCAPIContext } from './MCAPIContext';

export type BinItem = {
  id: string;
  name: string;
  selected: boolean;
};

interface Props {
  binPath: string;
}

const useGetBinItems = ({ binPath }: Props) => {
  const [data, setData] = useState<BinItem[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const { client, getAccessToken } = useMCAPIContext();

  useEffect(() => {
    if (!binPath) return;
    setLoading(true);
    setData([]);

    const request = new GetListOfBinItemsRequest();
    const body = new GetListOfBinItemsRequestBody();
    body.setBinRelativePath(binPath);

    body.setBinFlagsList([GetListOfBinItemsRequestBody.BinItemFlags.ALLTYPES]);

    body.setOnlyVisibleFlag(true);
    body.setOnlySelectedFlag(false);

    request.setBody(body);

    const stream = client.getListOfBinItems(request, { accessToken: getAccessToken() });

    const binItemsData = [];

    stream.on('data', (response) => {
      const body = response.toObject().body;
      if (body?.mobId) {
        const item = {
          id: body.mobId,
          name: body.mobName,
          selected: body.mobSelected
        };

        binItemsData.push(item);
      }
    });
    stream.on('error', (err) => {
      console.log(`Unexpected stream error: code = ${err.code}` + `, message = "${err.message}"`);
      window.mcapi.reportError(err.code, err.message);
      setError(err.message);
      setLoading(false);
    });
    stream.on('status', (status) => {
      console.log(status);
    });
    stream.on('end', () => {
      setData(binItemsData);
      setLoading(false);
      console.log(`Completed`);
    });
  }, [binPath, client, getAccessToken]);

  const value = {
    data,
    isLoading,
    error
  };

  return value;
};

export default useGetBinItems;
