import { useState } from 'react';
import BinList from '../bin/BinList';
import { Bin } from '../mcapi/useGetBins';
import BinContentList from '../bin/BinContentList';
import { BinItem } from '../mcapi/useGetBinItems';
import CancelIcon from '../common/icons/CancelIcon';

interface Props {
  onSelect: (item: BinItem) => void;
  onClose: () => void;
}

const McFileBrowser = ({ onSelect, onClose }: Props) => {
  const [selectedBin, setSelectedBin] = useState<Bin | null>(null);

  const handleSelectBin = (bin: Bin) => {
    setSelectedBin(bin);
  };

  const handleSelectBinItem = (binItem: BinItem) => {
    onSelect(binItem);
  };

  return (
    <div className="flex flex-col fixed top-8 left-0 right-0 bottom-8 bg-background-default px-2 py-4">
      <div className="flex gap-1 justify-between items-center mb-2">
        Select File
        <button onClick={onClose} className="hover:text-white transition-white outline-none">
          <CancelIcon />
        </button>
      </div>

      {!selectedBin && <BinList onChange={handleSelectBin} />}
      {selectedBin && (
        <BinContentList
          bin={selectedBin}
          onChange={handleSelectBinItem}
          onCancel={() => setSelectedBin(null)}
        />
      )}
    </div>
  );
};

export default McFileBrowser;
