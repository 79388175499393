import clsx from 'clsx';
import { ReactNode } from 'react';
import LoadingIcon from './icons/LoadingIcon';

interface Props {
  children: ReactNode;
  variant?: 'default' | 'text';
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const Button = ({ children, variant = 'default', onClick, disabled, loading }: Props) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={clsx(
        'outline-none flex gap-1 items-center cursor-default',
        { 'hover:text-primary-light': variant === 'text' },
        {
          'bg-background-light border border-border-light hover:border-primary-default hover:bg-primary-default hover:text-text px-4 py-1 disabled:border-border-light/50 disabled:text-text/25 disabled:bg-background-light disabled:cursor-not-allowed':
            variant === 'default'
        }
      )}>
      {loading && <LoadingIcon size="small" />}
      {children}
    </button>
  );
};

export default Button;
