import { FIVE_MINS } from '../constants/time';
import { GetFoldersPaginatedResponse } from '../types/files';
import { useApi } from './useApi';
import { useQuery } from '@tanstack/react-query';

interface Props {
  projectId: string | undefined;
  folderId: string | undefined;
}

// TODO: Implement pagination
const useGetFolder = ({ projectId, folderId }: Props) => {
  const callApi = useApi();
  const { data, isLoading } = useQuery<GetFoldersPaginatedResponse>(
    ['folder', projectId, folderId],
    async () => {
      const data = await callApi<GetFoldersPaginatedResponse>({
        url: `${process.env.REACT_APP_API_URL}/v1/content-v2/${projectId}/folders/${folderId}?limit=50`
      });
      return data;
    },
    { staleTime: FIVE_MINS, enabled: !!projectId && !!folderId }
  );

  const value = {
    data,
    isLoading
  };

  return value;
};

export default useGetFolder;
