import { Link } from 'react-router-dom';
import Button from '../common/Button';
import NotesIcon from '../common/icons/NotesIcon';
import ProjectIcon from '../common/icons/ProjectIcon';
import PageButton from '../common/PageButton';
import { useScopeContext } from '../scope/ScopeContext';
import SyncIcon from '../common/icons/SyncIcon';
import BinIcon from '../common/icons/BinIcon';

const HomePage = () => {
  const { scope } = useScopeContext();

  if (!scope) {
    return (
      <div className="flex flex-col items-center justify-center h-full gap-2">
        <ProjectIcon className="w-6 h-6" />
        No project selected, please select one first
        <Link to="/scope-selector">
          <Button>Select Project</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="py-2">
      <div className="flex flex-col gap-2">
        <Link to="/sync-notes">
          <PageButton
            name="Sync notes"
            description="Get notes from PIX to a selected item in Media Composer"
            icon={<NotesIcon />}
          />
        </Link>
        <Link to="/sync-metadata">
          <PageButton
            name="Sync metadata"
            description="Sync metadata from an MC item to a PIX asset"
            icon={<SyncIcon />}
          />
        </Link>
        <Link to="/create-bin">
          <PageButton
            name="Create bin"
            description="Create an empty bin from a PIX asset metadata structure"
            icon={<BinIcon />}
          />
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
