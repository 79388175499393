// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from 'react';
import {
  GetOpenProjectInfoRequest,
  GetOpenProjectInfoRequestBody
} from '../../grpc-web/MCAPI_grpc_web_pb';
import { useMCAPIContext } from './MCAPIContext';

type ProjectInfo = {
  projectPath: string;
  projectType: string;
};

const useGetProjectInfo = () => {
  const [data, setData] = useState<ProjectInfo>({
    projectPath: '',
    projectType: ''
  });
  const [isLoading, setLoading] = useState<boolean>(true);

  const { client, getAccessToken } = useMCAPIContext();

  useEffect(() => {
    setLoading(true);
    const request = new GetOpenProjectInfoRequest();
    const gbqBody = new GetOpenProjectInfoRequestBody();

    request.setBody(gbqBody);

    client.getOpenProjectInfo(request, { accessToken: getAccessToken() }, (err, response) => {
      if (err) {
        const errMessage =
          `Unexpected stream error: code = ${err.code}` + `, message = "${err.message}"`;
        console.log(errMessage);
        window.mcapi.reportError(err.code, err.message);
      } else {
        setData({
          projectPath: response.getBody().getPath(),
          projectType: response.getBody().getProjectType()
        });
        setLoading(false);
      }
    });
  }, [client, getAccessToken]);

  const value = {
    data,
    isLoading
  };

  return value;
};

export default useGetProjectInfo;
