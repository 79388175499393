import { useState, createContext, useContext, ReactNode } from 'react';
import { Organization } from '../../hooks/useGetOrganizations';
import { Project } from '../../hooks/useGetProjects';
import { Team } from '../../hooks/useGetTeams';

interface Scope {
  organization: Organization;
  team: Team;
  project: Project;
}

interface ScopeCtx {
  scope: Scope | null;
  setScope: (scope: Scope) => void;
}

const ScopeContext = createContext<ScopeCtx>({ scope: null, setScope: () => null });

interface ScopeContextProviderProps {
  children: ReactNode;
}

export const ScopeContextProvider = ({ children }: ScopeContextProviderProps) => {
  const [scope, setScope] = useState<Scope | null>(null);

  const handleSetScope = (scope: Scope) => setScope(scope);

  const value = { scope, setScope: handleSetScope };

  return <ScopeContext.Provider value={value}>{children}</ScopeContext.Provider>;
};

export const useScopeContext = () => {
  return useContext(ScopeContext);
};
