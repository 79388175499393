import { Columns } from './MetadataSyncPage';

interface Props {
  columns: Columns;
}

const MetadataColumnList = ({ columns }: Props) => {
  const isEmpty = Object.keys(columns).length === 0;
  return (
    <div className="grow max-h-full h-20 flex flex-col">
      <div>Columns to be synced:</div>
      {isEmpty && (
        <div className="flex grow items-center justify-center max-h-full border border-border-light bg-background-light">
          No columns here
        </div>
      )}
      {!isEmpty && (
        <div className="grow overflow-y-auto max-h-full bg-background-light border border-border-light">
          <table className="w-full">
            {Object.keys(columns).length > 0 &&
              Object.keys(columns).map((key) => (
                <tr key={key} className="odd:bg-background-default">
                  <td className="px-1">{key}</td>
                  <td className="px-1">{columns[key]}</td>
                </tr>
              ))}
          </table>
        </div>
      )}
    </div>
  );
};

export default MetadataColumnList;
