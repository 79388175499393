import { Outlet } from 'react-router-dom';
import { useUserContext } from '../user/UserContext';
import Footer from './Footer';
import PageLoader from './PageLoader';
import Header from './Header';

const Layout = () => {
  const { isLoading: userLoading } = useUserContext();

  const loading = userLoading;
  return (
    <div className="bg-background-default text-text h-screen w-screen p-2 text-xs flex flex-col">
      {loading && <PageLoader />}
      {!loading && (
        <>
          <Header />
          <main className="py-6 grow">
            <Outlet />
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;
