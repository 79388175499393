import { createContext, useContext, ReactNode, useState, useMemo } from 'react';
import { BEFileItem, Contents } from '../../types/files';
import useGetFolder from '../../hooks/useGetFolder';

interface Path {
  folderId: string | undefined;
  folderName: string | undefined;
}

interface FileBrowserCtx {
  content: Contents | undefined;
  isRoot: boolean;
  breadcrumb: Path;
  isLoading: boolean;
  path: Path[];
  navigateTo: (breadcrumb: Path) => void;
  navigateBack: () => void;
  selectFile: (file: BEFileItem) => void;
  selectedFiles: BEFileItem[];
}

const defaultFileBrowserContext = {
  content: { folder_count: 0, item_count: 0, folders: [], items: [] },
  isRoot: true,
  breadcrumb: { folderId: 'root', folderName: 'root' },
  isLoading: false,
  path: [{ folderId: 'root', folderName: 'root' }],
  navigateTo: () => null,
  navigateBack: () => null,
  selectFile: () => null,
  selectedFiles: []
};

const FileBrowserContext = createContext<FileBrowserCtx>(defaultFileBrowserContext);

interface FileBrowserContextProviderProps {
  projectId: string | undefined;
  children: ReactNode;
}

export const FileBrowserContextProvider = ({
  projectId,
  children
}: FileBrowserContextProviderProps) => {
  const [path, setPath] = useState<Path[]>([{ folderId: 'root', folderName: 'root' }]);
  const [selectedFiles, setSelectedFiles] = useState<BEFileItem[]>([]);
  const currentFolder = useMemo(() => path.slice(-1)[0].folderId, [path]);

  const deselectFiles = () => setSelectedFiles([]);

  const navigateTo = (breadcrumb: Path) => {
    deselectFiles();
    setPath((currentPath) => [...currentPath, breadcrumb]);
  };

  const navigateBack = () => {
    deselectFiles();
    setPath((currentPath) => currentPath.slice(0, -1));
  };

  const selectFile = (file: BEFileItem) => setSelectedFiles([file]);

  // TODO: Implement pagination
  const { data, isLoading } = useGetFolder({ projectId, folderId: currentFolder });

  const value = {
    content: data?.folders[0].contents,
    isRoot: !!data?.folders[0].is_root,
    breadcrumb: { folderName: data?.folders[0].name, folderId: data?.folders[0].id },
    isLoading,
    path,
    navigateTo,
    navigateBack,
    selectFile,
    selectedFiles
  };

  return <FileBrowserContext.Provider value={value}>{children}</FileBrowserContext.Provider>;
};

export const useFileBrowserContext = () => {
  return useContext(FileBrowserContext);
};
