import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from '../user/UserContext';
import Button from './Button';
import LogoutIcon from './icons/LogoutIcon';

const Footer = () => {
  const { logout } = useAuth0();
  const { user } = useUserContext();

  return (
    <footer className="flex justify-between">
      <div>PIX</div>
      <div className="flex gap-2">
        {user && (
          <div>
            Logged in as <span>{`${user.first_name} ${user.last_name}`}</span>
          </div>
        )}
        <Button
          variant="text"
          onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
          <LogoutIcon className="w-4 h-4" />
        </Button>
      </div>
    </footer>
  );
};

export default Footer;
