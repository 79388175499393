import clsx from 'clsx';
import ChevronRightIcon from '../common/icons/ChevronRightIcon';
import HomeIcon from '../common/icons/HomeIcon';
import { useFileBrowserContext } from './FileBrowserContext';

const FileBrowserPath = () => {
  const { path } = useFileBrowserContext();
  return (
    <div className="flex gap-1 items-center h-8 px-1 overflow-hidden bg-neutral-700/25 text-neutral-500">
      {path.map((pathItem, index) => (
        <>
          {pathItem.folderId === 'root' ? (
            <div key={pathItem.folderId} className="flex gap-1 items-center">
              <div>
                <HomeIcon />
              </div>
              Root
            </div>
          ) : (
            <>
              <div>
                <ChevronRightIcon />
              </div>
              <div
                key={pathItem.folderId}
                className={clsx('flex gap-1 items-center', {
                  'min-w-0': path.length - 1 !== index
                })}>
                <div
                  className={clsx({
                    truncate: path.length - 1 !== index,
                    'shrink-0 w-auto': path.length - 1 === index
                  })}>
                  {pathItem.folderName}
                </div>
              </div>
            </>
          )}
        </>
      ))}
    </div>
  );
};

export default FileBrowserPath;
