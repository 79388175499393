import clsx from 'clsx';
import DocumentIcon from '../common/icons/DocumentIcon';
import FolderIcon from '../common/icons/FolderIcon';
import { useFileBrowserContext } from './FileBrowserContext';
import EllipsisHorizontalIcon from '../common/icons/EllipsisHorizontalIcon';

const FileBrowserContentList = () => {
  const { content, isRoot, isLoading, navigateTo, navigateBack, selectFile, selectedFiles } =
    useFileBrowserContext();
  if (isLoading) {
    return (
      <div className="flex grow items-center justify-center max-h-full bg-neutral-800/25 animate-pulse">
        loading folder content...
      </div>
    );
  }

  if (!content || !content.folders) {
    return <>nothing to see here</>;
  }

  return (
    <div className="h-20 bg-background-light grow border border-border-light select-none overflow-y-auto max-h-full">
      {!isRoot && (
        <div
          className="cursor-pointer hover:text-white flex items-center gap-1 hover:bg-neutral-700/25 transition-colors p-1"
          onClick={navigateBack}>
          <EllipsisHorizontalIcon />
        </div>
      )}
      {content.folders.map((folder) => (
        <div
          className="cursor-pointer hover:text-white flex items-center gap-1 hover:bg-neutral-700/25 transition-colors p-1 w-full min-w-0"
          key={folder.id}
          onClick={() => navigateTo({ folderName: folder.name, folderId: folder.id })}>
          <div className="shrink-0">
            <FolderIcon />
          </div>
          <div className="truncate">{folder.name}</div>
        </div>
      ))}
      {content.items.map((item) => (
        <div
          className={clsx(
            'cursor-pointer hover:text-white flex items-center gap-1 hover:bg-neutral-700/25 transition-colors p-1 w-full min-w-0',
            {
              'bg-primary-default hover:bg-primary-default text-white':
                selectedFiles.filter((selectedFile) => selectedFile.id === item.id).length > 0
            }
          )}
          key={item.id}
          onClick={() => selectFile(item)}>
          <div className="shrink-0">
            <DocumentIcon />
          </div>
          <div className="truncate">
            {item.name}.{item.extension}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FileBrowserContentList;
