import { useQuery } from '@tanstack/react-query';
import { ObjectValues } from '../types/object';
import { useApi } from './useApi';
import { ONE_HOUR } from '../constants/time';

export const ProjectStatus = {
  Active: 'Active',
  Deactivated: 'Deactivated'
} as const;

export type ProjectStatusType = ObjectValues<typeof ProjectStatus>;

export const ProjectType = {
  Private: 'Private',
  TeamOwned: 'Team-owned'
} as const;

export type ProjectTypeType = ObjectValues<typeof ProjectType>;

export interface Project {
  description: string;
  id: string;
  title: string;
  archived: boolean;
  created_at: string;
  updated_at: string;
  color: string;
  project_type: ProjectTypeType;
  status: ProjectStatusType;
  team_id: string;
}

export interface ProjectsResponse {
  data: Project[];
}

const useGetProjects = () => {
  const callApi = useApi();

  const result = useQuery<Project[]>(
    ['projects'],
    async () => {
      const data = await callApi<ProjectsResponse>({
        url: `${process.env.REACT_APP_API_URL}/v1/admin-v2/projects`
      });

      return data.data;
    },
    {
      staleTime: ONE_HOUR
    }
  );

  return result;
};

export default useGetProjects;
