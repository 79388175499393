import clsx from 'clsx';
import useGetBins, { Bin } from '../mcapi/useGetBins';
import BinIcon from '../common/icons/BinIcon';

interface Props {
  onChange: (bin: Bin) => void;
}

const BinList = ({ onChange }: Props) => {
  const { data, isLoading } = useGetBins();

  const handleSelectBin = (bin: Bin) => {
    onChange(bin);
  };

  return (
    <>
      {isLoading && (
        <div className="flex grow items-center justify-center max-h-full bg-neutral-800/25 animate-pulse">
          loading content...
        </div>
      )}
      {!isLoading && (
        <div className="h-20 bg-background-light grow border border-border-light select-none overflow-y-auto max-h-full">
          <div className="px-1 py-2 bg-border-light/25">Bins:</div>
          {data.map((bin) => (
            <div
              className={clsx(
                'cursor-pointer hover:text-white flex items-center gap-1 hover:bg-neutral-700/25 transition-colors p-1 w-full min-w-0'
              )}
              key={bin.path}
              onClick={() => handleSelectBin(bin)}>
              <div className="shrink-0">
                <BinIcon className="w-4 h-4" />
              </div>
              <div className="truncate">{bin.name}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BinList;
