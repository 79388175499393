import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { ApiRequest } from '../utils/api';
import { api } from '../utils/api';

type CallApiArgs = Omit<ApiRequest, 'token'>;

export const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const callApi = useCallback(
    async <TData>(args: CallApiArgs) => {
      const token = await getAccessTokenSilently();
      return api<TData>({ ...args, token });
    },
    [getAccessTokenSilently]
  );

  return callApi;
};
