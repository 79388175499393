import { FileBrowserContextProvider } from './FileBrowserContext';
import FileBrowserContentList from './FileBrowserContentList';
import FileBrowserPath from './FileBrowserPath';
import FileBrowserActions from './FileBrowserActions';
import { BEFileItem } from '../../types/files';
import CancelIcon from '../common/icons/CancelIcon';

interface Props {
  projectId: string | undefined;
  onSelect: (file: BEFileItem[]) => void;
  onClose: () => void;
}

const FileBrowser = ({ projectId, onSelect, onClose }: Props) => {
  return (
    <div className="flex flex-col fixed top-8 left-0 right-0 bottom-8 bg-background-default px-2 py-4">
      <div className="flex gap-1 justify-between items-center mb-2">
        Select File
        <button onClick={onClose} className="hover:text-white transition-white outline-none">
          <CancelIcon />
        </button>
      </div>
      <FileBrowserContextProvider projectId={projectId}>
        <FileBrowserContentList />
        <FileBrowserPath />
        <FileBrowserActions onSelect={onSelect} />
      </FileBrowserContextProvider>
    </div>
  );
};

export default FileBrowser;
