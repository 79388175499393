const MetadataSyncHeader = () => {
  return (
    <>
      <h1 className="text-bold mb-1 text-lg flex items-center gap-2">Syncronize metadata to PIX</h1>
      <p className="italic opacity-50 mb-4">
        To sync metadata from Media Composer, first drag a file on the plugin window then select a
        file from PIX then click on the sync button below.
      </p>
    </>
  );
};

export default MetadataSyncHeader;
