export interface ApiRequest {
  url: string;
  token?: string;
  method?: Request['method'];
  params?: Record<string, string>;
  headers?: HeadersInit;
  mode?: Request['mode'];
  credentials?: Request['credentials'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  redirect?: Request['redirect'];
  /**
   * @default false
   */
  rawBody?: boolean;
  /**
   * @default json
   */
  responseAs?: 'json' | 'text' | 'formData' | 'arrayBuffer' | 'blob';
  /**
   * @default true
   */
  includeDefaultHeaders?: boolean;
  /**
   * @default true
   */
  urlObjectInstance?: boolean;
}

export const api = async <TData>({
  url,
  token,
  method = 'get',
  headers = {},
  credentials,
  body,
  mode,
  redirect,
  rawBody = false,
  responseAs = 'json',
  includeDefaultHeaders = true
}: ApiRequest): Promise<TData> => {
  const defaultHeaders: HeadersInit = {
    ...(includeDefaultHeaders && {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_X_API_KEY || ''
    }),
    ...(!!token && { Authorization: `Bearer ${token}` })
  };
  try {
    const response = await fetch(url, {
      headers: { ...defaultHeaders, ...headers },
      mode,
      method,
      credentials,
      redirect,
      body: rawBody ? body : JSON.stringify(body)
    });

    if (!response.ok) {
      const errBody = await response.json();
      throw new Error(errBody);
    }

    return await response[responseAs]?.();
  } catch (err) {
    throw new Error('some error happened');
  }
};
