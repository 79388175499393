import { useApi } from './useApi';
import { useQuery } from '@tanstack/react-query';
import { ObjectValues } from '../types/object';
import { useScopeContext } from '../components/scope/ScopeContext';
import { ONE_SECOND_IN_MILLISECONDS } from '../constants/time';

export const NoteStatus = {
  Unset: 'nil',
  Resolved: 'resolved',
  Unresolved: 'unresolved'
} as const;

export type NoteStatusType = ObjectValues<typeof NoteStatus>;

export type NoteReply = {
  id: string;
  text: string;
  author_id: string;
  author_name: string;
  created_on: string;
  mentions: NoteMentions | null;
};

export type NoteMentions = Record<string, string>;

export type Note = {
  id: string;
  text: string;
  mentions: NoteMentions | null;
  markup: string;
  pdf_page?: number | null;
  start_frame: number | null;
  end_frame: number | null;
  state: NoteStatusType | null;
  replies: NoteReply[];
  is_private: boolean;
  author_display_name: string;
  author_id: string;
  resolved_by_id: string;
  created_on: string;
  modified_on: string;
};

interface GetNotesPaginatedResponse {
  info: {
    count: number;
    offset: number;
    // pagination is not working
    next_page: boolean;
  };
  list: Note[];
}

interface Props {
  assetId: string | undefined;
}

const useGetNotes = ({ assetId }: Props) => {
  const { scope } = useScopeContext();
  const callApi = useApi();

  const result = useQuery<Note[]>(
    [scope?.organization.id, 'notes', assetId],
    async () => {
      const data = await callApi<GetNotesPaginatedResponse>({
        url: `${process.env.REACT_APP_API_URL}/v1/notes/items/${assetId}/notes?organization_id=${
          scope?.organization.id || ''
        }&limit=100&sort_by=created_on`
      });

      return data.list;
    },
    {
      staleTime: ONE_SECOND_IN_MILLISECONDS,
      enabled: assetId !== undefined,
      retry: false
    }
  );

  return result;
};

export default useGetNotes;
