import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ReactElement } from 'react';
import PageLoader from './PageLoader';

interface Props {
  children: ReactElement;
}

const Frame = ({ children }: Props) => {
  return children;
};

const ProtectedFrame = withAuthenticationRequired(Frame, {
  returnTo: '/',
  onRedirecting: () => (
    <div className="bg-background-default text-text h-screen w-screen p-2 text-xs flex flex-col">
      <PageLoader />
    </div>
  )
});

export default ProtectedFrame;
