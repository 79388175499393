// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from 'react';
import { GetMobInfoRequest, GetMobInfoRequestBody } from '../../grpc-web/MCAPI_grpc_web_pb';
import { useMCAPIContext } from './MCAPIContext';

interface Props {
  mobId: string;
  visibleColumns?: boolean;
  includeEmptyColumns?: boolean;
}

const useGetMobInfo = ({ mobId, visibleColumns = true, includeEmptyColumns = true }: Props) => {
  const { client, getAccessToken } = useMCAPIContext();
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!mobId) return;
    setLoading(true);

    const request = new GetMobInfoRequest();
    const requestBody = new GetMobInfoRequestBody();

    requestBody.setMobId(mobId);
    requestBody.setOnlyVisibleColumns(visibleColumns);
    requestBody.setIncludesEmptyColumns(includeEmptyColumns);

    request.setBody(requestBody);

    const stream = client.getMobInfo(request, { accessToken: getAccessToken() });

    stream.on('data', (response) => {
      const columnName = response.getBody().getColumnName();
      const columnValue = response.getBody().getColumnValue();
      //   const columnValueType = response.getBody().getColumnValueType();
      //   const columnHidden = response.getBody().getColumnHidden();
      //   const columnIsCustom = response.getBody().getColumnIsCustom();

      setData((prevData) => ({ ...prevData, [columnName]: columnValue }));
    });

    stream.on('error', (err) => {
      const errMessage =
        `Unexpected stream error: code = ${err.code}` + `, message = "${err.message}"`;
      console.log(errMessage);
      window.mcapi.reportError(err.code, err.message);
    });
    stream.on('status', (status) => {
      console.log(status);
    });
    stream.on('end', () => {
      setLoading(false);
      console.log(`getMobInfo completed`);
    });
  }, [client, getAccessToken, includeEmptyColumns, mobId, visibleColumns]);

  const value = {
    data,
    isLoading
  };

  return value;
};

export default useGetMobInfo;
