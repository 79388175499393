interface Props {
  checked: boolean;
  onChange: () => void;
  label: string;
}

const Checkbox = ({ checked, onChange, label }: Props) => {
  return (
    <label className="select-none flex gap-1 items-center">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="w-4 h-4 text-primary-default accent-primary-default bg-background-light border-border-light rounded"
      />
      {label}
    </label>
  );
};

export default Checkbox;
