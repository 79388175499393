import React from 'react';

interface TextInputProps {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, onChange, value, ...props }, ref) => (
    <div>
      <label className="block">{label}</label>
      <input
        ref={ref}
        className="block w-full focus:outline-none bg-background-light px-2 py-1 border-border-light border w-min-0 gap-3"
        onChange={onChange}
        value={value}
        {...props}
      />
    </div>
  )
);

TextInput.displayName = 'TextInput';

export default TextInput;
