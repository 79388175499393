const NotesSyncHeader = () => {
  return (
    <>
      <h1 className="text-bold mb-1 text-lg flex items-center gap-2">Syncronize notes from PIX</h1>
      <p className="italic opacity-50 mb-4">
        To sync notes from PIX, first drag a file on the plugin window then select a file from PIX,
        choose a track and click the sync button below.
      </p>
    </>
  );
};

export default NotesSyncHeader;
